import React from "react";
import { IBooking } from "navision-proxy-api/@types";
import readXlsxFile from "read-excel-file";

import { useLoading } from "hooks/loading";
import { useSnackbar } from "context/snackbar";
import { useLang } from "context/lang";
import { useApi } from "hooks/api";
import { BookingMovementStatus } from "types";

export function useBookingActions() {
  const loaderNames = ["create", "update", "delete", "excel", "movementStatus"];
  const { loading, loadingOn, loadingOff } =
    useLoading<typeof loaderNames>(loaderNames);

  const { openSnackbar } = useSnackbar();
  const { t } = useLang();
  const {
    axios,
    createBooking: createBookingAPI,
    createBookings: createBookingsAPI,
    updateBooking: updateBookingAPI,
    deleteBooking: deleteBookingAPI,
  } = useApi();

  const createBooking = async (booking: IBooking) => {
    loadingOn("create");
    await createBookingAPI(booking);
    openSnackbar(t("bookingCreated"));
    loadingOff("create");
  };

  const createBookings = async (bookings: IBooking[]) => {
    loadingOn("create");
    await createBookingsAPI(bookings);
    loadingOff("create");
  };

  const updateBooking = async (booking: IBooking) => {
    loadingOn("update");
    await updateBookingAPI(booking);
    openSnackbar("bookingUpdated");
    loadingOff("update");
  };

  const deleteBooking = async (booking: IBooking) => {
    loadingOn("delete");
    await deleteBookingAPI(booking);
    openSnackbar("bookingDeleted");
    loadingOff("delete");
  };

  const createBookingsFromFile = async (excelFile: File) => {
    loadingOn("excel");
    const rows = await readXlsxFile(excelFile);
    const keys = rows[0];
    rows.shift();
    const bookings = rows.map((row) =>
      row.reduce<IBooking>((acc, cell, index) => {
        const key = keys[index];
        if (key.includes("packages")) {
          return {
            ...acc,
            packages: { ...acc.packages, [key.split("packages.")[1]]: cell },
          };
        } else {
          return { ...acc, [keys[index]]: cell };
        }
      }, {} as IBooking)
    );
    await createBookings(bookings);
    openSnackbar("bookingsImported");
    loadingOff("excel");
  };

  const updateBookingMovementStatus = async (
    bookingId: string,
    status: BookingMovementStatus
  ) => {
    loadingOn("movementStatus");
    await axios.patch(
      "/bookingMovementStatus",
      {},
      { params: { bookingId, status } }
    );
    loadingOff("movementStatus");
  };

  return {
    loading,
    createBooking,
    createBookings,
    updateBooking,
    deleteBooking,
    createBookingsFromFile,
    updateBookingMovementStatus,
  };
}
