import React from "react";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

export const useDraggable = (
  handleReorderRows: (oldIndex: number, newIndex: number) => void,
  options: {
    itemIdPrefix: string;
    checkDraggability?: (oldIndex: number, newIndex: number) => boolean;
  } = { itemIdPrefix: "draggable-item-" }
) => {
  const orderDragState = {
    oldIndex: -1,
    newIndex: -1, // drag target
  };

  //onDragEnter and onDragLeave are firing by child elements so we have to keep a count of how deep event is
  let dragCounter = 0;

  const getDraggbleProps = (
    itemIndex: number,
    /** Callbacks for visually highlighing draggable items while dragging */
    callbacks: {
      onEnterEffect?: (event: any) => void;
      onLeaveEffect?: (event: any) => void;
      onCleanupEffect?: (event: any) => void;
    } = {}
  ) => ({
    draggable: true,
    id: `${options.itemIdPrefix}${itemIndex}`,
    onDragStart: (e: any) => {
      if (
        !document?.activeElement?.classList.contains("dragging-button") //check if drug starts from the dragging button
      ) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        orderDragState.oldIndex = itemIndex;
      }
    },
    onDrag: (e: any) => {
      e.preventDefault();
    },
    onDragOver: (e: any) => {
      //for some reason if not preventing this ondragevent is fired with delay
      e.preventDefault();
    },
    onDragEnd: (e: any) => {
      e.preventDefault();
      if (callbacks.onCleanupEffect) {
        callbacks.onCleanupEffect(e);
      }
      if (orderDragState.newIndex != -1) {
        document //prevent draggable-destination-item from appearing after drag is end
          .getElementById(`${options.itemIdPrefix}${itemIndex}`)
          ?.parentElement?.classList.add("disable-destination-item");
        handleReorderRows(orderDragState.oldIndex, orderDragState.newIndex);
      }
      orderDragState.oldIndex = -1;
      orderDragState.newIndex = -1;
    },
    onDragEnter: (e: any) => {
      //additional draggable props for highlighting
      e.preventDefault();
      dragCounter++;
      if (
        options.checkDraggability &&
        !options.checkDraggability(orderDragState.oldIndex, itemIndex)
      ) {
        e.dataTransfer.dropEffect = "none";
        e.dataTransfer.effectAllowed = "none";

        orderDragState.newIndex = -1;
      } else {
        if (callbacks.onEnterEffect) {
          callbacks.onEnterEffect(e);
        }
        orderDragState.newIndex = itemIndex + 1;
      }
    },
    onDragLeave: (e: any) => {
      e.preventDefault();
      dragCounter--;
      if (callbacks.onLeaveEffect && dragCounter == 0) {
        callbacks.onLeaveEffect(e);
      }
    },
  });

  const getDraggingButton = () => (
    <IconButton className="dragging-button">
      <Icon className="draggable">drag_handle</Icon>
    </IconButton>
  );

  return { orderDragState, getDraggbleProps, getDraggingButton };
};
