import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

import { LanguagePicker } from "./LanguagePicker";
import { useAuth } from "../context/auth";
import { useLang } from "../context/lang";
import { useHistory } from "react-router-dom";

import routes from "config/routes";

export default ({ slim = false }) => {
  const history = useHistory();
  const { logout } = useAuth();
  const { t } = useLang();

  const handleClickSettings = (event: any) => {
    history.push(routes.settings.path);
  };

  const handleClickHelp = (event: any) => {
    history.push(
      `${routes.help.path}#${history.location.pathname.substring(1)}`
    );
  };

  return (
    <Box
      width={slim ? 1 : undefined}
      height={{ xs: 33, sm: 50 }}
      display="flex"
      justifyContent="flex-end"
      zIndex="202"
    >
      <Box
        width={slim ? 1 : undefined}
        display="flex"
        flexDirection={slim ? "column" : "row"}
        boxShadow={slim ? 0 : 5}
      >
        <LanguagePicker />
        <Box
          width="1px"
          height="100%"
          borderLeft="1px solid"
          borderColor="text.disabled"
        />
        <Button
          onClick={handleClickHelp}
          variant="text"
          disableElevation
          color="primary"
        >
          {slim ? <Icon>help</Icon> : ""}
          <Box px={2} color="primary.main">
            {t("help")}
          </Box>
        </Button>
        <Button
          variant={slim ? "text" : "contained"}
          onClick={handleClickSettings}
          disableElevation
          color="primary"
        >
          <Icon>person</Icon>
          {t("myCompany")}
        </Button>
        <Button
          onClick={() => logout()}
          variant={slim ? "text" : "outlined"}
          size="large"
          color="secondary"
        >
          <Icon>exit_to_app</Icon>
          {t("logout")}
        </Button>{" "}
      </Box>
    </Box>
  );
};
