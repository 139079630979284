import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";

import BookingFormField from "components/BookingForm/BookingFormField";
import { useLang } from "context/lang";
import { useStore } from "context/store";

import { Typography } from "@material-ui/core";
import { Loader } from "components/Loader";
import PackagesEditContainer from "components/PackagePicker/PackagesEditContainer";
import { BookingFieldId, IBooking } from "navision-proxy-api/@types";
import { useBookingForm } from "hooks/bookings/bookingForm";

interface IProps {
  booking: Partial<IBooking>;
  onChange: (field: BookingFieldId, value: any) => void;
  notEditableColumns: BookingFieldId[];
  hiddenFields: BookingFieldId[];
  handleDelete: () => void;
  loading: boolean;
}

export default ({
  booking,
  onChange,
  handleDelete,
  notEditableColumns,
  hiddenFields,
  loading,
}: IProps) => {
  const { bookingFormData, getCustomerCustomersLookup } = useStore();
  const { t } = useLang();

  const { handleClearAddresses, customersCustomerLookup } = useBookingForm();

  const ref = React.useRef<HTMLDivElement>();

  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    if (expanded && ref.current) {
      ref.current.scrollIntoView();
    }
  }, [expanded]);

  const customerValue = getCustomerCustomersLookup().find(
    ({ customerCode }) => customerCode === booking["customer"]
  )?.customerName;

  const receiverValue = getCustomerCustomersLookup().find(
    ({ receiverCode }) => receiverCode === booking["receiver"]
  )?.receiverName;

  return (
    <Box width={1} clone>
      <Accordion
        ref={ref}
        expanded={expanded}
        onChange={(e: React.ChangeEvent<{}>) => {
          if ((e.target as any)["id"] === "toogle-accordion") {
            //trigger only when button clicked
            setExpanded((prev) => !prev);
          }
        }}
      >
        <AccordionSummary
          expandIcon={
            <Icon id="toogle-accordion">
              {expanded ? "keyboard_arrow_up" : "keyboard_arrow_down"}
            </Icon>
          }
        >
          <Box m={1} mb={0}>
            <Box display="flex" alignItems="center">
              <div className="fancy-bullet fancy-bullet-connected" />
              <Typography>{customerValue}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <div className="fancy-bullet" />
              <Typography>{receiverValue}</Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box borderTop={1} borderColor="divider" clone width={1}>
            <Grid container spacing={4} direction="column">
              {bookingFormData.fields
                .filter(
                  (field) =>
                    !hiddenFields.includes(field.id) &&
                    !field.id.includes("packages") &&
                    !["goodsDescription", "packagesDescription"].includes(
                      field.id
                    )
                )
                .map((field) => {
                  return (
                    <Grid item container justify="space-between">
                      {/* <Grid item xs={3}>
                      <Typography>{t(field.id)}</Typography>
                    </Grid> */}
                      <Grid item xs={12}>
                        <BookingFormField
                          fullWidth
                          customersCustomerLookup={customersCustomerLookup}
                          field={field}
                          booking={booking}
                          value={booking[field.id as keyof IBooking]}
                          onChange={onChange}
                          disabled={notEditableColumns.includes(field.id)}
                          onClear={
                            field.isAddress
                              ? () =>
                                  handleClearAddresses(
                                    field.id as keyof IBooking
                                  )
                              : undefined
                          }
                        />
                      </Grid>
                    </Grid>
                  );
                })}

              <Grid item container justify="space-between">
                <Grid item xs={12}>
                  <PackagesEditContainer
                    onChange={(newValue) => onChange("goods", newValue)}
                    value={booking.goods}
                    packagesLookup={bookingFormData.goodNames}
                    width={200}
                    required
                    label={t("goods")}
                    // errorText={
                    //   errors["goods"] ? errors["goods"][booking._id] : undefined
                    // }
                    translate={false}
                    packagesDescriptions={booking.goodsDescription} //array
                    onChangePackageDescriptions={
                      !hiddenFields.includes("goodsDescription")
                        ? (index: number, value: string) => {
                            const newGoodsDescription = booking.goodsDescription
                              ? [...booking.goodsDescription]
                              : [];
                            newGoodsDescription[index] = value;
                            onChange("goodsDescription", newGoodsDescription);
                          }
                        : null
                    }
                    enableDuplicateKeys
                  />
                </Grid>
              </Grid>
              <Grid item container justify="space-between">
                <Grid item xs={12}>
                  <PackagesEditContainer
                    onChange={(newValue) => onChange("packages", newValue)}
                    value={booking.packages}
                    packagesLookup={bookingFormData.packageNames}
                    width={200}
                    required
                    label={t("packages")}
                    // errorText={
                    //   errors["packages"]
                    //     ? errors["packages"][booking._id]
                    //     : undefined
                    // }
                  />
                </Grid>
              </Grid>

              <Box display="flex" mt={5} width="100%" justifyContent="flex-end">
                <Button
                  size="large"
                  color="secondary"
                  onClick={handleDelete}
                  startIcon={
                    <Loader loading={loading} size={20}>
                      <Icon>delete</Icon>
                    </Loader>
                  }
                >
                  {t("delete")}
                </Button>
              </Box>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
