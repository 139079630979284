import React from "react";
import { IBooking, BookingFieldId } from "navision-proxy-api/@types";
import { BookingsResponse } from "navision-proxy-api/@types/api";
import { useBookings } from "hooks/bookings";
import { useStore } from "context/store";
import { Grid, Typography, Box, IconButton, Icon } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useBookingActions } from "hooks/bookings/bookingActions";
import _ from "lodash";
import { Loader } from "components/Loader";
import LinearProgress from "@material-ui/core/LinearProgress";

interface IProps {
  booking: Partial<IBooking>;
  initBookings: BookingsResponse;
  muiltiFormFields: BookingFieldId[];
}

interface IButtonsViewForm {
  [bookingId: string]: { bindedBookings?: IBooking[]; goodsAmount: number };
}

export default ({ booking, initBookings, muiltiFormFields }: IProps) => {
  const { bookings, loadBookings, loading } = useBookings();

  const [goodsForm, setGoodsForm] = React.useState<IButtonsViewForm>({});
  React.useEffect(() => {
    if (booking.tourTime) {
      loadBookings({
        filters: {
          pickupDate: {
            eq: booking.tourTime,
            //eq: "2022-12-07T00:00:00Z",
          } as any,
          pickupTime: {
            eq: `'${booking.tourTime.split("T")[1]?.split(".")[0]}'`,
            //eq: "'08:00:00'",
          },
        } as any,
      });
    }
  }, [booking.tourTime]);

  React.useEffect(() => {
    matchBookings();
  }, [bookings]);

  /** Matches existing bookings to quick from bookings */
  const matchBookings = () => {
    const newGoodsForm: IButtonsViewForm = {};
    bookings?.items?.forEach((b) => {
      // console.log("trying to match booking", b);
      const matchedBooking = initBookings?.items.find(
        (qfb) =>
          b.delivery == qfb.delivery &&
          b.receiver == qfb.receiver &&
          b.pickup == qfb.pickup &&
          b.customer == qfb.customer &&
          b.customerNb == qfb.customerNb
      );

      const matchedBookingPackageValue = b?.goods?.find(
        ([key, _]) => key == "CC"
      )?.[1];

      if (matchedBooking && matchedBookingPackageValue) {
        newGoodsForm[String(matchedBooking._id)] = {
          bindedBookings: [
            ...(newGoodsForm[String(matchedBooking._id)]?.bindedBookings || []),
            b,
          ],
          goodsAmount:
            parseInt(matchedBookingPackageValue) +
            (newGoodsForm[String(matchedBooking._id)]?.goodsAmount || 0),
        };
      }
    });
    setGoodsForm(newGoodsForm);
  };

  return (
    <Box pb={20} width="100%">
      <Loader loading={Boolean(loading["load"])} margin={10} size={50}>
        <Grid container>
          {initBookings.items.map((quickFormBooking, index) => (
            <ButtonsViewItem
              key={index}
              item={quickFormBooking}
              goodsForm={goodsForm}
              setGoodsForm={setGoodsForm}
              booking={booking}
              muiltiFormFields={muiltiFormFields}
            />
          ))}
        </Grid>
      </Loader>
    </Box>
  );
};

interface IItemProps {
  item: IBooking;
  goodsForm: IButtonsViewForm;
  /** Handler for editing goods */
  setGoodsForm: React.Dispatch<React.SetStateAction<IButtonsViewForm>>;
  /** Global booking for tourtime, dates */
  booking: Partial<IBooking>;
  muiltiFormFields: BookingFieldId[];
}

const ButtonsViewItem = ({
  item,
  goodsForm,
  setGoodsForm,
  booking,
  muiltiFormFields,
}: IItemProps) => {
  const { getCustomerCustomersLookup } = useStore();

  /** Interval after booking is being saved */
  const [progress, setProgress] = React.useState(100);

  const progressTimeout = React.useRef<ReturnType<typeof setTimeout>>();

  // console.log(item);
  // console.log(goodsForm);

  React.useEffect(() => {
    // console.log("triggering progress");
    if (progress == 100) {
      clearProgressInterval();

      return;
    } else {
      // console.log("running progress");
      // console.log(progressTimeout.current);

      progressTimeout.current = setTimeout(() => {
        setProgress((oldProgress) => {
          const diff = 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);
    }
  }, [progress]);

  const clearProgressInterval = () => {
    if (progressTimeout.current) {
      clearInterval(progressTimeout.current);
      progressTimeout.current = undefined;
    }
  };

  const rerunProgress = () => {
    clearProgressInterval();
    setProgress(0);
  };

  const { createBooking, loading } = useBookingActions();
  const theme = useTheme();

  const addressesLookup = getCustomerCustomersLookup();
  const customerValue =
    addressesLookup.find(
      ({ customerCode }) => customerCode === item["customer"]
    )?.customerName + (item["customerNb"] ? ` (${item["customerNb"]})` : "");

  const receiverValue = addressesLookup.find(
    ({ receiverCode }) => receiverCode === item["receiver"]
  )?.receiverName;

  const pickupValue = addressesLookup.find(
    ({ pickupCode }) => pickupCode === item["pickup"]
  )?.pickupName;

  const deliveryValue = addressesLookup.find(
    ({ deliveryCode }) => deliveryCode === item["delivery"]
  )?.deliveryName;

  /** Saves quick form booking.
   * If corresponding booking for this tourtime already exist we want to edit goods of this booking.
   * If there is no corresponding booking we create one. */
  const saveBookingGoods = (quickFormBooking: IBooking, goodsValue: number) => {
    // console.log("running save booking Goods");
    // console.log(quickFormBooking);
    // console.log(goodsForm);
    const bookingForm = goodsForm[String(quickFormBooking._id)];

    const dataFromBookingForm = Object.entries(booking) //We need to use ref because handler being memoized and using old state TODO: check if it is ok
      .filter(([key]) => muiltiFormFields.includes(key as BookingFieldId))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    if (bookingForm?.bindedBookings?.length) {
      const firstBookingGoodsValue =
        goodsValue -
        bookingForm.bindedBookings.reduce((acc, b, i) => {
          if (i == 0) {
            //we ignore first element
            return acc;
          } else {
            //sum up all CC goods
            return (
              acc + parseInt(b.goods.find((g) => g[0] == "CC")?.[1] || "0")
            );
          }
        }, 0);

      createBooking({
        ...bookingForm.bindedBookings[0],
        goods: [["CC", String(firstBookingGoodsValue)]],
        ...dataFromBookingForm,
      });
    } else {
      createBooking({
        ...quickFormBooking,
        goods: [["CC", String(goodsValue)]],
        ...dataFromBookingForm,
      });
    }
  };

  const debouncedSaveBookingGoods = React.useRef(
    _.debounce(saveBookingGoods, 5000)
  ).current;

  const debouncedRerunProgress = React.useRef(
    _.debounce(rerunProgress, 600, { leading: true, trailing: false })
  ).current;

  const addGood = () => {
    setGoodsForm((prev) => {
      const newAmount = (prev[String(item._id)]?.goodsAmount || 0) + 1;
      debouncedSaveBookingGoods(item, newAmount);
      debouncedRerunProgress();
      return {
        ...prev,
        [String(item._id)]: {
          ...prev[String(item._id)],
          goodsAmount: newAmount,
        },
      };
    });
  };

  const removeGood = () => {
    setGoodsForm((prev) => {
      const newAmount = (prev[String(item._id)]?.goodsAmount || 1) - 1;
      debouncedSaveBookingGoods(item, newAmount);
      debouncedRerunProgress();
      return {
        ...prev,
        [String(item._id)]: {
          ...prev[String(item._id)],
          goodsAmount: newAmount,
        },
      };
    });
  };

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Box m={4} border="1px solid" borderColor={theme.palette.divider} p={2}>
        <Box>
          <Box>
            <Box display="flex">
              <Box mr={1}>
                <Icon color="disabled">launch</Icon>
              </Box>
              <div>
                <Typography>{customerValue}</Typography>
                <Typography variant="caption">{pickupValue}</Typography>
              </div>
            </Box>
            <Box display="flex">
              <Box mr={1}>
                <Icon color="disabled" style={{ transform: "scale(-1,1)" }}>
                  input
                </Icon>
              </Box>
              <div>
                <Typography>{receiverValue}</Typography>
                <Typography variant="caption">{deliveryValue}</Typography>
              </div>
            </Box>
          </Box>
        </Box>
        <Loader
          margin={3}
          size={50}
          color="primary"
          loading={Boolean(loading["create"])}
        >
          <Box>
            <Box
              display={"flex"}
              p={2}
              justifyContent="center"
              alignItems="center"
            >
              <IconButton onClick={removeGood}>
                <Icon style={{ fontSize: 40 }} color="secondary">
                  remove
                </Icon>
              </IconButton>
              <Typography variant="h3">{`${
                goodsForm[String(item._id)]?.goodsAmount || 0
              } CC`}</Typography>
              <IconButton onClick={addGood}>
                <Icon style={{ fontSize: 40 }} color="primary">
                  add
                </Icon>
              </IconButton>
            </Box>
            {progress < 100 && (
              <LinearProgress variant="determinate" value={progress} />
            )}
          </Box>
        </Loader>
      </Box>
    </Grid>
  );
};
