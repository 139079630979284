import React from "react";

import { IQuickForm, IBooking } from "navision-proxy-api/@types";
import {
  QuickFormBulkEditRequest,
  QuickFormRequestQuery,
  ReorderQuickFormRequest,
  LabelsStatusResponse,
} from "navision-proxy-api/@types/api";
import { ObjectId } from "mongoose";

import { useLoading } from "hooks/loading";
import { useLang } from "context/lang";
import { useSnackbar } from "context/snackbar";

import { useApi } from "hooks/api";

export function useQuickForm({ isLocal }: { isLocal: boolean }) {
  const loaderNames = ["load", "create", "release", "update", "delete"];
  const { loading, loadingOn, loadingOff } =
    useLoading<typeof loaderNames>(loaderNames);
  const { t } = useLang();
  const { openSnackbar } = useSnackbar();

  const {
    releaseQuickForm: releaseQuickFormApi,
    fetchQuickForms,
    updateQuickForm,
    createQuickForm: createQuickFormApi,
    deleteQuickForm: deleteQuickFormApi,
    deleteQuickFormBooking: deleteQuickFormBookingApi,
    reorderQuickFormLines,
    getRFIDLabels,
    orderRFIDLabels,
  } = useApi();

  const [quickForms, setQuickForms] = React.useState<IQuickForm[]>([]);

  const [RFIDLabels, setRFIDLabels] =
    React.useState<LabelsStatusResponse | null>(null);

  React.useEffect(() => {
    loadQuickForms({ filters: { isLocal } });
  }, []);

  const loadQuickForms = async (query: QuickFormRequestQuery) => {
    loadingOn("load");
    const quickForms = await fetchQuickForms(query);
    setQuickForms(
      quickForms.map((qf) => ({ ...qf, name: qf.name || t(qf.type) }))
    );
    loadingOff("load");
  };

  const reloadQuickForms = () => {
    loadQuickForms({ filters: { isLocal } });
  };

  const deleteQuickFormBooking = async (id: ObjectId) => {
    loadingOn("delete");
    await deleteQuickFormBookingApi(id);
    loadingOff("delete");
  };

  const createQuickForm = async (quickForm: IQuickForm) => {
    loadingOn("create");
    const qf = await createQuickFormApi(quickForm);
    openSnackbar("quickFormCreated");
    loadingOff("create");
    return qf;
  };

  const deleteQuickForm = async (quickForm: IQuickForm) => {
    loadingOn("delete");
    await deleteQuickFormApi(quickForm._id);
    loadingOff("delete");
    openSnackbar("quickFormDeleted");
  };

  const bulkUpdateQuickForm = async (
    id: ObjectId,
    name: string,
    editedData: IBooking[] = []
  ) => {
    loadingOn("update");
    const qf = await updateQuickForm({
      id,
      name,
      editedBookings: editedData,
    } as QuickFormBulkEditRequest);

    loadingOff("update");
    return qf;
  };

  const releaseQuickForm = async (quickFormId: ObjectId) => {
    loadingOn("release");
    await releaseQuickFormApi(quickFormId);
    loadingOff("release");
    openSnackbar("orderCreated");
  };

  const reorderLines = async (reorderLinesRequest: ReorderQuickFormRequest) => {
    await reorderQuickFormLines(reorderLinesRequest);
  };

  const loadLabels = async () => {
    loadingOn("labels");
    const labels = await getRFIDLabels();
    setRFIDLabels(labels);
    loadingOff("labels");
  };

  const orderLabels = async (
    tourtime: string,
    pickupCode: string,
    receiverCode: string,
    amount: number
  ) => {
    loadingOn("labelsCreate");
    const newLabels = await orderRFIDLabels(
      tourtime,
      pickupCode,
      receiverCode,
      amount
    );
    loadingOff("labelsCreate");
    setRFIDLabels(newLabels);
  };

  return {
    loading,
    quickForms,
    loadQuickForms,
    deleteQuickFormBooking,
    createQuickForm,
    deleteQuickForm,
    bulkUpdateQuickForm,
    releaseQuickForm,
    reloadQuickForms,
    reorderLines,
    loadLabels,
    orderLabels,
    RFIDLabels,
  };
}
