import React from "react";

import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";

import { useLocation } from "react-router-dom";

interface IProps {
  header: string;
  subheader: string;
  text: string;
  content: { key: string; title: string; body: string }[];
}

export default ({ header, subheader, text, content }: IProps) => {
  const [currentPage, setCurrentPage] = React.useState(
    window.location.hash?.substring(1)
  );

  const body = content.find(({ key }) => key === currentPage)?.body;

  return (
    <Box display="flex" flexWrap={{ sm: "wrap", lg: "nowrap" }}>
      <Box width={{ xs: "100%", lg: "40%" }}>
        <Typography gutterBottom variant="h4">
          <b>{header}</b>
        </Typography>
        <Typography variant="h6">{subheader}</Typography>
        <Typography variant="subtitle1">{text}</Typography>
        <Box display="flex" flexDirection="column" mt={5}>
          {content.map(({ key, title }) => (
            <Box
              border={1}
              borderTop={0}
              borderLeft={0}
              borderRight={0}
              borderColor="grey.300"
            >
              <Box m={3} clone width="100%">
                <Button
                  onClick={() => {
                    setCurrentPage(""); //for smooth transition
                    setTimeout(() => setCurrentPage(key), 1);
                  }}
                >
                  <Typography>
                    {currentPage === key ? <b>{title}</b> : title}
                  </Typography>
                  <Box
                    borderRadius="50%"
                    width="25px"
                    height="25px"
                    bgcolor="primary.main"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Icon fontSize="small" style={{ color: "white" }}>
                      arrow_forward
                    </Icon>
                  </Box>
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box width={{ xs: "100%", lg: "60%" }} ml="10%" mr="10%" mt={5}>
        {Boolean(currentPage) && Boolean(body) && (
          <Fade in>
            <Typography>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: body || "" }}
              ></div>
            </Typography>
          </Fade>
        )}
      </Box>
    </Box>
  );
};
