const config: {
  [pageName: string]: {
    path: `/${string}`;
    hideTitle?: boolean;
    name?: string;
  };
} = {
  mainPage: {
    path: "/",
  },
  login: { path: "/login" },
  bookings: { path: "/bookings" },
  bookingDetails: { path: "/bookings/:trackingId", name: "bookingDetails" },
  bookingDetailsPublic: { path: "/track/:trackingId" },

  createBooking: { path: "/createBooking" },
  editBooking: { path: "/editBooking" },
  quickForm: { path: "/quickForm" },
  createLocalBooking: { path: "/createLocalBooking" },
  editLocalBooking: { path: "/editLocalBooking" },
  quickFormLocal: { path: "/quickFormLocal" },
  labels: { path: "/labels" },
  report: { path: "/report" },
  settings: { path: "/settings" },
  help: {
    path: "/help",
    hideTitle: true,
  },
};

export default config;
