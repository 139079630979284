import React from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../context/auth";

import { useLocation, matchPath, generatePath, useParams } from "react-router";
import routes from "config/routes";

const redirects = {
  "/bookings/:trackingId": "/track/:trackingId",
};

export default ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();

  if (isAuthenticated()) {
    //all good
    return <>{children}</>;
  } else {
    //custom redirects

    //looking for redirect options
    const redirect = Object.keys(redirects).find((path) =>
      matchPath(pathname, { path, exact: true, strict: false })
    ) as keyof typeof redirects;

    if (redirect) {
      const redirectTo = redirects[redirect];

      let privateMatch: any; //check if there is corresponding private path
      Object.values(routes).forEach(({ path }) => {
        const _match = matchPath(pathname, {
          path,
          exact: true,
          strict: false,
        });

        if (_match) {
          privateMatch = _match;
        }
      });

      //private path exist and we may want to redirect to corresponding public path
      if (privateMatch) {
        const { params } = privateMatch;
        const newPath = generatePath(redirectTo, { ...params } as any);
        return <Redirect to={newPath} />;
      } else {
        //redirect without matching params
        return <Redirect to={redirectTo} />;
      }
    }
  }

  //default
  return <Redirect to="/login" />;
};
