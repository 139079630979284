import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

interface IProps {
  loading: boolean;
  size?: number;
  color?: "inherit" | "primary" | "secondary" | undefined;
  margin?: number;
  children?: JSX.Element | JSX.Element[] | string;
}

export const Loader = ({
  loading,
  size = 20,
  color = "primary",
  margin = 0,
  children,
}: IProps) =>
  loading ? (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      m={margin}
      mx="auto"
    >
      <CircularProgress color={color} size={size} />
    </Box>
  ) : (
    <>{children || null}</>
  );
