import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useLocation, matchPath } from "react-router";

import NavFastMenu from "./NavFastMenu";
import { useLang } from "../context/lang";

import routes from "config/routes";

export const Header = () => {
  const { pathname } = useLocation();
  const { t } = useLang();

  const pathSettings = Object.values(routes).find(({ path }) =>
    matchPath(pathname, {
      path,
      exact: true,
      strict: false,
    })
  );

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column-reverse", lg: "row" }}
      justifyContent="space-between"
    >
      {!(
        pathSettings as {
          path: string;
          hideTitle: boolean;
        }
      )?.hideTitle ? (
        <Box
          m={{ xs: 5, sm: 3, lg: 2 }}
          mb={{ xs: 1 }}
          pt={{ xs: 3, sm: 0 }}
          ml={{ xs: 1, sm: 2 }}
        >
          <Typography gutterBottom variant="h4">
            <b>{t(pathSettings?.name || pathname.substring(1))}</b>
          </Typography>
        </Box>
      ) : (
        <Box height="50px"></Box>
      )}
      <Box display={{ xs: "none", sm: "flex" }} justifyContent="flex-end">
        <NavFastMenu />
      </Box>
    </Box>
  );
};
