import React from "react";
import { useLang } from "context/lang";
import { Box, Typography, Button, Icon, Checkbox } from "@material-ui/core";
import PackagesEditContainer from "components/PackagePicker/PackagesEditContainer";
import BookingFormField from "components/BookingForm/BookingFormField";
import { useStore } from "context/store";
import { useAuth, IAuthUser } from "context/auth";
import { useSnackbar } from "context/snackbar";

import { useApi } from "hooks/api";
import { Loader } from "components/Loader";
import { IUser, IBooking } from "navision-proxy-api/@types";
import AlertDialog from "components/AlertDialog";

export default () => {
  const { t } = useLang();
  const {
    user,
    setUser,
    user: { appConfig },
    logout,
    restoreSettings,
  } = useAuth();

  const { saveUserConfig } = useApi();

  const { openSnackbar } = useSnackbar();

  // const {
  //   bookingFormData: { packages },
  //   setEnableCCLookup,
  // } = useStore();

  const { getCustomerCustomersLookup, bookingFormData } = useStore();

  // React.useEffect(() => {
  //   setEnableCCLookup(false);
  //   return () => setEnableCCLookup(true);
  // }, []);

  const initForm = {
    customer: appConfig?.formDefaults.customerAddress,
    goodsUnit: appConfig?.formDefaults.goodsUnit.map((p: string) => [p, ""]),
    packagesUnits: appConfig?.formDefaults.packagesUnits.map((p: string) => [
      p,
      "",
    ]),
    saveGoodsDescription: Boolean(appConfig?.quickForm?.saveGoodsDescription),
    savePackagesDescription: Boolean(
      appConfig?.quickForm?.savePackagesDescription
    ),
    saveDescription: Boolean(appConfig?.quickForm?.saveDescription),
    showOnlyMyBookings: Boolean(appConfig?.showOnlyMyBookings),
    showLabelsMenu: Boolean(appConfig?.showLabelsMenu),

    saveCreatePickupDate: Boolean(appConfig?.createBooking?.savePickupDate),
    saveCreatePickupTime: Boolean(appConfig?.createBooking?.savePickupTime),
  };

  const formReducer = (
    form: typeof initForm,
    { key, value }: { key: string; value: any }
  ) => ({
    ...form,
    [key]: value,
  });

  const [form, dispatch] = React.useReducer(formReducer, initForm);

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const newUser = {
      ...user,
      appConfig: {
        ...appConfig,
        formDefaults: {
          customerAddress: form.customer,
          goodsUnit: form.goodsUnit.map(
            ([key, value]: [key: string, value: string]) => key
          ),
          packagesUnits: form.packagesUnits.map(
            ([key, value]: [key: string, value: string]) => key
          ),
        },
        quickForm: {
          saveGoodsDescription: form.saveGoodsDescription,
          savePackagesDescription: form.savePackagesDescription,
          saveDescription: form.saveDescription,
        },
        createBooking: {
          savePickupDate: form.saveCreatePickupDate,
          savePickupTime: form.saveCreatePickupTime,
        },
        showOnlyMyBookings: form.showOnlyMyBookings,
        showLabelsMenu: form.showLabelsMenu,
      },
    };
    await saveUserConfig(newUser as IUser);
    setUser(newUser as IAuthUser);
    setLoading(false);
    openSnackbar(t("settingsSaved"));
  };

  const handleRestoreSettings = async () => {
    setLoading(true);
    await restoreSettings();
    setLoading(false);
  };

  return (
    <Box maxWidth="600px" display="flex" flexDirection="column">
      <Box mt={1} mb={2}>
        <Button
          onClick={() => logout()}
          variant="outlined"
          size="large"
          color="secondary"
        >
          <Icon>exit_to_app</Icon>
          {t("logout")}
        </Button>{" "}
      </Box>
      <Box borderColor="text.disabled">
        <Typography>{`${t("principalCode")}: ${
          user?.principalCode
        }`}</Typography>
        <Typography>{`${t("userId")}: ${user.userId}`}</Typography>
        <Typography>{`${t("indtrans")} ${
          user?.allowIndtrans ? t("availible") : t("unavailible")
        }`}</Typography>
      </Box>

      <Box mt={3} mb={1}>
        <Typography>
          <b>{t("bookings")}</b>
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={form.showOnlyMyBookings}
          onChange={(e) =>
            dispatch({ key: "showOnlyMyBookings", value: e.target.checked })
          }
        />
        <Typography>{t("showOnlyMyBookings")}</Typography>
      </Box>
      <Box mt={3} mb={1}>
        <Typography>
          <b>{t("createBooking")}</b>
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Checkbox
          checked={form.saveCreatePickupDate}
          onChange={(e) =>
            dispatch({
              key: "saveCreatePickupDate",
              value: e.target.checked,
            })
          }
        />
        <Typography>{t("save") + " " + t("pickupDate")}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={form.saveCreatePickupTime}
          onChange={(e) =>
            dispatch({
              key: "saveCreatePickupTime",
              value: e.target.checked,
            })
          }
        />
        <Typography>{t("save") + " " + t("pickupTime")}</Typography>
      </Box>

      <Box mt={3} mb={1}>
        <Typography>{t("defaultCustomerAddress")}:</Typography>
      </Box>
      <BookingFormField
        field={{ id: "customer", isAddress: true, type: "String" }}
        onChange={(key, value) => dispatch({ key, value })}
        value={form.customer}
        customersCustomerLookup={getCustomerCustomersLookup()}
        booking={{} as IBooking}
      />
      <Box mt={3} mb={1}>
        <Typography>{t("defaultUnit")}:</Typography>
      </Box>

      <PackagesEditContainer
        label={t("goods")}
        onChange={(goods) => dispatch({ key: "goodsUnit", value: goods })}
        value={form.goodsUnit}
        packagesLookup={bookingFormData.goodNames}
        variant="outlined"
        row
        hideDescription
        hideValues
        translate={false}
      />
      <Box mt={3} mb={1}>
        <Typography>{t("defaultPackages")}:</Typography>
      </Box>

      <PackagesEditContainer
        onChange={(newValue) =>
          dispatch({ key: "packagesUnits", value: newValue })
        }
        value={form.packagesUnits}
        inputLabel={t("packages")}
        packagesLookup={bookingFormData.packageNames}
        variant="outlined"
        hideDescription
        hideValues
      />

      <Box mt={3} mb={1}>
        <Typography>
          <b>{t("quickForm")}</b>
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={form.saveGoodsDescription}
          onChange={(e) =>
            dispatch({ key: "saveGoodsDescription", value: e.target.checked })
          }
        />
        <Typography>{t("saveGoodsDescription")}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={form.savePackagesDescription}
          onChange={(e) =>
            dispatch({
              key: "savePackagesDescription",
              value: e.target.checked,
            })
          }
        />
        <Typography>{t("savePackagesDescription")}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={form.saveDescription}
          onChange={(e) =>
            dispatch({ key: "saveDescription", value: e.target.checked })
          }
        />
        <Typography>{t("saveDescription")}</Typography>
      </Box>

      <Box mt={3} mb={1}>
        <Typography>
          <b>{t("extentions")}</b>
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={form.showLabelsMenu}
          onChange={(e) =>
            dispatch({ key: "showLabelsMenu", value: e.target.checked })
          }
        />
        <Typography>{t("showLabelsMenu")}</Typography>
      </Box>
      <Loader loading={loading}>
        <>
          <Box width="50px" mt={3}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              {t("save")}
            </Button>
          </Box>
          <Box mt={2}>
            <AlertDialog handleSubmit={handleRestoreSettings}>
              <Button variant="outlined" color="secondary">
                {t("restoreSettings")}
              </Button>
            </AlertDialog>
          </Box>
        </>
      </Loader>
    </Box>
  );
};
