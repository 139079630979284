import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

import { ObjectId } from "mongoose";
import { IBookingsMap } from "containers/QuickForm";

import ItemsFormBooking from "components/QuickForm/ItemsFormBooking";

import { useLang } from "context/lang";
import useWidth from "hooks/useWidth";

import { Loader } from "components/Loader";
import { useFieldsSettings } from "context/fieldsSettings";
import KeyListener from "components/KeyListener";
import { BookingFieldId } from "navision-proxy-api/@types";
import { BookingsResponse } from "navision-proxy-api/@types/api";

interface IProps {
  initBookings: BookingsResponse;
  ignoredColumns: BookingFieldId[];
  handleSubmit: (bookings: IBookingsMap) => void;
  handleDelete: (id: ObjectId) => void;
  loading: boolean;
}

export default ({
  // local,
  // muiltiFormFields,
  initBookings,
  ignoredColumns,
  handleSubmit,
  handleDelete,
  loading,
}: // adding,
// setAdding,
// currentQuickForm,
IProps) => {
  const { t } = useLang();
  const [bookingsForm, setBookingsForm] = React.useState<IBookingsMap>({});

  const { hiddenFields } = useFieldsSettings();

  const width = useWidth();

  React.useEffect(() => {
    initBookingsForm();
    // if (enableCCLookup) {
    //   setEnableCCLookup(false);
    // }
  }, [initBookings]);

  const initBookingsForm = () => {
    const newBookingsForm = initBookings.items.reduce(
      (acc, booking) => ({
        ...acc,
        [String(booking._id)]: booking,
      }),
      {}
    );
    setBookingsForm(newBookingsForm);
  };

  const handleChangeField = (
    bookingId: string,
    field: BookingFieldId,
    value: any
  ) => {
    setBookingsForm((prev) => ({
      ...prev,
      [bookingId]: { ...prev[bookingId], [field]: value },
    }));
  };

  const submit = () => {
    handleSubmit(bookingsForm);
    initBookingsForm();
  };

  return (
    <>
      {Object.entries(bookingsForm).map(([bookingId, value]) => (
        <ItemsFormBooking
          loading={loading}
          key={bookingId}
          booking={value}
          onChange={(field, value) =>
            handleChangeField(bookingId, field, value)
          }
          hiddenFields={[
            ...hiddenFields,
            ...ignoredColumns,
            // ...muiltiFormFields,
          ]}
          //handleSave={bookingId === "newBooking" ? handleSaveLine : undefined}
          handleDelete={() => handleDelete(value._id)}
          notEditableColumns={[
            "receiver",
            "customerNb",
            "customer",
            "pickup",
            "delivery",
          ]}
          // headerFields={
          //   ["md", "lg", "xl"].includes(width) ? ["goods", "packages"] : []
          // }
          // adding={adding}
        />
      ))}
      <Box display="flex" mt={5} width="100%" justifyContent="space-between">
        {initBookings.items.length > 0 && (
          <KeyListener keyName="Enter" ctrl action={() => submit()}>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={() => submit()}
              startIcon={
                <Loader size={20} loading={loading}>
                  <Icon>save</Icon>
                </Loader>
              }
            >
              {t("createOrder")}
            </Button>
          </KeyListener>
        )}
        {/* <Button
          size="large"
          color="primary"
          onClick={() => handleAdding()}
          ref={addLineActionRef}
          startIcon={
            <Loader size={20} loading={loadingCreate}>
              <Icon>add</Icon>
            </Loader>
          }
        >
          {t("addLine")}
        </Button> */}
      </Box>
    </>
  );
};
