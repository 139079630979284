import React from "react";
import { Box, Icon, Tooltip, IconButton } from "@material-ui/core";
import { useLang } from "context/lang";
import { useStore } from "context/store";
import listIcon from "assets/img/wpforms.svg";
import { BookingsRequestQuery } from "navision-proxy-api/@types/api";
import { NavPdfDownloaderMany } from "components/PdfGenerator/NavPdfDownloader";
import { IBooking } from "navision-proxy-api/@types";
import { BookingMovementStatus } from "types";
import AlertDialog from "components/AlertDialog";
import {
  canConfirmBooking,
  canDeleteBooking,
  canDownloadPdf,
  canUnconfirmBooking,
} from "config/bookingPolicies";
import { DownloadLabelsPdf } from "components/PdfGenerator/PdfLabel";

interface IBookingsToolbarProps {
  handleCreateBookingsFromFile: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  query: BookingsRequestQuery;
  selectedBookings: IBooking[];
  children?: JSX.Element | JSX.Element[];
  deleteBooking: (b: IBooking) => Promise<void>;
  updateBookingMovementStatus: (
    bookingId: string,
    newStatus: BookingMovementStatus
  ) => Promise<void>;
  refreshBookings: () => void;
}

export default ({
  handleCreateBookingsFromFile,
  query,
  selectedBookings,
  children,
  deleteBooking,
  updateBookingMovementStatus,
  refreshBookings,
}: IBookingsToolbarProps) => {
  const { t } = useLang();
  const { templateModeProps, setTemplateModeProps } = useStore();
  // const excelInputRef = React.useRef();

  const handleOffTemplateMode = () => {
    setTemplateModeProps(null);
  };

  const handleOnTemplateMode = () => {
    setTemplateModeProps({});
  };

  const handleDeleteBookings = () => {
    selectedBookings.forEach((b) => deleteBooking(b));
  };

  const handleConfirmBookings = async () => {
    await Promise.all(
      selectedBookings.map((b) =>
        updateBookingMovementStatus(
          b.bookingId,
          BookingMovementStatus.confirmed
        )
      )
    );
    refreshBookings();
  };

  const handleUnconfirmBookings = async () => {
    await Promise.all(
      selectedBookings.map((b) =>
        updateBookingMovementStatus(
          b.bookingId,
          BookingMovementStatus.unconfirmed
        )
      )
    );
    refreshBookings();
  };

  return (
    <Box display="flex" height={56}>
      {children}
      <Box mt="4px">
        <Tooltip title={t("refreshBookings")}>
          <IconButton onClick={refreshBookings}>
            <Icon>refresh</Icon>
          </IconButton>
        </Tooltip>
      </Box>
      {!templateModeProps ? (
        <Box display="flex">
          <Box display="none">
            <input
              //ref={excelInputRef}
              type="file"
              onChange={handleCreateBookingsFromFile}
            />
          </Box>
          {query?.filters.status === "deliveryCompleted" && (
            <Tooltip key="choose-tooltip" title={t("chooseTemplate")}>
              <IconButton color="primary" onClick={handleOnTemplateMode}>
                <div
                  style={{
                    backgroundImage: `url(${listIcon})`,
                    backgroundSize: "29px",
                    width: "29px",
                    height: "32px",
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {selectedBookings.length ? ( //multselect
            <>
              {selectedBookings.every(canDownloadPdf) && (
                <Box m={1} mb={2}>
                  <NavPdfDownloaderMany
                    bookingIds={selectedBookings
                      .filter((booking) => booking.type !== "Buffer")
                      .map(({ bookingId }) => bookingId)}
                  />
                </Box>
              )}
              <Box m={1} mb={2}>
                <DownloadLabelsPdf bookings={selectedBookings} />
              </Box>
              {selectedBookings.every(canDeleteBooking) && (
                <Box mt="4px">
                  <Tooltip title={t("delete")}>
                    <AlertDialog handleSubmit={handleDeleteBookings}>
                      <IconButton>
                        <Icon>delete</Icon>
                      </IconButton>
                    </AlertDialog>
                  </Tooltip>
                </Box>
              )}
              {selectedBookings.every(canConfirmBooking) && (
                <Tooltip title={t("ready")}>
                  <IconButton onClick={handleConfirmBookings}>
                    <Icon color="primary">redo</Icon>
                  </IconButton>
                </Tooltip>
              )}
              {selectedBookings.every(canUnconfirmBooking) && (
                <Tooltip title={t("notReady")}>
                  <IconButton onClick={handleUnconfirmBookings}>
                    <Icon color="primary">redo</Icon>
                  </IconButton>
                </Tooltip>
              )}
            </>
          ) : null}
        </Box>
      ) : (
        <>
          <IconButton color="primary" onClick={handleOnTemplateMode}>
            <Box display="flex">
              <Icon>arrow_downward</Icon>
              {t("chooseTemplate")}
            </Box>
          </IconButton>
          <Tooltip key="cancel-tooltip" title={t("cancel")}>
            <IconButton color="secondary" onClick={handleOffTemplateMode}>
              <Icon>clear</Icon>
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  );
};
