import * as XLSX from "xlsx-js-style";

const getTextFromCell = (el) => {
  if (el.props && el.props.children !== undefined) {
    //react element
    if (Array.isArray(el.props.children)) {
      if (el.props.children.every((a) => typeof a === "string")) {
        return el.props.children.join("");
      } else {
        return el.props.children.map((c) => getTextFromCell(c)).join(",");
      }
    } else {
      return getTextFromCell(el.props.children);
    }
  } else if (typeof el === "string") {
    //string
    return el;
  } else {
    if (el instanceof Array) {
      if (el[0] && el[0].key && el[0].value) {
        return el
          .map(
            ([key, value]) =>
              `${String(key?.replace("&dot;", "."))}:${String(value)}`
          )
          .join(", ");
      } else {
        return el.map(getTextFromCell).join(" ");
      }
    } else {
      //object
      return Object.entries(el)
        .map(
          ([key, value]) =>
            `${String(key?.replace("&dot;", "."))}:${String(value)}`
        )
        .join(", ");
    }
  }
};

export default (columns, rows) => {
  //console.log(columns);
  const getColumnDef = (f) =>
    columns.reverse().find(({ field }) => field === f) || {};
  //console.log(rows);
  const data = rows.map((row) =>
    Object.entries(row)
      .filter(([key, value]) => {
        //console.log("filtering", key, getColumnDef(key));
        return (
          (getColumnDef(key).hidden !== undefined &&
            getColumnDef(key).hidden === false) ||
          key === "packages"
        );
      })
      .reduce((acc, [key, value]) => {
        // console.log("----");
        // console.log(key);
        // console.log(getColumnDef(key));
        if (key == "packages") {
          return {
            ...acc,
            ...value.reduce((packagesAcc, [packageKey, packageValue]) => {
              if (packageKey && packageValue) {
                return {
                  ...packagesAcc,
                  [packageKey.replace("&dot;", ".")]: packageValue,
                };
              } else {
                return packagesAcc;
              }
            }, {}),
          };
        } else {
          let renderFunction = getColumnDef(key).render;
          const renderedValue = renderFunction ? renderFunction(row) : value;
          // console.log(renderFunction);
          // console.log(renderedValue);
          return {
            ...acc,
            [key]:
              typeof renderedValue === "string" ||
              typeof renderedValue === "number"
                ? renderedValue
                : getTextFromCell(renderedValue),
          };
        }
      }, {})
  );
  console.log("export excel data");
  console.log(data);
  const dataWS = XLSX.utils.json_to_sheet(data);
  //dataWS["A1"].s = { fill: { bgColor: { rgb: "E9E9E9" } } };
  console.log("export excel data ws");
  console.log(dataWS);
  const excel = XLSX.utils.book_new(); // make Workbook of Excel
  XLSX.utils.book_append_sheet(excel, dataWS, "bookings"); // sheetAName is name of Worksheet

  XLSX.writeFile(excel, "book.xlsx");
};
