import React from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import { Packaging, FieldParameters } from "navision-proxy-api/@types";

import { PackagePicker } from "./PackagePicker";
import { useStore } from "context/store";
import { useLang } from "context/lang";
import { useAuth } from "context/auth";

interface IProps {
  /** Id for DOM manipulations */
  id?: string;
  onChange?: (newValue: Packaging) => void;
  value?: Packaging;
  /** Enable editing */
  editing?: boolean;
  /** Name of packages can be duplicated. */
  enableDuplicateKeys?: boolean;
  /** Packages list */
  packagesLookup: string[];
  /** Other props */
  [key: string]: any;
}

/** Container for package picker. Handles actions with packages */
export default ({
  id,
  onChange,
  value = [],
  editing = true,
  enableDuplicateKeys = false,
  packagesLookup,
  ...props
}: IProps) => {
  const {
    user: { appConfig },
  } = useAuth();

  const defaultValue: Packaging = appConfig
    ? appConfig.formDefaults.packagesUnits.map((p: string) => [p, ""])
    : [];

  const lookup = packagesLookup
    ? packagesLookup.filter((key) =>
        enableDuplicateKeys
          ? true
          : value
          ? !value.find(([k, v]) => key === k)
          : defaultValue
          ? !defaultValue.find(([k, v]) => key === k)
          : false
      )
    : [];

  const handleChange = (index: number, key: string, val: string) => {
    const entries = [...value];
    entries[index] = [key, val];
    if (onChange) {
      onChange(entries as Packaging);
    }
  };

  /** add new empty package */
  const handlePush = () => {
    if (onChange) {
      onChange([
        ...(value || defaultValue),
        [lookup[0], ""],
      ] as unknown as Packaging);
    }
  };

  /** Remove package */
  const handleClear = (index: number) => {
    const entries = value;
    entries.splice(index, 1);
    if (onChange) {
      onChange(entries);
    }
  };

  return (
    <Box id={id}>
      <PackagePicker
        onChange={handleChange}
        onPush={handlePush}
        onRemove={handleClear}
        editing={editing}
        packages={(value || defaultValue).map(([key, v]) => ({
          key,
          value: v,
        }))}
        lookup={lookup}
        {...props}
      />
    </Box>
  );
};
