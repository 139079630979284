import React from "react";
import Box from "@material-ui/core/Box";
import MaterialTable, { MTableBodyRow } from "material-table";
import { Icon } from "@material-ui/core";
import _ from "lodash";

import { IBooking } from "navision-proxy-api/@types";
import { BookingsRequestQuery } from "navision-proxy-api/@types/api";

import { useStyles } from "styles/index";
import { useBookingsMTableColumns } from "hooks/mTableColumns";
import { useBookings } from "hooks/bookings";
import { useStore } from "context/store";

import VirtualizedTableBody from "components/BookingMaterialTable/VirtualizedTableBody";
import { useFieldsSettings } from "context/fieldsSettings";
import { useTheme } from "@material-ui/core/styles";

import { Icons } from "material-table";

interface IProps {
  initQuery: BookingsRequestQuery;
}

export default ({ initQuery }: IProps) => {
  const styles = useStyles();
  const theme = useTheme();

  const { handleSaveColumnsWidth, setHiddenPackages } = useFieldsSettings();

  const { columns } = useBookingsMTableColumns({
    ignoredColumns: [
      //"packages"
      //"delivery",
      //"pickup",
      //"pickupDate",
      //"pickupTime",
      //"departureDate",
      "customersCustomer",
      "deadline",
      "ediReference",
      "movementStatus",
      "trackingNumber",
    ],
    notEditableColumns: [
      "bookingId",
      "pickupDate",
      "tourTime",
      "receiver",
      "customerNb",
      "customersCustomer",
      "customer",
      "pickup",
      "isLocal",
    ],
    sortableColumns: ["tourTime", "customer", "receiver", "pickup", "delivery"],
    verticalPackagesView: false,
  });

  const { bookingFormData } = useStore();

  const { bookings, loading, loadBookings } = useBookings();

  React.useEffect(() => {
    //hide packages which are not used
    const notUsedPackages = _.difference(
      bookingFormData.packageNames,
      bookings && bookings.usedPackages ? bookings.usedPackages : []
    );
    setHiddenPackages(
      notUsedPackages.map<`packages.${string}`>((p) => `packages.${p}`)
    );
  }, [bookings]);

  React.useEffect(() => {
    loadBookings(initQuery);
  }, [initQuery]);

  const tableRef = React.useRef<any>();

  return (
    <div className={styles.bookingsList}>
      <Box
        onMouseUp={() =>
          //resize column catcher
          //handle columns resize
          handleSaveColumnsWidth(tableRef.current?.state?.columns)
        }
        mt={5}
      >
        <MaterialTable<IBooking>
          tableRef={tableRef}
          data={bookings ? bookings.items : []}
          columns={columns}
          isLoading={loading["load"]}
          //   columnWidths={{
          //     tourTime: 140,
          //   }}
          options={{
            paging: false,
            tableLayout: "fixed",
            columnResizable: true,
            toolbar: false,
            draggable: false,
          }}
          icons={
            {
              Resize: (props: any) => (
                <Icon
                  {...props}
                  style={{
                    ...props.style,
                    color: theme.palette.grey[400],
                    transform: "rotate(90deg)",
                    position: "absolute",
                    right: "5px",
                  }}
                >
                  drag_handle
                </Icon>
              ),
            } as Icons
          }
          components={{
            //...props.components,
            Body: (
              bodyProps //infinite scroll body
            ) => (
              <VirtualizedTableBody
                {...bodyProps}
                scrollWidth={undefined}
                tableRef={tableRef}
              />
            ),
            Row: (props) => (
              <MTableBodyRow
                {...props}
                scrollWidth={undefined}
                data-test="bookings-table-body-row"
                className={
                  props.data.type === "Buffer" ? "waiting-table-row" : ""
                }
              />
            ),
          }}
        />
      </Box>
    </div>
  );
};
