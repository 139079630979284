import React from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Loader } from "../Loader";
import { useLang } from "../../context/lang";
import { PackageField } from "components/PackagePicker/PackageField";
import { Packaging } from "navision-proxy-api/@types";

interface IProps {
  packages: { key: string; value: string }[];
  label?: string;
  /** Add new empty package */
  onPush: () => void;
  /** Show in row arrangment */
  row?: boolean;
  /** Remove package */
  onRemove: (index: number) => void;
  /** Minimum number of packages */
  minLength?: number;
  descriptionLabel?: string;
  description?: string;
  onChangeDescription?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** other props */
  [key: string]: any;
}

export const PackagePicker = ({
  packages = [{ key: "CC", value: "" }],
  label,
  onPush,
  row = false,
  onRemove,
  minLength = 0,
  descriptionLabel,
  description = " ",
  onChangeDescription,
  ...props
}: IProps) => {
  const { t } = useLang();
  return (
    <Loader loading={props.loading}>
      <Box mb={2}>
        <Box
          display="flex"
          flexDirection={row ? "row" : "column"}
          flexWrap="wrap"
        >
          {label && (
            <Box width={1}>
              <Typography variant="overline">{label}</Typography>
            </Box>
          )}
          <Box
            display="flex"
            flexDirection={row ? "row" : "column"}
            flexWrap="wrap"
            minHeight={50}
          >
            {packages.map(({ key, value }, index) => (
              <PackageField
                key={index}
                packageKey={key}
                value={value}
                index={index}
                descriptionLabel={descriptionLabel}
                onChange={props.onChange}
                {...props}
              />
            ))}
          </Box>
          {props.errorText && (
            <Box>
              <Typography color="secondary">{props.errorText}</Typography>
            </Box>
          )}
          <Box display="flex" mt="-18px">
            {packages.length > minLength && onRemove && (
              <IconButton
                size="small"
                color="secondary"
                onClick={() => onRemove(packages.length - 1)}
              >
                <Icon fontSize="small">clear</Icon>
              </IconButton>
            )}
            {onPush && (
              <Button color="primary" onClick={onPush}>
                <Icon>add</Icon>
              </Button>
            )}
          </Box>
        </Box>
        {onChangeDescription && (
          <TextField
            fullWidth
            value={description}
            onChange={onChangeDescription}
            //inputProps={{ onBlur: onChangeDescription }}
            label={descriptionLabel}
            inputProps={{ maxLength: 30 }}
          />
        )}
      </Box>
    </Loader>
  );
};
