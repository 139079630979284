import React, { ReactElement, SyntheticEvent, ReactChildren } from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useLang } from "context/lang";

type MessageType = "success" | "error" | "warning";

interface ISnackbarContext {
  openSnackbar: (message: string, severity?: MessageType) => void;
}

interface ISnackbar {
  isOpen: boolean;
  severity?: MessageType;
  message?: string;
}

export const SnackbarContext = React.createContext<ISnackbarContext>(
  {} as ISnackbarContext
);

function SnackbarProvider(props: {
  children: JSX.Element | JSX.Element[];
}): ReactElement {
  const { t } = useLang();
  const [snackbar, setSnackbar] = React.useState<ISnackbar>({
    isOpen: false,
    severity: "success",
    message: "",
  });

  const openSnackbar = (message: string, severity: MessageType = "success") => {
    setSnackbar({
      isOpen: true,
      message: `${t(message)}`,
      severity,
    });
  };

  const handleClose = (
    event: SyntheticEvent<Element, Event>,
    reason: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar({ isOpen: false });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbar.isOpen}
        autoHideDuration={
          snackbar.severity === "error" || snackbar.severity === "warning"
            ? 100000
            : 3000
        }
        onClose={handleClose}
      >
        <MuiAlert
          id={`message-${snackbar.severity}`}
          elevation={6}
          variant="filled"
          onClose={
            handleClose as (event: SyntheticEvent<Element, Event>) => void
          }
          severity={snackbar.severity}
        >
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
      <SnackbarContext.Provider
        value={{
          openSnackbar,
        }}
        {...props}
      />
    </>
  );
}

const useSnackbar = () => React.useContext(SnackbarContext);

export { useSnackbar, SnackbarProvider };
