import React from "react";
import _ from "lodash";

import { BookingsFormDataResponse } from "navision-proxy-api/@types/api";
import { IBooking, ICustomersCustomer } from "navision-proxy-api/@types";

import { useLoading, Loading } from "hooks/loading";

import { useApi } from "hooks/api";
import { useLocation } from "react-router-dom";

interface IStoreContext {
  bookingFormData: BookingsFormDataResponse;
  loadBookingFormData: () => void;
  loading: Loading<string[]>;
  //clearStore: () => void;
  /** Load booking template to create booking form */
  loadTemplate: (booking: IBooking) => void;
  /** Choose template from bookings list mode */
  templateModeProps: Partial<IBooking> | null;
  setTemplateModeProps: React.Dispatch<
    React.SetStateAction<Partial<IBooking> | null>
  >;
  getCustomerCustomersLookup: () => ICustomersCustomer[];
  refreshTimeoutRef: React.MutableRefObject<NodeJS.Timeout | undefined>;
}

const Store = React.createContext<IStoreContext>({} as IStoreContext);

function StoreProvider(props: {
  children: React.ReactElement;
}): React.ReactElement {
  const [bookingFormData, setBookingFormData] =
    React.useState<BookingsFormDataResponse>({
      fields: [],
      countries: [],
      customerCustomers: [],
      packageNames: [],
      goodNames: [],
    });

  const { loading, loadingOn, loadingOff } = useLoading(["load"]);

  const { fetchBookingFormData } = useApi();

  const location = useLocation();

  //we store timeout ref (for refreshing bookings list) globally to be able to cancel it when location is changed
  const refreshTimeoutRef = React.useRef<NodeJS.Timeout>();
  React.useEffect(() => {
    if (refreshTimeoutRef.current) {
      //if refreshTimeout.current is undefined means hook was unmounted
      clearTimeout(refreshTimeoutRef.current);
      refreshTimeoutRef.current = undefined;
    }
  }, [location]);

  /** null signilize that template mode is off */
  const [templateModeProps, setTemplateModeProps] =
    React.useState<Partial<IBooking> | null>(null);

  React.useEffect(() => {
    loadBookingFormData();
  }, []);

  const getCustomerCustomersLookup = () => bookingFormData.customerCustomers;

  const loadBookingFormData = async () => {
    loadingOn("load");
    const data = await fetchBookingFormData();
    // const palletType = data.fields.find(({ id }) => id === "palletType");
    setBookingFormData(data);
    loadingOff("load");
  };

  const loadTemplate = (bookingData: IBooking) => {
    setTemplateModeProps({
      ..._.omit(bookingData, ["__v", "_id"]),
      //packages: newPackages,
    });
  };

  return (
    <Store.Provider
      value={{
        bookingFormData,
        loadBookingFormData,
        loading,
        loadTemplate,
        templateModeProps,
        setTemplateModeProps,
        getCustomerCustomersLookup,
        refreshTimeoutRef,
      }}
      {...props}
    />
  );
}

const useStore = () => React.useContext(Store);

export { useStore, StoreProvider };
