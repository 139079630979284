import React from "react";
import _ from "lodash";

import { useSnackbar } from "context/snackbar";

import { useLoading } from "hooks/loading";

import {
  BookingsResponse,
  BookingsRequestQuery,
} from "navision-proxy-api/@types/api";

import { useApi } from "hooks/api";
import { useHistory, useLocation } from "react-router-dom";
import routes from "config/routes";
import { realpathSync } from "fs";
import { useStore } from "context/store";

interface IProps {
  /** If bookings list contain bookings from buffer, refresh it every minute.
   *  If bookings list doesn't contain buffered bookings, refresh it every 10 mins. */
  refreshBookings?: boolean;
}

/** Hook for loading bookings. */
export function useBookings(
  { refreshBookings }: IProps = { refreshBookings: false }
) {
  const { openSnackbar } = useSnackbar();
  const { fetchBookings: fetchBookingsAPI } = useApi();
  const [bookings, setBookings] = React.useState<BookingsResponse>({
    items: [],
  } as BookingsResponse);

  const { loading, loadingOn, loadingOff } = useLoading(["load"]);

  const lastLoadPromise = React.useRef<Promise<BookingsResponse>>();

  const location = useLocation();

  /** Current filters */
  const [query, loadBookings] = React.useState<BookingsRequestQuery>({
    filters: {},
  });

  React.useEffect(() => {
    if (!_.isEmpty(query.filters)) {
      _fetchBookings();
    }
  }, [JSON.stringify(query.filters)]);

  /** Fetch bookings using query. Triggered by changing the query with loadBookings */
  const _fetchBookings = async () => {
    if (query) {
      loadingOn("load"); //TODO:add error handling

      //avoiding race conditions
      const currentLoadPromise = fetchBookingsAPI(query);
      lastLoadPromise.current = currentLoadPromise;
      const bookings = await currentLoadPromise;
      if (lastLoadPromise.current === currentLoadPromise) {
        setBookings({ ...bookings });
      }

      if (refreshBookings) {
        //console.log("prev timeout in fetch bookings", refreshTimeoutRef.current);

        //next refresh
        setTimeoutRefreshBookings(
          bookings.items.some((item) => item.type === "Buffer")
            ? 1000 * 60 //1 min
            : 1000 * 60 * 10 //10mins
        );
      }

      loadingOff("load");
    }
  };

  const reloadBookings = () => {
    _fetchBookings();
  };

  /** We want to refresh bookings list from time to time if there are buffered bookings.
   * This is variable to store this timeout
   */
  //const refreshTimeout = React.useRef<NodeJS.Timeout>();
  const { refreshTimeoutRef } = useStore();

  const setTimeoutRefreshBookings = (timeoutTime: number) => {
    // if (
    //   invokedLocationPathname.current &&
    //   location.pathname !== invokedLocationPathname.current
    // ) {
    //   //of we alraady on another location dont set timeout
    //   return;
    // } else {
    if (refreshTimeoutRef.current) {
      //if refreshTimeout.current is undefined means hook was unmounted
      clearTimeout(refreshTimeoutRef.current);
      refreshTimeoutRef.current = undefined;
    }
    if (location.pathname == window.location.pathname) {
      //check if timeout context location matches actual location
      //means we are still on the same screen
      const timeout = setTimeout(_fetchBookings, timeoutTime);

      refreshTimeoutRef.current = timeout;
    }
  };

  return {
    bookings,
    query,
    loadBookings,
    loading,
    reloadBookings,
  };
}
