import React from "react";
import { useLang } from "../context/lang";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";
import { Loader } from "components/Loader";
import { LangCode } from "navision-proxy-api/@types";
import { useAuth } from "context/auth";

export const LanguagePicker = () => {
  const [menuEl, setMenuEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { t, getAvailableLangs } = useLang();
  const { switchLanguage } = useAuth();
  return (
    <Box width={125} display="flex">
      <Loader loading={loading} margin={1}>
        <Button
          aria-controls="lang-menu"
          aria-haspopup="true"
          onClick={(e: any) => setMenuEl(e.currentTarget)}
          size="small"
          variant="text"
        >
          <Box mx={1} clone>
            <img width="24" src={t("flagUrl")} />
          </Box>
          <Box display="flex">
            <span>{t("languageName")}</span>
            {Boolean(menuEl) ? (
              <Icon color="primary">expand_less</Icon>
            ) : (
              <Icon color="primary">expand_more</Icon>
            )}
          </Box>
        </Button>
      </Loader>
      <Menu
        id="lang-menu"
        anchorEl={menuEl}
        open={Boolean(menuEl)}
        onClose={() => setMenuEl(null)}
      >
        {getAvailableLangs().map((lang, key) => (
          <MenuItem
            key={key}
            onClick={async () => {
              setMenuEl(null);
              setLoading(true);
              await switchLanguage(lang.code);
              setLoading(false);
            }}
          >
            <Box mx={1} clone>
              <img width="24" src={lang.flagUrl} />
            </Box>
            {lang.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
