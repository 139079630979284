import { IBooking } from "navision-proxy-api/@types";
import { BookingMovementStatus } from "types";

export const canConfirmBooking = (booking: IBooking) =>
  ((booking.movementStatus || "") as BookingMovementStatus) ==
    BookingMovementStatus.unconfirmed &&
  booking.type !== "Buffer" &&
  !(booking.status == "deliveryCompleted");

export const canUnconfirmBooking = (booking: IBooking) =>
  ((booking.movementStatus || "") as BookingMovementStatus) ==
    BookingMovementStatus.confirmed && booking.type !== "Buffer";

export const canDeleteBooking = (booking: IBooking) =>
  !(booking.status == "deliveryCompleted");

export const canDownloadPdf = (booking: IBooking) => booking.type !== "Buffer";
