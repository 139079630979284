import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";

import MaterialTable, {
  MTableAction,
  MTableToolbar,
  MTableEditRow,
  EditComponentProps,
  MTableBodyRow,
  Icons,
  MTableHeader,
} from "material-table";
import { ObjectId } from "mongoose";
import _ from "lodash";

import { IBookingsMap } from ".";
import { BookingFieldId, IBooking } from "navision-proxy-api/@types";

import { Loader } from "components/Loader";
import { useBookingsMTableColumns } from "hooks/mTableColumns";

import { useDraggable } from "hooks/draggable";
import { useLang } from "context/lang";
import { useFieldsSettings } from "context/fieldsSettings";
import KeyListener from "components/KeyListener";
import { BookingsResponse } from "navision-proxy-api/@types/api";
import AlertDialog from "components/AlertDialog";

interface IProps {
  initBookings: BookingsResponse;
  handleSubmit: (bookings: IBookingsMap) => void;
  ignoredColumns: BookingFieldId[];
  loading: boolean;
  handleDelete: (id: ObjectId) => void;
  handleReorderRows: (oldIndex: number, newIndex: number) => void;
}

const TableForm = ({
  // local,
  // currentQuickForm,
  // muiltiFormFields,
  // requiredFields,
  // addLineRequiredFields,
  // editing,
  // adding,
  // setAdding,
  loading,
  initBookings,
  handleSubmit,
  ignoredColumns,
  handleDelete,
  handleReorderRows,
}: // validateNewLine,
// addLineActionRef,
IProps) => {
  const { t } = useLang();
  const theme = useTheme();
  const { handleSaveColumnsWidth } = useFieldsSettings();
  const { getDraggbleProps, getDraggingButton } =
    useDraggable(handleReorderRows);
  const { columns } = useBookingsMTableColumns({
    ignoredColumns: [
      "bookingId",
      //"itemId",
      "status",
      // "pickupTime",
      // "tourTime",
      "deliveryDate",
      "deliveryTime",
      "pickupDate",
      "pickupTime",
      "tourTime",
      "latestDeliveryTime",
      "latestDeliveryDate",
      ...ignoredColumns,
    ],
    alwaysUnhiddenColumns: ["packages", "goods"],
    notEditableColumns: [
      "receiver",
      "customerNb",
      "customer",
      "pickup",
      "delivery",
    ],
    sortableColumns: [
      "pickupDate",
      "customer",
      "receiver",
      "pickup",
      "delivery",
      "isLocal",
    ],
    verticalPackagesView: true,
    customColumns: [
      {
        field: "_id",
        title: t("actions"),
        hidden: false,
        resizable: false,
        width: 100,
        sorting: false,
        editComponent: ({ rowData: { _id } }: EditComponentProps<IBooking>) => {
          return (
            <Box display="flex">
              <Tooltip title={t("changeOrder")}>
                <Box mr={1}>{getDraggingButton()}</Box>
              </Tooltip>
              <Tooltip title={t("delete")}>
                <AlertDialog handleSubmit={() => handleDelete(_id)}>
                  <IconButton>
                    <Icon>delete</Icon>
                  </IconButton>
                </AlertDialog>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
  });

  const tableRef = React.useRef<any>(null);

  //we getting this actions from material table when rendering
  let editAllAction: () => void;
  let saveAllAction: () => void;

  // const newRowRef = React.useRef(); //this usedd when we add new row we want to have it props and state

  // React.useLayoutEffect(() => {
  //   if (
  //     adding &&
  //     tableRef.current &&
  //     tableRef.current.tableContainerDiv.current
  //   ) {
  //     tableRef.current.tableContainerDiv.current.scrollTop =
  //       tableRef.current.tableContainerDiv.current.scrollHeight;
  //   }
  // }, [adding]);

  //

  // const {
  //   user: { appConfig },
  // } = useAuth();

  // const handleConfirmNewBooking = () => {
  //   //validation
  //   if (
  //     validateNewLine({
  //       ...newRowRef.current.state.data,
  //       ...bookingFormData.booking,
  //     })
  //   ) {
  //     newRowRef.current.handleSave();
  //   }
  // };

  // const handleDiscardNewBooking = () => {
  //   const { props: newRowProps } = newRowRef.current;
  //   newRowProps.onEditingCanceled(newRowProps.mode, newRowProps.data);
  //   setAdding(false);
  //   initBookingForm();
  // };

  const handleBook = () => {
    saveAllAction();
  };

  const bookingsEditableActions = {
    onBulkUpdate: async (
      editedData: Record<
        number,
        {
          oldData: IBooking;
          newData: IBooking;
        }
      >
    ) => {
      handleSubmit(
        Object.values(editedData).reduce<IBookingsMap>(
          (acc, { newData }: { newData: IBooking }) => ({
            ...acc,
            [String(newData._id)]: newData,
          }),
          {}
        ) as IBookingsMap
      );
    },
  };

  return (
    <>
      <Grid item xs={12}>
        <Box
          onMouseUp={() => {
            //handle columns resize
            handleSaveColumnsWidth(tableRef.current?.state?.columns);
          }}
          maxHeight="70vh;"
        >
          <MaterialTable<IBooking>
            data={initBookings ? initBookings.items : []}
            columns={columns}
            editable={bookingsEditableActions}
            icons={
              {
                Resize: (props: any) => {
                  return (
                    <Icon
                      {...props}
                      style={{
                        ...props.style,
                        color: theme.palette.grey[400],
                        transform: "rotate(90deg)",
                        position: "absolute",
                        right: "5px",
                      }}
                    >
                      drag_handle
                    </Icon>
                  );
                },
              } as Icons
            }
            // actions={actions}
            // requiredLabelColumns={adding ? addLineRequiredFields : requiredFields}
            options={{
              paging: false,
              search: false,
              showTitle: false,
              toolbar: true,
              tableLayout: "fixed",
              columnResizable: true, //TODO make resizable
              addRowPosition: "last",
              actionsColumnIndex: -2, // -2 means we don't render actions column and do the custom stuff
              maxBodyHeight: "70vh",
              draggable: false,
            }}
            components={{
              Action: (props) => {
                if (props.action.tooltip === "Edit All") {
                  //kinda hack for always keeping table in edit mode
                  const EditButton = () => {
                    React.useLayoutEffect(() => {
                      if (
                        tableRef.current &&
                        !tableRef.current["dataManager"]["bulkEditOpen"] &&
                        editAllAction
                        // !adding &&
                        // !editing
                      ) {
                        editAllAction();
                      }
                    }, []);
                    return null;
                  };

                  return <EditButton />;
                }

                return <MTableAction {...props} />;
              },
              Toolbar: (
                props //we need to render for add actions in toolbar but not display it
              ) => {
                // addAction = props.actions.find(
                //   ({ tooltip }) => tooltip === "Add"
                // ).onClick;
                editAllAction = props.actions.find(
                  ({ tooltip }: { tooltip: string }) => tooltip === "Edit All"
                ).onClick;
                saveAllAction = props.actions.find(
                  ({ tooltip }: { tooltip: string }) =>
                    tooltip === "Save all changes"
                ).onClick;
                // discardAllAction = props.actions.find(
                //   ({ tooltip }) => tooltip === "Discard all changes"
                // ).onClick;
                return (
                  <Box display="none">
                    <MTableToolbar {...props} />
                  </Box>
                );
              },
              Header: (props) => {
                //TODO: fork material table library and make props passing to the children for MTableHeader
                return (
                  <>
                    <MTableHeader
                      {...props}
                      {...getDraggbleProps(-1, {
                        //header item index should be -1 because it is located before actual rows
                        onEnterEffect: (e: any) => {
                          for (var element of document.getElementsByClassName(
                            //remove previous
                            "draggable-destination-item"
                          )) {
                            element.remove();
                          }

                          //hightligh row before all rows
                          const rowToHighlightBefore =
                            document.getElementById(`draggable-item-0`);

                          const tr = document.createElement("tr");
                          tr.setAttribute(
                            "width",
                            rowToHighlightBefore?.getAttribute("scrollwidth") ||
                              ""
                          );
                          const innderDiv = document.createElement("div");
                          innderDiv.style.width = `${
                            parseInt(
                              rowToHighlightBefore?.getAttribute(
                                "scrollwidth"
                              ) || "1000"
                            ) - 10
                          }px`;

                          tr.appendChild(innderDiv);
                          tr.classList.add("draggable-destination-item");
                          rowToHighlightBefore?.parentNode?.insertBefore(
                            tr,
                            rowToHighlightBefore?.parentNode?.firstChild
                          );
                        },
                        onLeaveEffect: (e: any) => {
                          for (var element of document.getElementsByClassName(
                            "draggable-destination-item"
                          )) {
                            element.remove();
                          }
                          // const currentDraggableItem = document.getElementById(
                          //   `draggable-item-${props.data.tableData.id}`
                          // );
                          // if (
                          //   //stop highlighing draggable row
                          //   currentDraggableItem?.nextElementSibling?.classList.contains(
                          //     "draggable-destination-item"
                          //   )
                          // ) {
                          //   currentDraggableItem?.nextSibling?.remove();
                          // }
                        },
                      })}
                    />
                  </>
                );
              },
              EditRow: (props) => {
                const rowIndex = parseInt(props.data.tableData.id);
                return (
                  <MTableEditRow
                    {...props}
                    onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                      //avoid submit onEnter
                      if (e.key === "Enter") {
                        e.stopPropagation();
                        e.preventDefault();
                      }
                    }}
                    {...getDraggbleProps(rowIndex, {
                      onEnterEffect: (e: any) => {
                        for (var element of document.getElementsByClassName(
                          //remove previous
                          "draggable-destination-item"
                        )) {
                          element.remove();
                        }

                        const currentDraggableItem = document.getElementById(
                          `draggable-item-${rowIndex}`
                        );
                        if (
                          //highlight draggable row
                          !currentDraggableItem?.nextElementSibling?.classList.contains(
                            "draggable-destination-item"
                          )
                        ) {
                          const tr = document.createElement("tr");
                          tr.setAttribute(
                            "width",
                            currentDraggableItem?.getAttribute("scrollwidth") ||
                              ""
                          );
                          const innderDiv = document.createElement("div");
                          innderDiv.style.width = `${
                            parseInt(
                              currentDraggableItem?.getAttribute(
                                "scrollwidth"
                              ) || "1000"
                            ) - 10
                          }px`;

                          tr.appendChild(innderDiv);
                          tr.classList.add("draggable-destination-item");
                          currentDraggableItem?.parentNode?.insertBefore(
                            tr,
                            currentDraggableItem?.nextSibling
                          );
                        }
                      },
                      onLeaveEffect: (e: any) => {
                        for (var element of document.getElementsByClassName(
                          "draggable-destination-item"
                        )) {
                          element.remove();
                        }
                        // const currentDraggableItem = document.getElementById(
                        //   `draggable-item-${props.data.tableData.id}`
                        // );
                        // if (
                        //   //stop highlighing draggable row
                        //   currentDraggableItem?.nextElementSibling?.classList.contains(
                        //     "draggable-destination-item"
                        //   )
                        // ) {
                        //   currentDraggableItem?.nextSibling?.remove();
                        // }
                      },
                    })}
                  />
                );
              },
            }}
            tableRef={tableRef}
          />
        </Box>
      </Grid>

      {/* <Box
        width="100%"
        display="flex"
        mr="2rem"
        mt="-30px"
        justifyContent="flex-end"
      >
        {adding ? (
          <KeyListener
            keyName="Enter"
            action={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (
                confirmNewLineButton.current &&
                e.target.nodeName === "BODY"
              ) {
                confirmNewLineButton.current.click();
              }
            }}
          >
            <Box bgcolor="white !important" clone>
              <Buttonimport { IBookingsMap } from 'src/components/QuickForm/index';
import { BookingFieldId } from 'navision-proxy-api/@types';
import { ObjectId } from 'mongoose';
import { useDraggable } from 'src/hooks/draggable';

                disableRipple
                color="primary"
                size="large"
                variant="outlined"
              >
                <IconButton
                  ref={confirmNewLineButton}
                  color="primary"
                  onClick={handleConfirmNewBooking}
                >
                  <Icon fontSize="large">check</Icon>
                  <Box fontSize={16} mr={1}>
                    {t("addLine")}
                  </Box>
                </IconButton>
                &nbsp;
                <Tooltip key="cancel-new-line" title={t("cancel")}>
                  <IconButton onClick={handleDiscardNewBooking}>
                    <Icon fontSize="large" color="secondary">
                      clear
                    </Icon>
                  </IconButton>
                </Tooltip>
              </Button>
            </Box>
          </KeyListener>
        ) : (
          
        )}
      </Box> */}

      <Box
        display="flex"
        height={50}
        mt={5}
        width="100%"
        justifyContent="flex-start"
      >
        {initBookings.items.length > 0 && (
          <KeyListener
            keyName="Enter"
            ctrl
            action={(e: KeyboardEvent) => {
              e.preventDefault();
              e.stopPropagation();
              const activeElement = document.activeElement;
              if (activeElement instanceof HTMLElement) {
                activeElement?.blur();
              }
              handleBook();
            }}
          >
            <Button
              id="submit"
              size="large"
              color="primary"
              variant="contained"
              onClick={handleBook}
              disabled={loading}
              startIcon={
                <Loader size={20} loading={loading}>
                  <Icon>save</Icon>
                </Loader>
              }
            >
              {t("createOrder")}
            </Button>
          </KeyListener>
        )}
      </Box>
    </>
  );
};

export default React.memo(TableForm, (prevProps, nextProps) => {
  if (
    prevProps.initBookings.items.length ===
      nextProps.initBookings.items.length &&
    prevProps.initBookings.items.every(
      (item, index) => item._id === nextProps.initBookings.items[index]._id
    ) &&
    prevProps.loading === nextProps.loading
  ) {
    return true;
  } else {
    return false;
  }
});
