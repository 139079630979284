import * as React from "react";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { PropTypes } from "@material-ui/core";
import { useLang } from "context/lang";
import Box from "@material-ui/core/Box";
import { Loader } from "components/Loader";

export default function AlertDialog({
  text,
  handleSubmit,
  children,
}: {
  text?: string;
  handleSubmit: () => void;
  children: React.ReactElement | string;
}) {
  const [open, setOpen] = React.useState(false);

  const { t } = useLang();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitDialog = () => {
    handleSubmit();

    //handleClose();
  };

  return (
    <Box display="flex">
      <div onClick={handleClickOpen}>{children}</div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {text || t("unrevertableAction")}
        </DialogTitle>
        <DialogActions>
          <Button color="default" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button color="secondary" onClick={handleSubmitDialog} autoFocus>
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
