import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import moment from "moment";
import "moment/locale/da";
import "moment/locale/de";
import "moment/locale/sv";
import "moment/locale/nl";

import {
  ICustomersCustomer,
  FieldParameters,
  IBooking,
  BookingFieldId,
  IAddress,
} from "navision-proxy-api/@types";

import { LangCode } from "context/lang";
import { Link } from "@material-ui/core";

const isDateNotEmpty = (date: string) =>
  date && date !== "0001-01-01T00:00:00Z";

//TODO: refactor this to component
export default ({
  path,
  // addresses,
  // getCustomerCustomersLookup,
  t,
  lang,
  history,
}: {
  path: FieldParameters;
  // addresses: { [addressCode: string]: IAddress };
  // getCustomerCustomersLookup: () => ICustomersCustomer[];
  t: (key: string) => string;
  lang: LangCode;
  history: any;
}) => {
  const addressComponent = (value: IBooking) => {
    //TODO change to bookingFormField
    // const ccItem = getCustomerCustomersLookup()?.find(
    //   (item) =>
    //     item[`${path.id}Code` as keyof ICustomersCustomer] ==
    //     value[path.id as keyof IBooking]
    // );

    // const address = addresses[value[path.id as keyof IBooking]];

    // return address ? (
    //   <>
    //     <Typography variant="body2">{`${address.name}`}</Typography>
    //     <Box fontSize={12}>({`${address.address}`})</Box>
    //   </>
    // ) : (
    //   <Typography color="textSecondary" variant="caption">
    //     {t("addressLoading")}
    //   </Typography>
    // );

    const firstLine = value[`${path.id}Name` as keyof IBooking];
    const secondLine = value[`${path.id}Street` as keyof IBooking];
    const thirdLine = value[`${path.id}Location` as keyof IBooking];

    return firstLine ? (
      <>
        <Typography variant="body2">{firstLine}</Typography>
        <Box fontSize={12}>{secondLine}</Box>
        <Box fontSize={12}>{thirdLine}</Box>
      </>
    ) : (
      <Typography color="textSecondary" variant="caption">
        {t("addressLoading")}
      </Typography>
    );
  };

  const booleanComponent = (value: IBooking) => {
    const v = value[path.id as keyof IBooking];
    if (v) {
      return <Icon>check</Icon>;
    } else {
      return " ";
    }
  };

  const timeComponent = (value: IBooking) => {
    return value[path.id as keyof IBooking];
  };

  const dateTimeComponent = (value: IBooking) => {
    moment.locale(lang);
    const date = isDateNotEmpty(value[path.id as keyof IBooking])
      ? moment(value[path.id as keyof IBooking])
          .utc(true)
          .format("dddd Do MMMM")
      : "";
    return date;
  };

  const goodsComponent = (value: IBooking) => {
    return (
      <div>
        {value.goods.map(([key, value]) => (
          <div>{`${key.replaceAll("&dot;", ".")}: ${value}`}</div>
        ))}
      </div>
    );
  };
  const goodsDescriptionComponent = (value: IBooking) => {
    return (
      <div>
        {value.goodsDescription?.map((d) => (
          <Box height="20px">{d}</Box>
        ))}
      </div>
    );
  };

  const tourTimeComponent = (value: IBooking) => {
    moment.locale(lang);
    if (value.isLocal) {
      //only for local bookings
      const date = isDateNotEmpty(value[path.id as keyof IBooking])
        ? moment(value[path.id as keyof IBooking])
            .utc()
            .format("dddd Do MMMM HH:mm")
        : "";
      return date;
    } else {
      return "";
    }
  };

  const bookingIdComponent = (value: IBooking) => {
    return value.type != "Buffer" ? (
      <Link
        onClick={async () => {
          history.push(`/bookings/${value.trackingNumber}`);
        }}
        style={{ cursor: "pointer" }}
      >
        {value.bookingId}
      </Link>
    ) : (
      <Typography>{value.bookingId}</Typography>
    );
  };

  const components = [
    {
      pickBy: () => path.id == "bookingId",
      component: bookingIdComponent,
    },
    {
      pickBy: () => path.isAddress,
      component: addressComponent,
    },
    {
      pickBy: () => path.type === "Boolean",
      component: booleanComponent,
    },
    {
      pickBy: () => path.type === "Time",
      component: timeComponent,
    },
    {
      pickBy: () => path.id === "tourTime",
      component: tourTimeComponent,
    },
    {
      pickBy: () => path.isDateTime,
      component: dateTimeComponent,
    },
    {
      pickBy: () => path.id === "goods",
      component: goodsComponent,
    },
    {
      pickBy: () => path.id === "goodsDescription",
      component: goodsDescriptionComponent,
    },
    {
      pickBy: () => path.translatable,
      component: (value: IBooking) => t(value[path.id as keyof IBooking]),
    },
    {
      pickBy: () => path.isPackage,
      component: (value: IBooking) => {
        return value.packages?.find(
          ([key, _]) => key === path.id.split(".")[1]
        )?.[1];
      },
    },
  ];

  const component = components.find(({ pickBy }) => pickBy())?.component;
  return component;
};
