import React from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router-dom";
import logo from "../assets/img/logo.png";

import { useAuth } from "context/auth";
import { useLang } from "context/lang";
import { Loader } from "components/Loader";
import { Typography } from "@material-ui/core";

export default () => {
  const { login, error, loading, isAuthenticated } = useAuth();
  const { t } = useLang();

  const [values, setValues] = React.useState({
    username: "",
    password: "",
  });

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [values]);

  const handleChange =
    (prop: "username" | "password") =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await login(values.username, values.password);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return isAuthenticated() ? (
    <Redirect to="/" />
  ) : (
    <Box
      display="flex"
      width="100%"
      mt="10vh"
      height="200px"
      justifyContent="space-around"
      alignItems="center"
      flexDirection="column"
    >
      <Box m={5}>
        <img width="250" src={logo} />
      </Box>
      <Box mb={5}>
        <Typography variant="h4">{t("webbooking")}</Typography>
      </Box>
      <Box m={1}>
        <TextField
          label={t("username")}
          value={values.username}
          onChange={handleChange("username")}
          error={Boolean(error)}
          required
          variant="outlined"
        />
      </Box>
      <Box m={1} mb={3}>
        <TextField
          label={t("password")}
          value={values.password}
          onChange={handleChange("password")}
          error={Boolean(error)}
          helperText={error}
          required
          variant="outlined"
          type="password"
        />
      </Box>
      <Button
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        size="large"
      >
        <Loader loading={loading}>{t("login")}</Loader>
      </Button>
    </Box>
  );
};
