import React from "react";

interface IProps {
  /**
   * See the [DOM Level 3 Events spec](https://www.w3.org/TR/uievents-key/#named-key-attribute-values). for possible values
   */
  keyName: string;
  action: (e: KeyboardEvent) => void;
  /** Trigger only when ctrl/cmd is pressed */
  ctrl?: boolean;
  children: React.ReactElement;
}

export default ({
  keyName = "Enter",
  action,
  ctrl = false,
  children,
}: IProps) => {
  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key == keyName && (ctrl ? e.metaKey || e.ctrlKey : true)) {
      action(e);
    }
  };

  return <>{children}</>;
};
