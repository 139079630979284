import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";

import { Loader } from "./Loader";
import { IconButton } from "@material-ui/core";

export type ChooseMenuItem = {
  key: string;
  value: string;
  type?: string;
};

interface IProps {
  prefix: string;
  currentItem: ChooseMenuItem;
  items: ChooseMenuItem[];
  handleChoose: (item: ChooseMenuItem) => void;
  clearable?: boolean;
  isLoading: boolean;
  variant?: "contained" | "text" | "outlined" | undefined;
  color: "inherit" | "primary" | "secondary" | "default" | undefined;
  size: "medium" | "small" | "large" | undefined;
  boxProps?: any;
}

export const ChooseMenu = ({
  prefix,
  currentItem,
  items,
  handleChoose,
  clearable = false,
  isLoading,
  variant = "contained",
  color = "primary",
  size = "medium",
  boxProps = {},
}: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" {...boxProps}>
      <Button
        color={color}
        variant={variant}
        size={size}
        onClick={handleClick}
        endIcon={
          <Loader size={15} loading={isLoading}>
            <Icon>
              {anchorEl ? "keyboard_arrow_up" : "keyboard_arrow_down"}
            </Icon>
          </Loader>
        }
      >
        {`${prefix}`}
        &nbsp;
        {currentItem.value && (
          <>
            <b>{`${currentItem?.value || ""}`}</b>
            {clearable && (
              <Box p="0 !important" ml="8px !important" clone>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleChoose({ key: "", value: "" });
                    handleClose();
                  }}
                >
                  {" "}
                  <Icon fontSize="small" color="secondary">
                    clear
                  </Icon>
                </IconButton>
              </Box>
            )}
          </>
        )}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item, key) => (
          <MenuItem
            key={key}
            onClick={() => {
              handleChoose(item);
              handleClose();
            }}
          >
            {item.value}{" "}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
