import React, { useState } from "react";
import _ from "lodash";

import { useLocation } from "react-router-dom";

import { useAuth } from "./auth";
import routeParams from "../config/routes";
// import { useStore } from "./store";
import { BookingFieldId, IBooking } from "navision-proxy-api/@types";
import { Column } from "material-table";

interface IFieldsSettings {
  columnWidths: { [columnName: string]: number };
  setColumnWidths: (column: BookingFieldId, width: number) => void;
  hiddenFields: BookingFieldId[];
  getHiddenFields: () => BookingFieldId[];
  toogleHiddenField: (field: BookingFieldId) => void;
  /** merge with old hidden fields list */
  setHiddenFields: (hiddenFields: BookingFieldId[]) => void;
  /** overwrite hidden fields list in user.appConfig */
  setHiddenFieldsList: (hiddenFields: BookingFieldId[]) => void;
  setHiddenPackages: (packagesFields: `packages.${string}`[]) => void;
  changeColumnOrderOffset: (column: BookingFieldId, offset: number) => void;
  columnsOrderOffset: { [columnName: string]: number };
  handleSaveColumnsWidth: (columns: Column<IBooking>[]) => void;
}

const FieldsSettingsContext = React.createContext<IFieldsSettings>(
  {} as IFieldsSettings
);

function FieldsSettingsProvider(props: {
  children: React.ReactElement;
}): React.ReactElement {
  //const [hiddenFields, setHiddenFieldsList] = useState([]);
  const { user, setAppUserConfig, saveAppUserConfig } = useAuth();
  const { pathname } = useLocation();

  const getCurrentPage = () =>
    Object.entries(routeParams).find(([key, value]) => value.path === pathname);

  const getHiddenFields = () => {
    const page = getCurrentPage();
    return page && user?.appConfig && user?.appConfig.hiddenFields
      ? user?.appConfig.hiddenFields[page[0]] || []
      : [];
  };

  const getColumnWidths = () => {
    const page = getCurrentPage();
    return (
      page &&
      user?.appConfig &&
      user?.appConfig.columnWidths &&
      user?.appConfig.columnWidths[page[0]]
        ? user?.appConfig.columnWidths[page[0]] || {}
        : {}
    ) as {
      [column in BookingFieldId]: number;
    };
  };

  const setColumnWidths = (column: BookingFieldId, width: number) => {
    const page = getCurrentPage();
    if (page && user?.appConfig) {
      const newColumnWidths = {
        ...user?.appConfig.columnWidths,
        [page[0]]: {
          ...user?.appConfig.columnWidths?.[page[0]],
          [column]: width,
        },
      };
      saveAppUserConfig({
        ...user?.appConfig,
        columnWidths: newColumnWidths,
      });
    }
  };

  const changeColumnOrderOffset = (column: BookingFieldId, offset: number) => {
    const page = getCurrentPage();

    if (page && user?.appConfig) {
      let oldOffset =
        user?.appConfig.columnsOrderOffsets?.[page[0]]?.[column as any] || 0;
      let newColumnsOrderOffsets =
        user?.appConfig.columnsOrderOffsets?.[page[0]] || {};

      //order of keys is important since it works as a history of reordering
      if (newColumnsOrderOffsets[column as any]) {
        delete newColumnsOrderOffsets[column as any];
      }

      newColumnsOrderOffsets = {
        ...user?.appConfig.columnsOrderOffsets?.[page[0]],
        [column]: offset + oldOffset,
      };

      setAppUserConfig({
        ...user?.appConfig,
        columnsOrderOffsets: {
          ...user?.appConfig.columnsOrderOffsets,
          [page[0]]: newColumnsOrderOffsets,
        },
      });
    }
  };

  const getColumnsOrderOffset = () => {
    const page = getCurrentPage();
    return (
      page &&
      user?.appConfig &&
      user?.appConfig.columnsOrderOffsets &&
      user?.appConfig.columnsOrderOffsets[page[0]]
        ? user?.appConfig.columnsOrderOffsets[page[0]] || {}
        : {}
    ) as {
      [column in BookingFieldId]: number;
    };
  };

  const handleSaveColumnsWidth = (columns: Column<IBooking>[]) => {
    columns?.forEach((column: any) => {
      if (column.tableData.additionalWidth) {
        const additionalWidth = column.tableData.additionalWidth;
        const initialWidth = parseInt(column.tableData.initialWidth);
        const fieldId = column.field as BookingFieldId;
        const columnWidth = getColumnWidths();
        if (columnWidth[fieldId] !== additionalWidth + initialWidth) {
          setColumnWidths(fieldId, additionalWidth + initialWidth);
        }
      }
    });
  };

  const setHiddenFieldsList = (value: BookingFieldId[]) => {
    const page = getCurrentPage();
    if (page && user?.appConfig) {
      const newAppConfig = {
        ...user?.appConfig,
        hiddenFields: {
          ...user?.appConfig.hiddenFields,
          [page[0]]: value.filter((v, i, a) => a.indexOf(v) === i), //only unique values
        },
      };

      saveAppUserConfig(newAppConfig);
      setAppUserConfig(newAppConfig);
    }
  };

  const toogleHiddenField = (item: BookingFieldId) => {
    const hiddenFields = getHiddenFields();
    return hiddenFields.includes(item)
      ? setHiddenFieldsList(hiddenFields.filter((el) => el !== item))
      : setHiddenFieldsList([...hiddenFields, item]);
  };

  const setHiddenFields = (newList: BookingFieldId[]) => {
    const hiddenFields = getHiddenFields();

    setHiddenFieldsList([
      ...newList.filter((id) => !id.includes("packages.")),
      ...hiddenFields.filter((id) => id.includes("packages.")),
    ]);
  };

  const setHiddenPackages = (emptyPackages: `packages.${string}`[]) => {
    const newList = getHiddenFields().filter((id) => !id.includes("packages."));
    setHiddenFieldsList([...newList, ...emptyPackages] as BookingFieldId[]);
  };

  // React.useEffect(() => {
  //   //unhid errored fields
  //   if (!_.isEmpty(errors)) {
  //     const hiddenErroredFields = Object.keys(errors).filter((f) =>
  //       getHiddenFields().includes(f)
  //     );
  //     setHiddenFieldsList(
  //       getHiddenFields().filter(
  //         (field) => !hiddenErroredFields.includes(field)
  //       )
  //     );
  //   }
  // }, [errors]);

  return (
    <FieldsSettingsContext.Provider
      value={{
        columnWidths: getColumnWidths(),
        columnsOrderOffset: getColumnsOrderOffset(),
        setColumnWidths,
        hiddenFields: getHiddenFields(),
        getHiddenFields,
        toogleHiddenField,
        setHiddenFields,
        setHiddenFieldsList,
        setHiddenPackages,
        changeColumnOrderOffset,
        handleSaveColumnsWidth,
      }}
      {...props}
    />
  );
}

const useFieldsSettings = () => React.useContext(FieldsSettingsContext);

export { useFieldsSettings, FieldsSettingsProvider };
