import React from "react";
import "react-virtualized/styles.css";
import InfiniteScroll from "../InfiniteScroll";

export default (props: any) => {
  const initCount = Math.floor((window.innerHeight - 200) / 60) + 10;

  return (
    <tbody data-test="bookings-table-body" className="MuiTableBody-root">
      <InfiniteScroll
        initCount={initCount}
        scrollElement={props.tableRef.current?.tableContainerDiv.current} //TODO: find more buletproof way to get to the material table's scrolling container
      >
        {(renderedItemsCount) =>
          props.renderData
            .slice(0, renderedItemsCount)
            .map((data: any, index: any) => (
              <props.components.Row
                key={index}
                index={index}
                data={data}
                {...props}
              />
            ))
        }
      </InfiniteScroll>
    </tbody>
  );
};
