import React from "react";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Icon,
  TextField,
  Button,
} from "@material-ui/core";

import { ObjectId } from "mongoose";
import moment from "moment";

import { useQuickForm } from "hooks/quickForm";
import {
  LabelOrder,
  LabelsStatusResponse,
} from "navision-proxy-api/@types/api.js";
import {
  IQuickForm,
  IBooking,
  ICustomersCustomer,
  FieldParameters,
} from "navision-proxy-api/@types";
import { Loader } from "components/Loader";
import { ChooseMenu } from "components/ChooseMenu";
import { useLang } from "context/lang";
import { useBookings } from "hooks/bookings";
import { useStore } from "context/store";
import BookingFormField from "components/BookingForm/BookingFormField";
import { useTheme } from "@material-ui/core/styles";

type LabeledBooking = IBooking & { labels: LabelsStatusResponse };

type LabelsForm = {
  [quickFormBookingId: string]: LabeledBooking;
} & {};

export default ({}) => {
  const {
    quickForms,
    reloadQuickForms,
    loading: {
      load: loadingQuickForm,
      labels: loadingLabels,
      labelsCreate: loadingLabelsCreate,
    },
    loadLabels,
    orderLabels,
    RFIDLabels,
  } = useQuickForm({ isLocal: true });

  const {
    bookings: quickFormBookings,
    loading: loadingBookings,
    loadBookings,
  } = useBookings();

  const {
    bookingFormData,
    loading: { load: loadingStore },
    getCustomerCustomersLookup,
  } = useStore();

  const { t } = useLang();

  const [currentQuickForm, setCurrentQuickForm] = React.useState<
    IQuickForm | undefined
  >(undefined);

  const [tourTime, setTourTime] = React.useState<string>("");

  const tourTimeField: FieldParameters | undefined = React.useMemo(
    () => bookingFormData.fields.find(({ id }) => id == "tourTime"),
    [bookingFormData]
  );

  const addressesLookup = React.useMemo(
    () => getCustomerCustomersLookup(),
    [bookingFormData]
  );

  const [labelsForm, setLabelsForm] = React.useState<LabelsForm>({});

  React.useEffect(() => {
    loadLabels();
  }, []);

  React.useEffect(() => {
    if (tourTimeField) {
      initTourTime();
    }
  }, [tourTimeField]);

  const initTourTime = () => {
    setTourTime(tourTimeField?.lookup?.[0]?.value || "");
  };

  React.useEffect(() => {
    if (
      !currentQuickForm || //no current quick form set
      (currentQuickForm &&
        // or current quick form is from old quick form lists
        (!quickForms.map(({ _id }) => _id).includes(currentQuickForm._id) ||
          !quickForms.map(({ name }) => name).includes(currentQuickForm.name)))
    ) {
      setCurrentQuickForm(quickForms[0]);
    }
  }, [quickForms]);

  React.useEffect(() => {
    handleLoadBookings();
  }, [currentQuickForm]);

  const handleLoadBookings = () => {
    if (currentQuickForm) {
      loadBookings({ filters: { quickForm: currentQuickForm._id } });
    }
  };

  React.useEffect(() => {
    initLabelsForm();
  }, [RFIDLabels, quickFormBookings, tourTime]);

  const initLabelsForm = () => {
    const newLabelsForm: LabelsForm = {};

    quickFormBookings?.items?.forEach((booking: IBooking) => {
      const labelsOrderFilter = (labelsOrder: LabelOrder) =>
        labelsOrder.trip_time == tourTime &&
        labelsOrder.pickup == booking.pickup &&
        labelsOrder.receiver == booking.receiver;

      newLabelsForm[String(booking._id)] = {
        ...booking,
        labels: {
          balance: RFIDLabels?.balance?.filter(labelsOrderFilter) || [],
          waiting: RFIDLabels?.waiting?.filter(labelsOrderFilter) || [],
          printed: RFIDLabels?.printed?.filter(labelsOrderFilter) || [],
        },
      };
    });

    setLabelsForm(newLabelsForm);
  };

  const handleOrderLabel = (amount: number, b: LabeledBooking) => {
    orderLabels(tourTime, b.pickup, b.receiver, amount);
  };

  return (
    <Loader
      color="primary"
      size={50}
      margin={10}
      loading={Boolean(
        loadingQuickForm || loadingLabels || loadingStore || loadingLabelsCreate
      )}
    >
      <Box
        width="100%"
        my={4}
        py={1}
        borderColor="divider"
        border={1}
        borderLeft={0}
        borderRight={0}
      >
        <ChooseMenu
          color="default"
          prefix={t("quickForm")}
          items={quickForms.map(({ _id, name, type }) => ({
            key: String(_id),
            value: name,
          }))}
          currentItem={
            currentQuickForm
              ? {
                  key: String(currentQuickForm._id),
                  value: currentQuickForm.name,
                }
              : { key: "", value: "" }
          }
          handleChoose={(item) =>
            setCurrentQuickForm({
              _id: item.key as unknown as ObjectId,
              name: item.value,
            } as IQuickForm)
          }
          isLoading={Boolean(loadingQuickForm)}
          size="small"
        />
      </Box>
      <Box width="100%" mt={2}>
        {/* special field for tourtime only*/}
        {tourTimeField && (
          <BookingFormField
            field={tourTimeField}
            onChange={(_, tourTimeNewValue) => {
              setTourTime(tourTimeNewValue);
            }}
            booking={{}}
            value={tourTime}
          />
        )}
      </Box>
      <Grid container>
        {Object.values(labelsForm).map((b) => (
          <Grid item xs={12} sm={6}>
            <LabeledBooking
              booking={b}
              orderLabels={(amount: number) => handleOrderLabel(amount, b)}
              addressesLookup={addressesLookup}
            />
          </Grid>
        ))}
      </Grid>
    </Loader>
  );
};

interface IProps {
  booking: LabeledBooking;
  orderLabels: (amount: number) => void;
  addressesLookup: ICustomersCustomer[];
}

const LabeledBooking = ({ booking, orderLabels, addressesLookup }: IProps) => {
  const theme = useTheme();
  const [amount, setAmount] = React.useState<number>(0);
  const { t } = useLang();

  const receiverValue = addressesLookup.find(
    ({ receiverCode }) => receiverCode === booking["receiver"]
  )?.receiverName;

  const pickupValue = addressesLookup.find(
    ({ pickupCode }) => pickupCode === booking["pickup"]
  )?.pickupName;

  const reduceCount = (acc: number, item: LabelOrder) =>
    acc + parseInt(String(item.count));

  return (
    <Box m={4} border="1px solid" borderColor={theme.palette.divider} p={2}>
      <Box>
        <Box>
          <Box display="flex">
            <Box mr={1}>
              <Icon color="disabled">launch</Icon>
            </Box>
            <div>
              <Typography>{pickupValue}</Typography>
            </div>
          </Box>
          <Box display="flex">
            <Box mr={1}>
              <Icon color="disabled" style={{ transform: "scale(-1,1)" }}>
                input
              </Icon>
            </Box>
            <div>
              <Typography>{receiverValue}</Typography>
            </div>
          </Box>
        </Box>
      </Box>
      <Box my={1}>
        <Typography>
          <b>{booking.labels.balance.reduce(reduceCount, 0)}</b>{" "}
          {t("onBalance")},{" "}
          <b>{booking.labels.printed.reduce(reduceCount, 0)}</b> {t("printed")},{" "}
          <b>{booking.labels.waiting.reduce(reduceCount, 0)}</b>{" "}
          {t("waitingPrinting")}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Box width="30%">
          <TextField
            label={`${t("order")}: `}
            id={`labelOrder_${booking._id}`}
            type="number"
            value={amount}
            onChange={(e) => setAmount(parseInt(e.target.value))}
            inputProps={{ onFocus: ({ target }) => target.select() }}
          />
        </Box>
        <Box>
          {" "}
          <Button
            onClick={() => orderLabels(amount)}
            color="primary"
            variant="contained"
            disabled={amount == 0}
          >
            {t("confirm")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
