import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@material-ui/pickers";

import { MuiPickersAdapter } from "@material-ui/pickers/_shared/hooks/useUtils";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import moment from "moment";
import "moment/locale/da";
import "moment/locale/de";
import "moment/locale/se";
import "moment/locale/nl";

import { useLang } from "context/lang";
import InfiniteScroll from "components/InfiniteScroll";

import {
  FieldParameters,
  IBooking,
  ICustomersCustomer,
  BookingFieldId,
} from "navision-proxy-api/@types";

const DATE_FORMAT = "dd/MM/yyyy";
const TIME_FORMAT = "HH:mm";

interface IProps {
  field: FieldParameters;
  /** Element can be accessed in DOM via `${field.id}_${index} */
  onChange: (field: BookingFieldId, value: any) => void;
  index?: number;
  booking: Partial<IBooking>;
  value: any;
  defaultValue?: any;
  // defaultValueOnFocus?: any;
  customersCustomerLookup?: ICustomersCustomer[];
  required?: boolean;
  disabled?: boolean;
  boxProps?: object;
  variant?: "filled" | "outlined" | "standard" | undefined;
  fullWidth?: boolean;
  onClear?: () => void;
  hideLabel?: boolean;
  hidePlaceholder?: boolean;
  error?: string;
}

export default ({
  field,
  index = 0,
  booking,
  value,
  defaultValue,
  customersCustomerLookup = [],
  onChange,
  required = false,
  disabled = false,
  boxProps = {},
  variant = "outlined",
  fullWidth = false,
  onClear,
  hideLabel = false,
  hidePlaceholder = false,
  error,
}: // defaultValueOnFocus,
IProps) => {
  const { t, lang, dateFnsLocale } = useLang();

  const fieldProps = {
    id: `${field.id}_${index}`,
    label: !hideLabel && t(field.id),
    placeholder: !hidePlaceholder ? t(field.id) : "",
    value: value || defaultValue || "",
    //defaultValue,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(field.id, e.target.value);
    },
    select:
      Boolean(field.lookup) ||
      Boolean(field.isAddress && customersCustomerLookup?.length),
    SelectProps: {
      disabled,
    },
    error: Boolean(error),
    helperText: error,
    variant,
    disabled,
    required,
    // defaultValueOnFocus,
    // required: requiredFields.includes(field.id)
  };

  const globalAddressOptions = field.isAddress
    ? customersCustomerLookup //TODO use AddressLookup component (fix forwarding props)
        ?.reverse()
        .filter(
          //only unique
          (ccItem, i, a) =>
            a.findIndex((item) =>
              field.id === "receiver" // if address field is receiver we have to also count with ccNumber
                ? item[`${field.id}Code`] + item["ccNumber"] ===
                  ccItem[`${field.id}Code`] + ccItem["ccNumber"]
                : item[`${field.id}Code` as keyof ICustomersCustomer] ===
                  ccItem[`${field.id}Code` as keyof ICustomersCustomer]
            ) === i
        )
    : [];

  const renderLookupMenu = (count = -1) =>
    field.isDateTime
      ? field.lookup?.slice(0, count).map(({ key, value }, i) => {
          moment.locale(lang);
          return (
            <MenuItem key={`${key}_${value}`} value={key}>
              {moment.utc(value).format("DD-MM-YYYY HH:mm")}
            </MenuItem>
          );
        })
      : field.lookup
      ? field.lookup.slice(0, count).map(({ key, value }, i) => (
          <MenuItem key={`${key}_${value}`} value={key}>
            {t(value || "")}
          </MenuItem>
        ))
      : null;

  const [scrollableMenu, setScrollableMenu] = React.useState<HTMLElement>();

  /** Handler to select part of the datetime when clicking */
  const onSelectDateTime = (
    event: any,
    type: "date" | "time",
    inputProps: any
  ) => {
    let selectionStart = event.target.selectionStart;
    let selectionEnd = event.target.selectionEnd;

    //select parts of the date
    const divider = type == "date" ? "/" : ":";

    const select = () => {
      const value: string = event.target.value;
      const startOfString = value.slice(0, selectionStart);

      const endOfString = value.slice(selectionStart);

      const newStartIndex = startOfString.lastIndexOf(divider) + 1;

      const newEndIndex =
        (endOfString.indexOf(divider) >= 0
          ? endOfString.indexOf(divider)
          : endOfString.length) + startOfString.length;
      event.target.setSelectionRange(newStartIndex, newEndIndex);
    };

    //prefill on init
    if (
      selectionStart == selectionEnd &&
      event.nativeEvent instanceof MouseEvent
    ) {
      if (!event.target.value) {
        if (type == "date") {
          event.target.value = moment(new Date())
            .add(1, "days")
            .format("DD/MM/YYYY");
        } else if (type == "time") {
          event.target.value = moment(new Date())
            .add(1, "hours")
            .set("minutes", 0)
            .format(TIME_FORMAT);
        }

        //
        //inputProps?.onChange(event);

        // event.target.value = "12:12";
        // event.target.dispatchEvent(new Event("change", { bubbles: true }));
      }

      select();
    }
  };
  //render

  switch (field.type) {
    case "String":
      const addressOptions: any = globalAddressOptions.filter((o) => {
        //filter options that includes current field
        const fieldAddressValues = Object.entries(o).filter(([key, value]) =>
          key.toLowerCase().includes(field.id.toLowerCase())
        );

        if (fieldAddressValues.length === 0) {
          return false;
        } else {
          return !fieldAddressValues.some(([key, v]) => v === "undefined"); //filter empty values
        }
      });

      const addressValue = // find last
        addressOptions.find((address: any) => {
          if (field.id === "receiver") {
            return (
              address[`${field.id}Code`] === fieldProps.value &&
              address["ccNumber"] === booking.customerNb
            );
          } else {
            return (
              address[`${field.id}Code` as keyof ICustomersCustomer] ===
              fieldProps.value
            );
          }
        }) || null;

      return (
        <Box boxShadow={0} {...boxProps} clone>
          {field.isAddress ? (
            <Autocomplete
              id={fieldProps.id}
              options={addressOptions}
              value={addressValue}
              onChange={(e, value) => {
                if (!value && onClear) {
                  onClear();
                } else {
                  onChange(field.id, value ? value[`${field.id}Code`] : "");
                  if (field.id == "receiver") {
                    onChange("customerNb", value[`ccNumber`]);
                  }
                }
              }}
              forcePopupIcon={!Boolean(fieldProps.value)}
              autoHighlight
              disabled={fieldProps.disabled}
              renderOption={(option) =>
                option[`${field.id}Name`] ? (
                  <Typography variant="body2">
                    {`${option[`${field.id}Name`]} `} &nbsp;
                    <Typography variant="caption">
                      ({`${option[`${field.id}Address`]}`})
                    </Typography>
                    {field.id == "receiver" &&
                      option[`ccNumber`] && ( //searching receiver by customers customer number
                        <Typography variant="caption">
                          ({`${option[`ccNumber`]}`})
                        </Typography>
                      )}
                  </Typography>
                ) : null
              }
              getOptionLabel={(option) =>
                `${option[`${field.id}Name`]}${
                  field.id == "receiver" && option[`ccNumber`]
                    ? ` (${option[`ccNumber`]})`
                    : ""
                }` || ""
              }
              autoSelect
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autocomplete: "off",
                      //type: "hidden",
                      // onFocus: (event) => {
                      //   event.target.setAttribute("autocomplete", "asdgasg");
                      // },
                      onKeyDown: (event) => {
                        if (event.key === "Enter") {
                          event.stopPropagation(); //have to block material table save on enter action
                          if (event.target instanceof HTMLElement) {
                            event.target.blur(); //blur + autoSelect props recreates the onChange action
                          }
                        }
                      },
                      onClick: onClear && value ? onClear : undefined,
                    }}
                    variant={fieldProps.variant}
                    label={fieldProps.label}
                    placeholder={fieldProps.placeholder}
                    error={fieldProps.error}
                    helperText={fieldProps.helperText}
                  />
                );
              }}
              defaultValue={defaultValue}
            />
          ) : (
            <InfiniteScroll scrollElement={scrollableMenu} initCount={20}>
              {(renderedItemsCount) => (
                <TextField
                  //noWrap
                  fullWidth={fullWidth}
                  type="text"
                  {...fieldProps}
                  //value={moment(fieldProps.value).utc(true).toISOString()}
                  inputProps={{ maxLength: field.maxLength }}
                  SelectProps={{
                    IconComponent:
                      onClear && fieldProps.value && !disabled
                        ? (props) => (
                            <>
                              <Icon {...props}>arrow_drop_down</Icon>
                              <Box position="absolute" right="35px">
                                <IconButton size="small" onClick={onClear}>
                                  <Icon>clear</Icon>
                                </IconButton>
                              </Box>
                            </>
                          )
                        : (props) => <Icon {...props}>arrow_drop_down</Icon>,
                    MenuProps: {
                      id: "times-options-infinite-scroll-target",
                      onEnter: (
                        e //for infinitive scrolling
                      ) =>
                        setScrollableMenu(
                          (document
                            .getElementById(
                              "times-options-infinite-scroll-target"
                            )
                            ?.getElementsByClassName(
                              "MuiPaper-root"
                            )[0] as HTMLElement) || undefined
                        ),
                      //...fieldProps.SelectProps.MenuProps,
                    },
                    ...fieldProps.SelectProps,
                  }}
                >
                  {renderLookupMenu(renderedItemsCount)}
                </TextField>
              )}
            </InfiniteScroll>
          )}
        </Box>
      );
    case "Number":
      return (
        <Box boxShadow={0} m={"1px !important"} {...boxProps} clone>
          <TextField
            fullWidth={fullWidth}
            type="number"
            {...fieldProps}
            inputProps={{
              min: 0,
              onFocus: ({ target }) => {
                target.select();
              },
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (/^-?\d+$/.test(e.target.value)) {
                fieldProps.onChange(e);
              }
            }}
          />
        </Box>
      );
    case "Date":
      return (
        <LocalizationProvider
          dateAdapter={
            DateFnsAdapter as new (...args: any) => MuiPickersAdapter<unknown>
          }
          locale={dateFnsLocale}
        >
          {" "}
          <DatePicker
            //inputVariant="outlined"
            {...fieldProps}
            //variant="inline"
            value={fieldProps.value || null}
            onChange={(date) => onChange(field.id, date)}
            //autoOk
            disablePast
            renderInput={(props) => {
              return (
                <Box boxShadow={0} {...boxProps} m={2} clone>
                  <TextField
                    {...props}
                    fullWidth={fullWidth}
                    error={Boolean(error)}
                    helperText={Boolean(error) ? error : DATE_FORMAT}
                    required={Boolean(fieldProps.required)}
                    onSelect={(event) =>
                      onSelectDateTime(event, "date", props.inputProps)
                    }
                    inputProps={{
                      ...props.inputProps,
                      placeholder: DATE_FORMAT,
                      onBlur: (event) => {
                        if (props.inputProps?.onChange)
                          //we need onChange onBlur event for default value on select to work
                          props.inputProps.onChange(event);
                        if (props.inputProps?.onBlur)
                          props.inputProps.onBlur(event);
                      },
                      min: "26/04/2021",
                    }}
                  />
                </Box>
              );
            }}
            inputFormat={DATE_FORMAT}
          />
        </LocalizationProvider>
      );
    case "Time":
      return (
        <LocalizationProvider
          dateAdapter={
            DateFnsAdapter as new (...args: any) => MuiPickersAdapter<unknown>
          }
          locale={dateFnsLocale}
        >
          {" "}
          <TimePicker
            ampm={false}
            {...fieldProps}
            //variant="inline"
            //inputVariant="outlined"
            value={
              fieldProps.value ? moment(fieldProps.value, TIME_FORMAT) : null
            }
            //required={fieldProps.required}
            onChange={(date) => {
              onChange(field.id, moment(date).format(TIME_FORMAT));
            }}
            //autoOk
            renderInput={(props: any) => {
              return (
                <Box boxShadow={0} m={1} {...boxProps} clone>
                  <TextField
                    {...props}
                    fullWidth={fullWidth}
                    error={Boolean(error)}
                    helperText={Boolean(error) ? error : TIME_FORMAT}
                    onSelect={(event) => {
                      onSelectDateTime(event, "time", props.inputProps);
                    }}
                    inputProps={{
                      ...props.inputProps,
                      //we need onBlur for default value on select to work
                      onBlur: (event) => {
                        if (props.inputProps?.onChange)
                          //we need onChange onBlur event for default value on select to work
                          props.inputProps.onChange(event);
                        if (props.inputProps?.onBlur)
                          props.inputProps.onBlur(event);
                      },
                      placeholder: TIME_FORMAT,
                    }}
                  />
                </Box>
              );
            }}
          />
        </LocalizationProvider>
      );
    default:
      return null;
  }
};
