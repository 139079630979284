import _ from "lodash";
import moment from "moment";

/** if value is not correct we return string with error message. If it is ok we return undefined */
export type Validator = (value: any) => string | undefined;

export const required: Validator = (value) =>
  value === "" || value === undefined ? "errorRequired" : undefined;

export const dateNotPast: Validator = (date) =>
  date && moment(date).isBefore(moment(), "day") ? "errorDatePast" : undefined;

export const objectRequired: Validator = (object) =>
  _.isEmpty(object) ? "errorRequired" : undefined;

export const objectValuesRequired: Validator = (object) =>
  Object.values(object).some((v) => !v)
    ? "errorObjectValuesRequired"
    : undefined;
