import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import pdfHeader from "../../assets/img/logo.png";
import QRCode from "qrcode";

import Icon from "@material-ui/core/Icon";

import { BlobProvider, PDFViewer } from "@react-pdf/renderer";
import { Loader } from "../Loader";
import { useLang } from "../../context/lang";
import { IBooking } from "navision-proxy-api/@types";
import { getDeliveryDate } from "containers/BookingDetails";
import { Button } from "@material-ui/core";

const styles = StyleSheet.create({
  page: {
    // display: "flex",
    // flexDirection: "column",
    // backgroundColor: "white",
    // height: "300px",
    // flexWrap: "wrap"
    fontSize: 8,
    fontWeight: 300,
    padding: 13,
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  h1: {
    fontSize: 11,
    fontWeight: 700,
    marginBottom: 1,
  },
  h2: {
    fontSize: 9,
    fontWeight: 700,
  },
  h3: {
    fontSize: 8,
    fontWeight: 600,
    marginBottom: 5,
  },
  smallText: {
    fontSize: 4,
  },
  midText: {
    fontSize: 6,
  },
  boldText: {
    fontWeight: 700,
  },
  headerImage: {
    height: 30,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    marginBottom: 5,
  },
  body: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    width: 184,
    height: 62,
  },
  container: {
    marginTop: 5,
    marginBottom: 5,
  },
  containerText: {
    width: "75%",
  },
  containerQr: {
    width: "25%",
    display: "flex",
    justifyContent: "flex-end",
  },
  qr: {
    width: 45,
    height: 45,
  },
  marginTop: {
    marginTop: 5,
  },
  main: {
    width: 184,
    height: 121,
  },
});

// Create styles

export const DownloadLabelsPdf = ({ bookings }: { bookings: IBooking[] }) => {
  const [isReady, setIsReady] = React.useState(false);
  const isClicked = React.useRef(false);
  const qrRefs = React.useRef<any>({});

  const handleDownload = async (e: any) => {
    await Promise.all(
      bookings.map(async (booking): Promise<void> => {
        const qr = await QRCode.toDataURL(
          `${process.env.REACT_APP_BASE_URL}track/${booking.trackingNumber}`,
          { errorCorrectionLevel: "L" }
        );

        qrRefs.current[booking.bookingId] = qr;
      })
    );
    setIsReady(true);
  };

  const { t } = useLang();

  return isReady ? (
    <BlobProvider
      document={
        <LabelsPdfTemplate
          t={t}
          bookings={bookings}
          bookingsQr={qrRefs.current}
        />
      }
      //   style={{ color: "inherit", textDecoration: "none", height: "24px" }}
    >
      {({ blob, url, loading, error }) => {
        const tempLink = document.createElement("a");
        tempLink.href = url || "";
        tempLink.setAttribute(
          "download",
          `labels_${new Date().toISOString()}.pdf`
        );

        const download = () => {
          if (url && !isClicked.current) {
            tempLink.click();
            isClicked.current = true;
          }
        };
        download();
        return (
          <Loader margin={5} loading={loading}>
            <Button
              onClick={(e) => {
                tempLink.click();
              }}
              color="primary"
              variant="outlined"
            >
              {t("downloadLabels")}
            </Button>
          </Loader>
        );
      }}
    </BlobProvider>
  ) : (
    <Button color="primary" variant="outlined" onClick={handleDownload}>
      {t("downloadLabels")}
    </Button>
  );
};

interface ILabelsPdfTemplateProps {
  t: (key: string) => string;
  bookings: IBooking[];
  bookingsQr: { [key: string]: string };
}

// Create Document Component
export const LabelsPdfTemplate = ({
  t,
  bookings,
  bookingsQr,
}: ILabelsPdfTemplateProps) => (
  <Document>
    {bookings
      .map((booking) =>
        booking.goods.map((bookingGood) =>
          [...Array(bookingGood[1] || 0).keys()].map((goodNumber) => {
            return (
              <Page size="A8" style={styles.page} orientation="landscape">
                <View style={styles.main}>
                  <View style={styles.header}>
                    <View style={styles.headerContainer}>
                      <Text style={styles.smallText}>{"Booking ID: "}</Text>
                      <Text style={styles.h2}>{booking.bookingId}</Text>
                      {booking.itemId && (
                        <Text
                          style={styles.smallText}
                        >{`Remark: ${booking.itemId}`}</Text>
                      )}
                    </View>
                    <Image src={pdfHeader} style={styles.headerImage} />
                  </View>
                  <View>
                    <Text style={styles.smallText}>{"Receiver: "}</Text>
                    <Text style={styles.h1}>{booking.receiverName}</Text>
                    <Text style={styles.smallText}>{"Delivery at: "}</Text>
                    {booking.deliveryName !== booking.receiverName && (
                      <Text style={styles.midText}>{booking.deliveryName}</Text>
                    )}
                    <Text style={styles.midText}>
                      {booking.deliveryStreet + ", " + booking.deliveryLocation}
                    </Text>
                    <Text style={styles.midText}>
                      {booking.deliveryAddress2}
                    </Text>
                    <View style={styles.body}>
                      <View style={styles.containerText}>
                        <View style={styles.container}>
                          <View>
                            <Text
                              style={styles.smallText}
                            >{`Latest delivery Date/Time:  `}</Text>
                            <Text style={styles.midText}>
                              {getDeliveryDate(booking)}
                            </Text>
                          </View>
                        </View>
                        <Text style={styles.smallText}>{"Sender: "}</Text>
                        <Text style={styles.midText}>
                          {`${booking.customerName}`}
                        </Text>
                        <View style={styles.marginTop}>
                          <View style={styles.flexBox}>
                            <Text style={styles.h2}>
                              {`${bookingGood[0]?.replaceAll("&dot;", ".")} `}
                            </Text>
                            <Text style={styles.h2}>{` ${goodNumber + 1} of ${
                              bookingGood[1]
                            }`}</Text>
                          </View>
                        </View>
                      </View>
                      <View style={styles.containerQr}>
                        <Image
                          style={styles.qr}
                          src={bookingsQr[booking.bookingId]}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </Page>
            );
          })
        )
      )
      .flat()
      .flat()}
  </Document>
);
