import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

export const SentryRoute = Sentry.withSentryRouting(Route);
const history = createBrowserHistory();

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://e1030e7d2b0dddc2b9a3fae90f710131@o4507814781255680.ingest.de.sentry.io/4507814787285072",
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      // OR
      Sentry.reactRouterV4BrowserTracingIntegration({ history }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        networkDetailAllowUrls: ["https://api.alex-andersen.app/booking"],
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
