import React from "react";

interface IProps {
  /** Number of items to show initialy*/
  initCount: number;
  /** Offset in pixels from the bottom of the elemnt to the point when it should be new items rendered */
  bottomOffset?: number;
  /** Offset in pixels from the top of the elemnt to the point when it should be returned to init state */
  topOffset?: number;
  /** Element to track scroll for. */
  scrollElement?: HTMLElement;
  /** Selector function to find element to track scroll for. */
  scrollElementSelector?: () => HTMLElement;
  children(
    renderedItemsCount: number
  ): React.ReactElement[] | React.ReactElement;
}

export default ({
  initCount = 10,
  bottomOffset = 10,
  topOffset = 0,
  scrollElement,
  scrollElementSelector = () => document.documentElement,
  children,
}: IProps) => {
  const [renderedItemsCount, setRenderedItemsCount] = React.useState(initCount);
  const trackScrolling = (event: Event) => {
    const wrappedElement = event.target;
    if (wrappedElement && wrappedElement instanceof HTMLElement) {
      const leftToBottom =
        wrappedElement.scrollHeight -
        wrappedElement.clientHeight -
        wrappedElement.scrollTop;
      const leftToTop = wrappedElement.scrollTop;
      if (leftToBottom < bottomOffset) {
        const activeElement = document.activeElement;
        if (activeElement instanceof HTMLElement) {
          activeElement?.blur();
        }
        setRenderedItemsCount((prev) => prev + 10);
      } else if (leftToTop <= topOffset) {
        setRenderedItemsCount(initCount);
      }
    }
  };

  React.useEffect(() => {
    const scrollTarget = scrollElement || scrollElementSelector();

    if (scrollTarget) {
      scrollTarget.addEventListener("scroll", trackScrolling);

      return () => {
        scrollTarget.removeEventListener("scroll", trackScrolling);
      };
    }
  });

  return <>{children(renderedItemsCount)}</>;
};
