import React from "react";
import AddressForm, { Form } from "components/BookingForm/AddressFormPopover";
import { useLang } from "context/lang";
import { Button, Icon, Popover, Typography, Box } from "@material-ui/core";
import { useStore } from "context/store";
import {
  BookingFieldId,
  ICustomersCustomer,
  IBooking,
} from "navision-proxy-api/@types";

const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

interface IProps {
  booking: Partial<IBooking>;
  addressField: BookingFieldId;
  setBooking: (booking: Partial<IBooking>) => void;
  pushCustomersCustomer: (cc: ICustomersCustomer) => void;
  setEnableCCLookup: (v: boolean) => void;
}

export default ({
  booking,
  addressField,
  setBooking,
  pushCustomersCustomer,
  setEnableCCLookup,
}: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSubmit = (address: Form) => {
    setEnableCCLookup(false);

    const newCustomerNb = address["receiverRef"]?.value || booking.customerNb; //when we adding new address if it is receiver address we want to use it's receiverRef as customerNb

    const convertAddress = (field: BookingFieldId, address: Form) =>
      Object.entries(address).reduce<Partial<ICustomersCustomer>>(
        (acc, [key, value]) => ({
          ...acc,
          [`${field}${capitalizeFirstLetter(key)}`]: value.code
            ? value.code
            : value.value,
        }),
        {
          [`${field}`]: "newAddress",
          [`${field}Code`]: "newAddress",
        }
      );

    let convertedAddress = convertAddress(addressField, address);

    if (addressField == "receiver") {
      convertedAddress = {
        ...convertedAddress,
        ...convertAddress("delivery", address),
      };
    } else if (addressField == "customer") {
      convertedAddress = {
        ...convertedAddress,
        ...convertAddress("pickup", address),
      };
    }

    setBooking({ ...convertedAddress, customerNb: newCustomerNb });
    //add new option
    pushCustomersCustomer({
      ...convertedAddress,
      ccNumber: newCustomerNb,
    } as ICustomersCustomer);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "address-popover" : undefined;

  const { t } = useLang();
  return (
    <Box my={1}>
      <Button
        startIcon={<Icon color="primary">add</Icon>}
        aria-describedby={id}
        variant="outlined"
        color="primary"
        onClick={handleClick}
      >
        <Typography variant="caption">{t("addNew")}</Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <AddressForm
          handleSubmit={handleSubmit}
          name={addressField}
          handleClose={handleClose}
          showNumber={addressField === "receiver"}
        />
      </Popover>
    </Box>
  );
};
