import React from "react";
import { Column } from "material-table";
import {
  IBooking,
  ICustomersCustomer,
  BookingFieldId,
  FieldParameters,
  Lookup,
  IAddress,
} from "navision-proxy-api/@types";
import { useFieldsSettings } from "context/fieldsSettings";
import renderCell from "components/BookingMaterialTable/renderCell";
import renderEditCell from "components/BookingMaterialTable/renderEditCell";
import { useStore } from "context/store";
import { useLang } from "context/lang";

import PackagesEditContainer from "components/PackagePicker/PackagesEditContainer";
import { useHistory } from "react-router-dom";

interface IColumnsOptions {
  ignoredColumns: BookingFieldId[];
  /** alwaysUnhiddenColumns is basically a fix for when sometimes "packages" inserts to hidden columns when it is ignored
   * TODO: unignore "packages" in quick form and make it hidable
   */
  alwaysUnhiddenColumns?: BookingFieldId[];
  notEditableColumns: BookingFieldId[];
  sortableColumns: BookingFieldId[];

  /** Show units and packages as interactive view from form rather than all in one row (horizontal view) */
  verticalPackagesView: boolean;
  customColumns?: Column<IBooking>[];
  /** If > 0 enables fresed columns which are sticky when horizontal scrolling */
  columnFreezeIndex?: number;
  /** Needed for trigering updating the columns */
  selectedBookings?: IBooking[];
}

type BookingColumns = (Column<IBooking> & {
  pathProps?: FieldParameters;
  alwaysUnhidden?: boolean;
})[];

const DEAFAULT_COLUMN_WIDTH = 115;

const applyColumnsFreezing = (
  columns: BookingColumns,
  columnFreezeIndex: number
): BookingColumns => {
  const columnsOffsets = columns
    .filter(({ hidden }) => !hidden)
    .reduce(
      (acc, column, index) => [
        ...acc,
        acc[index] + parseInt(String(column.width)) || DEAFAULT_COLUMN_WIDTH,
      ],
      [0]
    );

  const newColumns: BookingColumns = [];

  let visibleIndex = 0;
  let visibleFrozenIndex = 0;
  let allIndex = 0;
  for (let column of columns || []) {
    newColumns.push(
      allIndex <= columnFreezeIndex
        ? {
            ...column,
            cellStyle: {
              ...column.cellStyle,
              position: "sticky",
              left: columnsOffsets[visibleIndex],
              backgroundColor: "white",
              zIndex: 9,
              borderRight:
                allIndex === columnFreezeIndex ? "2px solid black" : undefined,
              overflow: "hidden",
            },
            headerStyle: {
              ...column.headerStyle,
              position: "sticky",
              backgroundColor: "white",
              left: columnsOffsets[visibleIndex],
              zIndex: 10,
              borderRight:
                allIndex === columnFreezeIndex ? "2px solid black" : undefined,
              overflow: "hidden",
            },
          }
        : column
    );
    if (!column.hidden) {
      visibleIndex++;
      if (allIndex <= columnFreezeIndex) {
        visibleFrozenIndex++;
      }
    }
    allIndex++;
  }

  const frozenTooWide =
    columnsOffsets[visibleFrozenIndex] + 500 > window.innerWidth;

  return frozenTooWide ? columns : newColumns;
};

export function useBookingsMTableColumns(options: IColumnsOptions) {
  const [columns, setColumns] = React.useState<BookingColumns>([]);
  const { t, dateFnsLocale, lang } = useLang();
  const { bookingFormData, getCustomerCustomersLookup } = useStore();
  const history = useHistory();
  const { hiddenFields, columnWidths, columnsOrderOffset } =
    useFieldsSettings();
  const isColumnHidden = (id: BookingFieldId) =>
    hiddenFields.includes(id) && !options.alwaysUnhiddenColumns?.includes(id)
      ? true
      : false;

  React.useEffect(() => {
    initColumns();
  }, [
    bookingFormData.fields,
    hiddenFields,
    lang,
    columnsOrderOffset,
    columnWidths,
    options.selectedBookings,
  ]);

  const initColumns = () => {
    let newColumns: BookingColumns = bookingFormData.fields
      .filter(
        ({ id }) =>
          options.ignoredColumns.every((c) => id !== c) &&
          (!id.includes("packages.") || !options.verticalPackagesView)
      )
      .map<Column<IBooking>>((path) => {
        const isPackage = path.isPackage;
        const isTime = path.type === "Time";
        const columnWidth = columnWidths[path.id]
          ? columnWidths[path.id]
          : DEAFAULT_COLUMN_WIDTH;

        let lookup:
          | {
              [key: string]: string;
            }
          | undefined;

        if (path.lookup) {
          if (path.isAddress) {
            //we use lookup in renderCell
            lookup = undefined;
          } else {
            lookup = (
              path.lookup as Array<{
                key?: string | undefined;
                value?: string | undefined;
              }>
            ).reduce<{
              [key: string]: string;
            }>(
              (acc, { key, value }) => ({
                ...acc,
                [key as string]: value || "",
              }),
              {}
            );
          }
        }

        return {
          pathProps: { ...path },
          field: path.id,
          title: `${isPackage ? t(path.id.split("packages.")[1]) : t(path.id)}`,
          hidden: isColumnHidden(path.id),
          alwaysUnhidden: options.alwaysUnhiddenColumns?.includes(path.id),
          lookup,
          width: columnWidth,
          // maxWidth: columnWidth,
          // minWidth: columnWidth,
          type: path.type === "Number" ? "numeric" : undefined,
          initialEditValue: isTime ? new Date() : "",
          defaultSort: undefined,
          customSort: path.isAddress //custom sorting only for addresses
            ? (a: IBooking, b: IBooking) => {
                const ccItemA: ICustomersCustomer | undefined =
                  getCustomerCustomersLookup().find(
                    (item) =>
                      item[`${path.id}Code` as keyof ICustomersCustomer] ==
                      a[path.id as keyof IBooking]
                  );
                const ccItemB: ICustomersCustomer | undefined =
                  getCustomerCustomersLookup().find(
                    (item) =>
                      item[`${path.id}Code` as keyof ICustomersCustomer] ==
                      b[path.id as keyof IBooking]
                  );
                return ccItemA &&
                  ccItemB &&
                  ccItemA[`${path.id}Name` as keyof ICustomersCustomer] >
                    ccItemB[`${path.id}Name` as keyof ICustomersCustomer]
                  ? 1
                  : -1;
              }
            : undefined,
          cellStyle: {
            //whiteSpace: "nowrap",
            overflow: "hidden",
            // textOverflow: "ellipsis",
            fontFamily:
              "'Asap','Roboto', 'Helvetica', 'Arial', sans-serif !important",
          },
          headerStyle: {
            zIndex: 9,
            overflow: "hidden",
          },
          editable: options.notEditableColumns.includes(path.id)
            ? "never"
            : "always",
          editComponent: renderEditCell({
            path,
            dateFnsLocale,
          }),
          render: renderCell({
            path,
            // addresses: options.addresses || {},
            // getCustomerCustomersLookup,
            t,
            lang,
            history,
          }),
          customFilterAndSearch: path.isAddress //searching works only for adresses fields
            ? (searchValue: string, rowData: IBooking) => {
                //const addressCode = rowData[path.id as keyof IBooking];

                const firstLine = rowData[`${path.id}Name` as keyof IBooking];
                const secondLine =
                  rowData[`${path.id}Street` as keyof IBooking];
                const thirdLine =
                  rowData[`${path.id}Location` as keyof IBooking];

                // console.log(getCustomerCustomersLookup());
                // const ccValue = getCustomerCustomersLookup().find(
                //   (item) =>
                //     item[`${path.id}Code` as keyof ICustomersCustomer] ==
                //     addressCode
                // );
                // console.log(ccValue);
                // if (ccValue) {
                //   const addressName = String(
                //     ccValue[`${path.id}Name` as keyof ICustomersCustomer]
                //   ).toLowerCase();
                //   const addressAddress = String(
                //     ccValue[`${path.id}Address` as keyof ICustomersCustomer]
                //   );
                //   console.log(addressName, addressAddress);
                //   if (
                //     `${addressName} ${addressAddress}`.includes(
                //       searchValue.toLowerCase()
                //     ) ||
                //     (addressName &&
                //       searchValue.toLowerCase().includes(addressName)) ||
                //     (addressAddress &&
                //       searchValue.toLowerCase().includes(addressAddress))
                //   ) {
                //     return true;
                //   }
                if (
                  `${firstLine} ${secondLine} ${thirdLine}`
                    .latinise()
                    .toLowerCase()
                    .includes(searchValue.latinise().toLowerCase())
                ) {
                  return true;
                }

                return false;
              }
            : undefined,
          sorting:
            options.sortableColumns.includes(path.id) &&
            !isColumnHidden(path.id),
          resizable: true,
        };
      });

    //update date time columnns
    newColumns = newColumns.map((column) => {
      if (
        column.pathProps &&
        column.pathProps.isDateTime &&
        column.pathProps.type == "Date"
      ) {
        return {
          ...column,
          editComponent: renderEditCell({
            path: column.pathProps,
            dateFnsLocale,
          }),
          render: renderCell({
            path: column.pathProps,
            // getCustomerCustomersLookup,
            // addresses: options.addresses || {},
            t,
            lang,
            history,
          }),
          headerStyle: { paddingRight: 0, overflow: "hidden" },
          initialEditValue: new Date(),
          cellStyle: {
            fontFamily:
              "'Asap','Roboto', 'Helvetica', 'Arial', sans-serif !important",
            overflow: "hidden",
          },
          defaultGroupSort: undefined,
          defaultSort: undefined,
          sorting: true,
          searchable: false,
        };
      } else {
        return column;
      }
    });

    //insert interactive goods/packages editor for a quickform
    if (options.verticalPackagesView) {
      const interactiveUnitColumn = (
        name: "goods" | "packages",
        packagePickerProps?: object
      ): Column<IBooking> => ({
        title: `${t(name)}`,
        field: name,
        hidden: isColumnHidden(name),
        cellStyle: {
          overflow: "hidden",
        },
        headerStyle: { overflow: "hidden" },
        width: columnWidths[name] ? columnWidths[name] : 140,
        resizable: true,
        editComponent: ({ onChange, value, ...props }) => {
          return (
            <PackagesEditContainer
              id={`${name}_${props.rowData._id}`}
              onChange={onChange}
              value={value}
              //required={requiredLabelColumns.includes(name)}
              minLength={1}
              packagesLookup={
                name == "goods"
                  ? bookingFormData.goodNames
                  : bookingFormData.packageNames
              }
              // errorText={
              //   errors[name] ? errors[name][props.rowData._id] : undefined
              // }
              enableDuplicateKeys
              {...packagePickerProps}
            />
          );
        },
        render: (value) => {
          return (
            <PackagesEditContainer
              editing={false}
              value={value[name]}
              packagesLookup={
                name == "goods"
                  ? bookingFormData.goodNames
                  : bookingFormData.packageNames
              }
              enableDuplicateKeys
            />
          );
        },
      });

      newColumns.splice(
        newColumns.findIndex(({ field }) => field === "goodsDescription"), //TODO should not use field name
        0,
        interactiveUnitColumn("goods", { translate: false })
      );

      newColumns.splice(
        newColumns.findIndex(({ field }) => field === "goodsDescription") + 1, //TODO should not use field name
        0,
        interactiveUnitColumn("packages")
      );
    }

    if (options.customColumns) {
      options.customColumns.forEach((customColumn) =>
        newColumns.unshift({
          ...customColumn,
          hidden: isColumnHidden(customColumn.field as BookingFieldId),
        })
      );
    }

    //For some unknown reason column resize doesn't work for the last column so we need this
    //dummy column to make column resize work
    newColumns.push({ hidden: true });

    //sorting regarding to columns order offsets
    Object.entries(columnsOrderOffset).forEach(([columnName, offset]) => {
      const columnIndex = newColumns.findIndex(
        (column) => column.field == columnName
      );
      newColumns.splice(
        columnIndex + offset,
        0,
        newColumns.splice(columnIndex, 1)[0]
      );
    });

    //applying freezed columns functionality
    if (options.columnFreezeIndex && options.columnFreezeIndex > 0) {
      newColumns = applyColumnsFreezing(newColumns, options.columnFreezeIndex);
    }

    setColumns(newColumns);
  };

  return { columns, initColumns, isColumnHidden };
}
