import React from "react";

export type Loading<LoaderNames extends string[]> = {
  [loaderName in LoaderNames[number]]?: boolean;
};

export function useLoading<
  LoaderNames extends string[] = ["load" | "create" | "update" | "delete"]
>(loaderNames: LoaderNames) {
  const loadingReducer = (
    state: Loading<LoaderNames>,
    action: { loaderName: LoaderNames[number]; value: boolean }
  ): Loading<LoaderNames> => {
    return { ...state, [action.loaderName]: action.value };
  };

  const initState = loaderNames.reduce(
    (acc, loaderName: LoaderNames[number]) => ({ ...acc, [loaderName]: false }),
    {}
  );

  const [loading, setLoading] = React.useReducer<typeof loadingReducer>(
    loadingReducer,
    initState
  );

  const loadingOn = (loaderName: LoaderNames[number]) =>
    setLoading({ loaderName, value: true });
  const loadingOff = (loaderName: LoaderNames[number]) =>
    setLoading({ loaderName, value: false });

  return { loading, loadingOn, loadingOff };
}
