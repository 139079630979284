import React from "react";
import moment from "moment";
import "moment/locale/da";
import "moment/locale/de";
import "moment/locale/se";
import "moment/locale/nl";

import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { MuiPickersAdapter } from "@material-ui/pickers/_shared/hooks/useUtils";

import { DateRangePicker, LocalizationProvider } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import { useStore } from "../../context/store";
import { useLang } from "../../context/lang";
import { BookingFieldId } from "navision-proxy-api/@types";
import { BookingsRequestQuery } from "navision-proxy-api/@types/api";
import { ExecOptionsWithStringEncoding } from "child_process";

interface IProps {
  field: BookingFieldId;
  query: BookingsRequestQuery;
  loadBookings: React.Dispatch<React.SetStateAction<BookingsRequestQuery>>;
}

type DateFilter = {
  ge?: string | undefined;
  le?: string | undefined;
};

export const DateRangeFilter = ({ field, query, loadBookings }: IProps) => {
  const [dateFilter, setDateFilter] = React.useState<
    [string | undefined, string | undefined]
  >([undefined, undefined]);

  /** This state used only for one time current search
   * It is a tweak so user can change both dates before trigering the update of the query
   */
  const [currentDateSearch, setCurrentDateSearch] = React.useState<
    [string | undefined, string | undefined]
  >([undefined, undefined]);

  const { t, lang } = useLang();
  const dateRangeRef = React.useRef();

  /** Effect for triggering loading the bookings when user clicks the filters
   *
   */
  React.useEffect(() => {
    if (
      dateFilter[0] &&
      dateFilter[1] &&
      //check if query was not exicuted already
      (
        query?.filters[
          field as keyof BookingsRequestQuery["filters"]
        ] as DateFilter
      )?.ge !== dateFilter[0] &&
      (
        query?.filters[
          field as keyof BookingsRequestQuery["filters"]
        ] as DateFilter
      )?.le != dateFilter[1]
    ) {
      loadBookings((prev) => ({
        ...prev,
        filters: {
          ...prev.filters,
          [field]: {
            ge: moment(dateFilter[0])?.utc(true).toISOString(),
            le: moment(dateFilter[1])?.utc(true).toISOString(),
          },
        },
      }));
    }
  }, [dateFilter]);

  /** Effect for setting corresponding filters when bookings are loaded */
  React.useEffect(() => {
    if (
      query?.filters[field as keyof BookingsRequestQuery["filters"]] &&
      ((
        query?.filters[
          field as keyof BookingsRequestQuery["filters"]
        ] as DateFilter
      )?.ge !== dateFilter[0] ||
        (
          query?.filters[
            field as keyof BookingsRequestQuery["filters"]
          ] as DateFilter
        )?.le !== dateFilter[1])
    ) {
      setDateFilter([
        (
          query?.filters[
            field as keyof BookingsRequestQuery["filters"]
          ] as DateFilter
        )?.ge,
        (
          query?.filters[
            field as keyof BookingsRequestQuery["filters"]
          ] as DateFilter
        )?.le,
      ]);
    }
  }, [query]);

  const handleClear = () => {
    setDateFilter([undefined, undefined]);
    loadBookings((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        [field]: undefined,
      },
    }));
  };

  return (
    <Box width="300px">
      <LocalizationProvider
        dateLibInstance={moment}
        dateAdapter={
          //TODO: upgrade to muix v5 and fix this weird conversion
          MomentAdapter as new (...args: any) => MuiPickersAdapter<unknown>
        }
        locale={lang}
      >
        <DateRangePicker
          //ref={dateRangeRef}
          startText={t("from")}
          endText={t("until")}
          value={dateFilter}
          onChange={(date) => {
            /** In this onChange handler we first want to ccollect two dates range input from the user
             * and then only apply it to the global date filter
             */

            //user chooses first date of the filter
            if (
              currentDateSearch[0] == undefined &&
              currentDateSearch[1] == undefined
            ) {
              setCurrentDateSearch([date[0] as string, undefined]);
            } else if (
              currentDateSearch[0] &&
              currentDateSearch[1] == undefined &&
              date[0] &&
              date[1] == undefined
            ) {
              //user chooses first date for the second time

              setCurrentDateSearch([date[0] as string, undefined]);
            } else if (
              currentDateSearch[0] &&
              currentDateSearch[1] == undefined &&
              date[0] &&
              date[1]
            ) {
              //user chooses second date

              setCurrentDateSearch([undefined, undefined]); //clear current search
              setDateFilter([date[0] as string, date[1] as string]); // apply current search
            }
            //setDateFilter([date[0] as string, date[1] as string]);
          }}
          renderInput={(startProps, endProps) => {
            return (
              <Box
                p={1}
                mb={1}
                width={"100%"}
                display="flex"
                alignItems="center"
              >
                <TextField
                  {...startProps}
                  error={false} //disable validation
                  fullWidth
                  label=""
                  placeholder={`${t(field)} ${t("filter")}`}
                  helperText=""
                  variant="standard"
                  inputProps={{
                    ...startProps.inputProps,
                    placeholder: `${t(field)} ${t("filter")}`,
                    value:
                      dateFilter[0] && dateFilter[1]
                        ? `${moment(dateFilter[0])
                            .utc(false)
                            .format("DD/MM/YYYY")} - ${moment(dateFilter[1])
                            .utc(false)
                            .format("DD/MM/YYYY")}`
                        : "",
                    onKeyPress: (e) => e.preventDefault(),
                    readOnly: true,
                  }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>date_range</Icon>
                      </InputAdornment>
                    ),
                    endAdornment:
                      dateFilter[0] && dateFilter[1] ? (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClear}>
                            <Icon>clear</Icon>
                          </IconButton>
                        </InputAdornment>
                      ) : undefined,
                  }}
                />
              </Box>
            );
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};
