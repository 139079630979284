import { makeStyles } from "@material-ui/core/styles";

export const useStyles = ({ minimizeMenu } = { minimizeMenu: false }) => {
  const sideMenuWidth = minimizeMenu ? "55px" : "230px";
  return makeStyles((theme) => {
    return {
      root: {
        "& .MuiPaper-root": {
          "& .MuiTable-root": {
            borderCollapse: "separate",
            "& .MuiTableCell-root": {
              padding: "1rem",
              border: 0,
              borderRight: "1px solid rgba(224, 224, 224, 1)",
              borderTop: "1px solid rgba(224, 224, 224, 1)",
              fontSize: "14px !important",
              fontFamily: "Asap !important",
              textAlign: "left",
              "& .MuiTypography-root": {
                fontSize: "14px !important",
                fontFamily: "Asap !important",
              },
              "& input": {
                fontFamily: "Asap !important",
                fontSize: "14px !important",
              },
              "& .MuiFormHelperText-root": {
                fontFamily: "Asap !important",
              },
              "& .MuiSelect-root": {
                fontFamily: "Asap !important",
                fontSize: "14px",
              },
            },
            // "& .material-icons": {
            //   color: "#707070"
            // }
          },
          "& .MuiToolbar-regular": {
            minHeight: "40px !important",
            paddingLeft: 0,
          },
        },
        "& .MuiTabs-root": {
          minHeight: "32px",
          "& .MuiTab-root": {
            padding: "0px 12px",
            minHeight: "32px",
            backgroundColor: theme.palette.text.disabled,
            fontWeight: 500,
            fontSize: "0.875rem",
          },
          "& .MuiTab-root.Mui-selected": {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: "white",
          },
        },
        "& textarea": {
          fontFamily: "Asap !important",
          padding: "0.5rem",
        },
        "& ::-webkit-scrollbar:horizontal": {
          height: "10px",
        },
        "& .hamburger": {
          outline: "none",
          "& .hamburger-inner, .hamburger-inner::before, & .hamburger-inner::after":
            {
              backgroundColor: theme.palette.primary.main,
            },
        },
        "& .display-small": {
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
        },
        "& .choose-template-row": {
          cursor: "pointer",
          "&:hover": {
            "& td": {
              backgroundColor: `rgb(200,200,200) !important`,
              transitionDuration: "0.1s",
            },
          },
        },
        "& .waiting-table-row": {
          "& td": {
            backgroundColor: `${theme.palette.warning.light} !important`,
          },
        },
        "& .confirmed-table-row": {
          "& td": {
            borderTop: `1px solid ${theme.palette.primary.main} !important`,
            borderBottom: `1px solid ${theme.palette.primary.main} !important`,
          },
          "& td:first-child": {
            borderLeft: `1px solid ${theme.palette.primary.main} !important`,
          },
          "& td:last-child": {
            borderRight: `1px solid ${theme.palette.primary.main} !important`,
          },
        },
        "& .width-side-menu": {
          width: sideMenuWidth,
          transitionDuration: "0.5s",
          [theme.breakpoints.down("md")]: {
            width: 0,
          },
        },

        "& .width-body": {
          width: `calc(100vw - ${sideMenuWidth})`,
          marginLeft: sideMenuWidth,
          transitionDuration: "0.5s",
          [theme.breakpoints.down("md")]: {
            width: "100%",
            marginLeft: 0,
          },
        },

        "& .content": {
          padding: "5px",
        },

        "& .content img": {
          maxWidth: "100%",
          border: `${theme.palette.secondary.main} solid 1px`,
        },

        "& .content span": {
          whiteSpace: "normal",
        },

        "& .content p": {
          whiteSpace: "normal",
        },
      },

      disabledArea: {
        opacity: " 0.5",
        background: theme.palette.text.disabled,
        cursor: "not-allowed",
        "& div, button": {
          pointerEvents: "none",
        },
      },
      sideMenu: {
        //boxShadow: "4px 0px 17px -7px rgba(0,0,0,0.75)",
        position: "relative",
        width: "100%",
        minHeight: "99.9vh",
        transitionDuration: "0.3s",

        [theme.breakpoints.down("md")]: {
          width: 0,
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 200,
          overflowX: "hidden",
          backgroundColor: "white",
          "&.side-menu-opened": {
            width: "220px",
          },
          "&.side-menu-opened-minimal": {
            width: "50px",
          },
        },
        "& button": {
          whiteSpace: "nowrap",
        },
        "& .active-button": {
          backgroundColor: theme.palette.secondary.light,
        },
      },
      logo: {
        height: 100,
        width: sideMenuWidth,
        transitionDuration: "0.5s",
        "& img": {
          width: "50%",
        },
      },
      quickForm: {
        position: "relative",
        "& .MuiPaper-root": {
          paddingLeft: 0,
          //paddingBottom: "2rem"
        },
        "& .MuiTableCell-head": {
          paddingTop: "0.25rem !important",
          paddingBottom: "0.25rem !important",
        },
        "& .MuiTableCell-root": {
          verticalAlign: "top",
          paddingTop: "0.5rem !important",
          paddingBottom: "0.5rem !important",
          // "& .MuiTypography-root": {
          //   marginTop: "0.25rem"
          // },
          border: "1px solid rgba(224, 224, 224, 1)",
          paddingLeft: "1rem !important",
          paddingRight: "1rem !important",
        },

        "& .MuiTable-root": {
          "& .MuiInputBase-root": {
            height: "100%",
            maxHeight: "27px",
          },
          "& .MuiFormControl-root": {
            //width: "100%",
            marginTop: "-0.25rem",
          },
        },
        "& .MuiIconButton-root": {
          padding: "0 !important",
        },

        "& .draggable": {
          cursor: "grab",
          "&:active": {
            cursor: "grabbing",
          },
        },

        "& .draggable-destination-item": {
          height: "100px",
          width: "100%",
          position: "relative",
          //borderBottom: `1px solid ${theme.palette.grey[800]} !important`,
          "& div": {
            position: "absolute",
            height: "92px",
            border: `5px solid ${theme.palette.grey[300]} !important`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "40px",
            background: `repeating-linear-gradient(45deg,${theme.palette.grey[300]},${theme.palette.grey[300]} 10px, ${theme.palette.grey[100]} 10px,${theme.palette.grey[100]} 20px)`,
          },
        },

        "& .disable-destination-item .draggable-destination-item": {
          display: "none",
        },
      },
      bookingsList: {
        "& .MuiTableCell-root": {
          padding: "0.6rem !important",
        },
      },
      bookingsDetails: {
        "& .transitionStep": {
          position: "relative",
          width: "20px",
          height: "20px",
          fontSize: "12px",
          backgroundColor: theme.palette.grey[500],
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .step-upper-info": {
            position: "absolute",
            bottom: "4.5vw",
            left: "-5px",
            width: "25vw",
            [theme.breakpoints.down("md")]: {
              bottom: "10vw",
            },
            [theme.breakpoints.down("sm")]: {
              width: "50vw",
              bottom: "13vw",
            },
          },
          "& .step-down-info": {
            position: "absolute",
            top: "30px",
            left: "-5px",
          },
          "& .step-info-right": {
            left: "unset",
            right: 0,
            textAlign: "right",
          },
          "& .transformed-icon": {
            transform: "rotate(180deg) scaleX(-1)",
          },
        },

        "& .transitionStep.accomplished": {
          backgroundColor: theme.palette.primary.main,
        },
      },
    };
  })();
};
