import React from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@material-ui/pickers";
import { MuiPickersAdapter } from "@material-ui/pickers/_shared/hooks/useUtils";

import { EditComponentProps } from "material-table";
import { FieldParameters, IBooking } from "navision-proxy-api/@types";
import BookingFormField from "../BookingForm/BookingFormField";
import { useLang } from "context/lang";

export default ({
  path,
  dateFnsLocale,
}: // bookingFormData,
// setBooking,
// handleClearAddresses,
// dateFnsLocale,
{
  path: FieldParameters;
  dateFnsLocale: Object | undefined;
}) => {
  const editComponent = (props: EditComponentProps<IBooking>) => {
    return (
      <BookingFormField
        field={path}
        index={(props.rowData as any).tableData?.id || 0}
        booking={props.rowData}
        value={props.value}
        onChange={(_, value) => props.onChange(value)}
        variant="standard"
        hideLabel
        hidePlaceholder
        required
      />
    );
  };

  // const addressEditComponent = (props: EditComponentProps<IBooking>) => {
  //   return (
  //     <BookingFormField
  //       field={path}
  //       booking={props.rowData}
  //       value={props.rowData[path.id as keyof IBooking]}
  //       onChange={(fieldId, value) => {
  //         setBooking({ [fieldId]: value }); //for triggering customers customer hook
  //         //onChange(value);
  //       }}
  //       onClear={() => handleClearAddresses(path.id)}
  //       hideLabel
  //       hidePlaceholder
  //       variant="standard"
  //     />
  //   );
  // };

  /** TODO: Check if used, if so - use BookingFormField */
  const timeEditComponent = ({
    value,
    onChange,
  }: EditComponentProps<IBooking>) => (
    <LocalizationProvider
      dateAdapter={
        DateFnsAdapter as new (...args: any) => MuiPickersAdapter<unknown>
      }
    >
      <TimePicker
        value={value}
        onChange={onChange}
        ampm={false}
        renderInput={(props) => (
          <Box width="125px" clone>
            <TextField
              {...props}
              helperText="hh:mm"
              inputProps={{ ...props.inputProps, placeholder: "hh:mm" }}
            />
          </Box>
        )}
      />
    </LocalizationProvider>
  );

  /** TODO: Check if used, if so - use BookingFormField */
  const dateTimeEditComponent = ({
    onChange,
    value,
  }: EditComponentProps<IBooking>) => {
    return (
      <LocalizationProvider
        dateAdapter={
          DateFnsAdapter as new (...args: any) => MuiPickersAdapter<unknown>
        }
        locale={dateFnsLocale}
      >
        {" "}
        <DatePicker
          disablePast
          value={value}
          onChange={onChange}
          renderInput={(props) => (
            <Box width="125px" clone>
              <TextField
                {...props}
                inputProps={{
                  ...props.inputProps,
                  placeholder: "dd/mm/yyyy",
                }}
              />
            </Box>
          )}
        />
      </LocalizationProvider>
    );
  };

  const goodsDescriptionComponent = ({
    value = [],
    onChange,
    ...other
  }: EditComponentProps<IBooking>) => {
    const handleChangeDescription = (index: number, v: string) => {
      const newValue = [...value];
      newValue[index] = v;
      onChange(newValue);
    };

    return (
      <Box display="flex" flexDirection="column">
        {other.rowData.goods?.map((_, i) => (
          <Box key={i} mt="2px" mb="12px">
            <TextField
              value={value[i]}
              onChange={(e) => handleChangeDescription(i, e.target.value)}
              inputProps={{ maxLength: 30 }}
            />
          </Box>
        ))}
      </Box>
    );
  };

  const packagesDescriptionComponent = ({
    value = [],
    onChange,
    ...other
  }: EditComponentProps<IBooking>) => {
    const handleChangeDescription = (index: number, v: string) => {
      const newValue = [...value];
      newValue[index] = v;
      onChange(newValue);
    };

    return (
      <Box display="flex" flexDirection="column">
        <Box mt="2px" mb="12px">
          <TextField
            value={value[0]}
            onChange={(e) => handleChangeDescription(0, e.target.value)}
          />
        </Box>
      </Box>
    );
  };

  const components = [
    // {
    //   pickBy: () => path.isAddress,
    //   component: addressEditComponent,
    // },
    {
      pickBy: () => path.type === "Time",
      component: timeEditComponent,
    },
    {
      pickBy: () => path.id === "goodsDescription",
      component: goodsDescriptionComponent,
    },
    {
      pickBy: () => path.id === "packagesDescription",
      component: packagesDescriptionComponent,
    },
    {
      pickBy: () => path.isDateTime && path.type == "Date",
      component: dateTimeEditComponent,
    },
    {
      pickBy: () => true, //default
      component: editComponent,
    },
  ];

  return components.find(({ pickBy }) => pickBy())?.component;
};
