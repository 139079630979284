import gbFlag from "../assets/img/gb_flag.svg";
import dkFlag from "../assets/img/dk_flag.svg";
import deFlag from "../assets/img/de_flag.svg";
import seFlag from "../assets/img/se_flag.svg";
import nlFlag from "../assets/img/nl_flag.svg";

//download flag that you need from https://hjnilsson.github.io/country-flags/
//save it to assets/img/ and import it here

export const LANG_CODE_CONVERSION = {
  ENG: "en",
  DAN: "da",
  DEU: "de",
  GER: "de",
  SVE: "sv",
  DUT: "nl",
  NLD: "nl",
  EN: "en",
  DA: "da",
  DE: "de",
  SV: "sv",
  NL: "nl",
};

export const TRANSLATION = {
  en: {
    actions: "Actions",
    flagUrl: gbFlag,
    languageName: "English",
    webbooking: "Webbooking",
    bookings: "Booking overview",
    createBooking: "Create Booking",
    quickForm: "Quick Form",
    createLocalBooking: "Create local booking",
    quickFormLocal: "Quick local booking",
    help: "Help?",
    myCompany: "My company",
    bookingId: "Booking id",
    customer: "Customer",
    delivery: "Delivery",
    receiver: "Receiver",
    pickup: "Pickup",
    customerNb: "Customer nb",
    itemId: "Invoice",
    sender: "Sender",
    receiver: "Receiver",
    pickupDate: "Pickup date",
    pickupTime: "Pickup time",
    departureDate: "Departure Date",
    tourTime: "Tour time",
    description: "Description",
    palletType: "Unit",
    quantity: "Quantity",
    note: "Note",
    status: "Status",
    showHide: "Adjust columns",
    Processed: "Processed",
    delivered: "Delivered",
    packages: "Packages",
    pallet: "Unit",
    goods: "Units",
    save: "Save",
    goodIsEmpty: "You can't create booking with empty goods.",
    errorRequired: "This field is reqired.",
    bookingCreated: "Booking was successfully saved.",
    bookingsImported: "Bookings was successfully imported.",
    time: "Time",
    importExcel: "Import from Excel",
    addToQuickForm: "Add to quick form",
    addBooking: "Add booking",
    addLine: "Add line",
    lineCreated: "Line was successfuly created",
    createNew: "Create new",
    edit: "Edit",
    discard: "Discard Changes",
    quickFormUpdated: "Quick form was successfully updated.",
    errorUpdatingQuickForm: "Error while updating quick form.",
    enterQuickFormName: "Please enter name",
    errorPickupLaterDelivery:
      "Delivery date cannot be earlier than pickup date.",
    quickFormCreated: "Quick form was successfully created.",
    error: "There is an error occured... Please try later.",
    updatedAt: "Updated at",
    createdAt: "Created at",
    createOrder: "Create Order",
    orderCreated: "Order created",
    isLocal: "Local",
    downloadPdf: "Download as PDF",
    useTemplate: "Choose template",
    chooseTemplate: "Choose booking you want to use for template",
    copyForSender: "Copy to sender",
    from: "From",
    until: "Until",
    to: "to", //like "from - to"
    newQuickForm: "Quick Form",
    newQuickFormLocal: "Local quick form",
    noRecords: "No records to display",
    delete: "Delete",
    edit: "Edit",
    deleteText: "Are you sure delete this row?",
    cancel: "Cancel",
    search: "Search",
    menu: "Menu",
    latestDeliveryTime: "Latest time",
    deleteQuickForm: "Delete quick form",
    quickFormDeleted: "Quick form was successfully deleted",
    oftest: "Oftest",
    errorNullEmpty: "This field cannot be empty",
    cantCreateOrder: "You can't create order with empty fields",
    validationFailed: "Validation failed",
    username: "Username",
    password: "Password",
    login: "Login",
    logout: "Logout",
    inProgress: "In progress",
    type: "Type",
    processed: "Processed",
    waiting: "Waiting",
    customersCustomer: "Client",
    bookingDeleted:
      "Deleting was requested, booking will dissapear once deleting will be proccessed",
    goodsDescription: "Units description",
    packagesDescription: "Packages description",
    deadline: "Deadline",
    editBooking: "Edit booking",
    editLocalBooking: "Edit local booking",
    addNewAddress: "Add new address",
    addAddress: "Add address",
    addNew: "Add new",
    add: "Add",
    name: "Name",
    address: "Address",
    postalCode: "Postal code",
    city: "City",
    country: "Country",
    deliveryTime: "Delivery time",
    deliveryDate: "Delivery date",
    settings: "Settings",
    defaultCustomerAddress: "Default customer address",
    defaultUnit: "Default good",
    defaultPackages: "Default packages",
    principalCode: "Principal code",
    userId: "User id",
    indtrans: "Indtrans",
    availible: "availible",
    unavailible: "unavailible",
    addressNumber: "Address number",
    latestDeliveryDate: "Latest delivery date",
    addressLoading: "Address will apear soon...",
    receiverRef: "Receiver reference",
    errorDatePast: "Date cannot be in past",
    errorObjectValuesRequired: "This field cannot contain empty values",
    export: "Export",
    exportExcel: "Export Excel",
    settingsSaved: "Settings saved",
    minimizeMenu: "Minimize menu",
    saveGoodsDescription: "Save goods description",
    savePackagesDescription: "Save packages description",
    saveDescription: "Save description",
    changeOrder: "Click & drag to change line order.",
    wrongCredentials: "Your username or password is incorrect.",
    unrevertableAction: "Are you sure? This action is unrevertable.",
    restoreSettings: "Restore my settings to default",
    showOnlyMyBookings: "Show only my bookings",
    //packages
    CC: "Cont.",
    DS12CC: "DS ½Cont.",
    DS12Hylde: "DS ½ Hylde",
    EUPL: "Euro palle",
    EuroCC: "EUR Cont.",
    EuroHylde: "Euro Hylde",
    "FORL&dot;": "Extensions",
    HalfCC: "½ Cont.",
    HalfHylde: "½Hylde",
    HalfPLL: "½ palle",
    HalfRFIDCC: "½ Cont. med lås",
    HYLDER: "Shelves",
    Oneway: "Oneway",
    QuarterPLL: "1/4 Palle",
    STPL: "STPL",
    "T5 RFID CC": "Rfid CC (Green)",
    KASSE: "Box",
    "RFID CC": "CC (Red)",

    //help
    helpHeader: "How can we help you?",
    helpSubheader: "Choose a category to get help.",
    myCompany: "My company",
    myCompany1:
      'Under "My Company", which can be found in the upper right corner, you can check your company information, including user ID.',
    myCompany2:
      "You can simultaneously set your standard customer address, standard shipping units and standard packaging.",
    myCompany3:
      " This means that the default settings you select here will be set up automatically when you want to create a new booking.",
    adjustColumns: "Add columns",
    adjustColumns1:
      'Under all menu items you have the option to add and remove columns by clicking on "Add column", which is located at the top right of the page.',
    adjustColumns2:
      " By clicking on this menu, you have the opportunity to choose which columns you want to see. This allows you to customize the view to exactly what you need. All you have to do is tick the columns that are relevant to you.",
    bookingsFeatures: "Booking overview",
    bookingsFeatures1:
      "<b> Export </b> allows you to download your bookings to your computer in an Excel file. You can do this for both ongoing bookings and completed bookings. You can specify which orders you want to download in Excel format by filtering at time intervals. ",
    bookingsFeatures2:
      "<b> Download as PDF </b> allows you to download a specific booking as a PDF file. You can do this for both ongoing bookings and completed bookings. The button is located under Actions and looks like on the image. ",
    bookingsFeatures3:
      "Under completed bookings, you can press <b> Select order from the list below </b>, which gives you the opportunity to use an already created booking as a template for a new booking. This allows you to reuse an old booking so that you do not have to enter all the information again.",
    bookingsFeatures4:
      "By pressing the button, you can select an order from the booking overview, which will then send you to Create booking, where all the information from the previous booking has been entered.",
    createBookingFeatures: "Create booking",
    createBookingFeatures1:
      "<b> Use Order as Template </b> allows you to use an already created booking as a template for a new booking. This allows you to reuse an old booking so that you do not have to enter all the information again.",
    createBookingFeatures2:
      "<b> Add to Quick Form </b> allows you to add your booking to an already created Quick Form. This allows you to quickly repeat your most common bookings.",
    createBookingFeatures3:
      "<b> Add New </b> gives you the ability to create new senders, recipients, pickup locations, and delivery locations. So, if you get a new customer or change address, then it is easy and fast to create them. If, on the other hand, you want to remove an address or customer, you must contact Alex Andersen and we will do it for you.",
    quickFormFeatures: "Quick form",
    quickFormFeatures1:
      "<b> Create New </b> is used to create a new quick form. Just give it a name and press save. If you reconsider, press undo. ",
    quickFormFeatures2:
      "<b> Edit </b> allows you to rename an already created quick form. You can also delete the form here if you no longer need it.",
    guidesForWebooking: "Guides for Webbooking",

    buttonsView: "Buttons view",
    tableView: "Table view",
    onBalance: "on balance",
    printed: "printed",
    waitingPrinting: "waiting for printing",
    confirm: "Confirm",
    order: "Order",
    labels: "Order labels",
    showLabelsMenu: "Enable labels order feature.",
    extentions: "Extentions",
    ready: "Booking is ready",
    notReady: "Booking is not ready",
  },
  da: {
    flagUrl: dkFlag,
    languageName: "Dansk",
    actions: "Handlinger",
    webbooking: "Webbooking",
    bookings: "Booking oversigt",
    createBooking: "Opret booking",
    quickForm: "Hurtig formular",
    createLocalBooking: "Opret Indtransport",
    quickFormLocal: "Hurtig formular Indtrans",
    help: "Hjælp?",
    myCompany: "Min virksomhed",
    bookingId: "Booking Id",
    customer: "Afsender",
    delivery: "Leveringssted",
    receiver: "Modtager",
    pickup: "Afhentningssted",
    customerNb: "Kunde Id",
    itemId: "Reference",
    sender: "Afsender",
    receiver: "Modtager",
    pickupDate: "Afhentningsdato",
    pickupTime: "Afhentningstid",
    departureDate: "Afgangsdato",
    tourTime: "Turtid",
    description: "Bem. ordre",
    palletType: "Enhed",
    quantity: "Antal",
    note: "Note",
    status: "Status",
    showHide: "Tilføj kolonne",
    Processed: "Behandlet",
    delivered: "Afsluttede",
    packages: "Emballage",
    pallet: "Enhed",
    goods: "Enheder",
    save: "Gem",
    errorRequired: "Dette felt kræves.",
    goodIsEmpty: "Du kan ikke oprette booking med tomme enheder.",
    bookingCreated: "Booking blev oprettet.",
    bookingsImported: "Bookinger blev importeret.",
    time: "Tid",
    useTemplate: "Brug ordre som skabelon",
    chooseTemplate: "Vælg ordre fra nedenstående liste",
    importExcel: "Indlæs fra Excel",
    addToQuickForm: "Tilføj til Hurtig Formular",
    addBooking: "Tilføj booking",
    addLine: "Tilføj linje",
    lineCreated: "Linje blev oprettet.",
    createNew: "Opret ny",
    edit: "Redigere",
    discard: "Kassér ændringer",
    quickFormUpdated: "Hurtig formular blev opdateret.",
    errorUpdatingQuickForm: "Fejl under opdatering af hurtig formular.",
    errorPickupLaterDelivery:
      "Leveringsdatoen må ikke være tidligere end afhentningsdatoen.",
    enterQuickFormName: "Intast navn",
    quickFormCreated: "Hurtig formular blev oprettet.",
    error: "Der opstod en fejl... Prøv senere.",
    updatedAt: "Opdateret kl.",
    createdAt: "Oprettet kl.",
    createOrder: "Opret booking",
    orderCreated: "Ordre oprettet",
    isLocal: "Indtransport",
    downloadPdf: "Download som PDF",
    useAsTemplate: "Brug som skabelon",
    copyForSender: "Kopiér til afsender",
    from: "Fra",
    until: "Til",
    to: "til", //like "fra - til"
    newQuickForm: "Hurtig formular",
    newQuickFormLocal: "Hurtig indtrans formular",
    noRecords: "Ingen poster at vise",
    delete: "Slet",
    deleteQuickForm: "Slet hurtig formular",
    edit: "Rediger",
    deleteText: "Er du sikker på, at du vil slette denne række?",
    cancel: "Fortryd",
    search: "Søg",
    menu: "Menu",
    latestDeliveryTime: "Seneste leveringstid",
    quickFormDeleted: "Hurtig formular blev slettet.",
    oftest: "Oftest",
    errorNullEmpty: "Dette felt kan ikke være tom eller 0",
    cantCreateOrder: "Du kan ikke oprette ordre med tomme felter",
    validationFailed: "Validering mislykkedes",
    login: "Log på",
    logout: "Log ud",
    username: "Brugernavn",
    password: "Adgangskode",
    inProgress: "I gang",
    type: "Type",
    processed: "Behandlet",
    waiting: "Venter",
    customersCustomer: "Kunde",
    bookingDeleted: "Bookingen vil forsvinde når sletningen er gennemført",
    goodsDescription: "Godsbeskrivelse",
    packagesDescription: "Emballagebeskrivelse",
    deadline: "Deadline",
    editBooking: "Rediger booking",
    editLocalBooking: "Rediger indtransport",
    addNewAddress: "Tilføj ny adresse",
    addAddress: "Tilføj adresse",
    addNew: "Tilføj ny",
    add: "Tilføj",
    name: "Navn",
    address: "Adresse",
    postalCode: "Post nummer",
    city: "By",
    country: "Land",
    deliveryTime: "Leveringstid",
    deliveryDate: "Leveringsdag",
    settings: "Indstillinger",
    defaultCustomerAddress: "Standard kunde adresse",
    defaultUnit: "Standard fragt",
    defaultPackages: "Standard emballage",
    principalCode: "Principal code",
    userId: "BrugerID",
    indtrans: "Indtrans",
    availible: "Tilgængelig",
    unavailible: "Ikke tilgængelig",
    addressNumber: "Adresse nummer",
    latestDeliveryDate: "Seneste leveringsdag",
    addressLoading: "Adresse vil blive vist snart...",
    receiverRef: "Modtager reference",
    errorDatePast: "Dato kan ikke være tidligere end dags dato",
    errorObjectValuesRequired: "Dette felt kan ikke være tomt",
    export: "Eksporter",
    exportExcel: "Eksporter Excel",
    settingsSaved: "Indstillinger gemt",
    minimizeMenu: "Minimer menu",

    saveGoodsDescription: "Gem godsbeskrivelse",
    savePackagesDescription: "Gem emballagebeskrivelse",
    saveDescription: "Gem bem. ordre",

    changeOrder: "Klik og træk for at ændre linjerækkefølgen.",

    wrongCredentials: "Dit brugernavn eller din adgangskode er forkert.",

    unrevertableAction: "Er du sikker? Denne handling kan ikke fortrydes.",
    restoreSettings: "Gendan mine indstillinger til standard.",

    showOnlyMyBookings: "Vis kun mine reservationer",

    //packages
    CC: "Cont.",
    DS12CC: "DS ½Cont.",
    DS12Hylde: "DS ½ Hylde",
    EUPL: "Euro palle",
    EuroCC: "EUR Cont.",
    EuroHylde: "Euro Hylde",
    "FORL&dot;": "Forlænger",
    HalfCC: "½ Cont.",
    HalfHylde: "½Hylde",
    HalfPLL: "½ palle",
    HalfRFIDCC: "½ Cont. med lås",
    HYLDER: "Hylder",
    Oneway: "Oneway",
    QuarterPLL: "1/4 Palle",
    STPL: "STPL",
    "M2 CC": "M2 CC (lille)",
    "RFID CC": "CC (Rød)",
    "T5 RFID CC": "Rfid CC (Grøn)",

    //help
    helpHeader: "Hvordan kan vi hjælpe dig?",
    helpSubheader: "Vælg en kategori for at finde hjælp.",
    myCompany: "Min virksomhed",
    myCompany1:
      "Under ”Min Virksomhed”, som findes i højre hjørne, kan man tjekke sine virksomhedsoplysninger, herunder brugerID.",
    myCompany2:
      "Du kan samtidig indstille din standard kunde adresse, standard fragtenheder og standard emballage.",
    myCompany3:
      " Dette betyder at de standardindstillinger du vælger her, vil være sat op automatisk når du vil oprette en ny booking.",
    adjustColumns: "Tilføj kolonner",
    adjustColumns1:
      "Under alle menupunkterne har man mulighed for at tilføje og fjerne kolonner ved at trykke på ”Tilføj kolonne”, som findes oppe i højre side.",
    adjustColumns2:
      "Ved at trykke på denne menu, har man mulighed for selv at vælge hvilke kolonner man vil se. Dette giver dig mulighed for at tilpasse visningen til lige præcis det du har behov for. Alt du skal gøre er at sætte flueben i de kolonner der er relevante for dig.",
    bookingsFeatures: "Booking oversigt",
    bookingsFeatures1:
      "<b>Eksporter</b> giver dig mulighed for at hente dine bookinger ned på computeren i en Excel fil. Du kan både gøre dette for igangværende bookinger og afsluttede bookinger. Du kan specificere hvilke ordrer du ønsker at hente i Excel format ved at filtrerer på tidsintervaller.",
    bookingsFeatures2:
      "<b>Download som PDF</b> giver dig mulighed for at hente en specifik booking ned som en PDF-fil. Du kan både gøre dette for igangværende bookinger og afsluttede bookinger. Knappen findes under handlinger og ser ud som på billedet.",
    bookingsFeatures3:
      "Under afsluttede bookinger kan man trykke på <b>Vælg ordre fra nedenstående liste</b>, som giver dig muligheden for at bruge en allerede oprettet booking som skabelon til en ny booking. Dermed kan du genbruge en gammel booking, så du ikke skal indtaste alle informationer igen.",
    bookingsFeatures4:
      "Ved at trykke på knappen kan du vælge en ordre fra bookingoversigten, som efterfølgende sender dig videre til Opret booking, hvor alle informationerne fra den tidligere booking er indtastet.",
    createBookingFeatures: "Opret booking",
    createBookingFeatures1:
      "<b>Brug ordre som skabelon</b> giver dig muligheden for at bruge en allerede oprettet booking som skabelon til en ny booking. Dermed kan du genbruge en gammel booking, så du ikke skal indtaste alle informationer igen.",
    createBookingFeatures2:
      "<b>Tilføj til Hurtig Formular</b> giver dig muligheden for at tilføje din booking til en allerede oprettet hurtig formular. Dermed kan du hurtigt gentage dine mest almindelige bookinger.",
    createBookingFeatures3:
      "<b>Tilføj ny</b> giver dig muligheden for at oprette nye afsendere, modtagere, afhentningssteder og leveringssteder. Så hvis du får en ny kunde eller skifter adresse, så er det nemt og hurtigt at oprette dem. Hvis du derimod vil have fjernet en adresse eller kunde, skal du kontakte Alex Andersen, så klarer vi det for dig.",
    quickFormFeatures: "Hurtig formular",
    quickFormFeatures1:
      "<b>Opret ny</b> bruges til at oprette en ny hurtig formular. Bare giv den et navn også tryk gem. Hvis du fortryder, så tryk på fortryd. ",
    quickFormFeatures2:
      "<b>Rediger</b> giver dig muligheden for at ændre navnet på en allerede oprettet hurtig formular. Du kan også slette formularen her hvis du ikke skal bruge den mere.",
    guidesForWebooking: "Guides til Webbooking",

    buttonsView: "Knapper visning",
    tableView: "Tabel visning",
    onBalance: "i balance",
    printed: "printet",
    waitingPrinting: "venter på udskrivning",
    confirm: "Bekræft",
    order: "Bestil",
    labels: "Bestil etiketter",
    showLabelsMenu: "Aktivieren Sie die Funktion zum Bestellen von Etiketten.",
    extentions: "Erweiterungen",

    ready: "Booking er klar",
    notReady: "Booking er ikke klar",
  },
  de: {
    flagUrl: deFlag,
    languageName: "Deutsch",
    actions: "Aktionen",
    webbooking: "Webbooking",
    bookings: "Buchungsübersicht",
    createBooking: "Buchung erstellen",
    quickForm: "Schnellformular",
    createLocalBooking: "Buchung im Nahverkehr erstellen",
    quickFormLocal: "Schnellformular Nahverkehr",
    help: "Hilfe?",
    myCompany: "Mein Unternehmen",
    bookingId: "Buchungs- ID",
    customer: "Absender",
    delivery: "Abladestelle",
    receiver: "Empfänger",
    pickup: "Abholungsort",
    customerNb: "Kunden - ID",
    itemId: "Rechnung",
    sender: "Absender",
    receiver: "Empfänger",
    pickupDate: "Abholungsdatum",
    pickupTime: "Abholungszeitpunkt",
    departureDate: "Abgangsdatum",
    tourTime: "Tourzeit",
    description: "Beschreibung",
    palletType: " Einheit",
    quantity: "Anzahl",
    note: "Bemerkung",
    status: "Status",
    showHide: "Spalte hinzufügen",
    Processed: "Bearbeitet",
    delivered: "Abgeschlossen",
    packages: "Emballage",
    pallet: "Einheit",
    goods: "Wareneinheit",
    save: "Speichern",
    errorRequired: "Feld muss ausgefüllt sein",
    goodIsEmpty: "Sie können die Buchung nicht ohne Einheit tätigen",
    bookingCreated: "Buchung wurde erstellt",
    bookingsImported: "Buchung wurde importiert",
    time: "Zeit",
    useTemplate: "Vorlage benutzen",
    chooseTemplate:
      "Wählen Sie die Buchungssendung aus der untenstehenden Liste.",
    importExcel: "Excel einlesen",
    addToQuickForm: "Schnellformular hinzufügen",
    addBooking: "Buchung hinzufügen",
    addLine: "Linie hinzufügen",
    lineCreated: "Linie wurde angelegt",
    createNew: "Neu eingeben",
    edit: "Bearbeiten",
    discard: "Änderungen kassieren",
    quickFormUpdated: "Schnellformular wurde aktualisiert",
    errorUpdatingQuickForm:
      "Fehler bei der Aktualisierung des Schnellformulars",
    enterQuickFormName: "Name eingeben",
    quickFormCreated: "Schnellformular wurde eingerichtet",
    error: "Fehler aufgetreten…Bitte später probieren",
    updatedAt: "Aktualisiert xy Uhr ",
    createdAt: " erstellt xy Uhr",
    createOrder: "Buchung erstellen",
    orderCreated: "Sendung eingeben",
    isLocal: " Nahverkehr",
    downloadPdf: "als PDF herunterladen",
    useAsTemplate: "Vorlage benutzen",
    copyForSender: " Für den Absender kopieren",
    from: "von",
    until: "nach",
    to: "nach",
    newQuickForm: "neues Schnellformular",
    newQuickFormLocal: "Schnellformular Nahverkehr",
    noRecords: "keine Einträge anzuzeigen",
    delete: "Löschen",
    deleteQuickForm: "Schnellformular löschen",
    edit: "Bearbeiten",
    deleteText: "Sind Sie sich sicher, diese Reihe löschen zu wollen?",
    cancel: "Abbrechen (Escape)",
    search: "Suchen",
    menu: "Menü",
    latestDeliveryTime: "Späteste Anlieferzeit",
    quickFormDeleted: "Schnellformular wurde gelöscht",
    oftest: "meistens",
    errorNullEmpty: "Diese Feld darf nicht leer oder mit 0 sein",
    errorPickupLaterDelivery:
      "Das Lieferdatum kann nicht vor dem Abholdatum liegen.",
    cantCreateOrder: "Sie können keine Buchung mit leeren Feldern tätigen.",
    validationFailed: "Überprüfung fehlgeschlagen",
    login: "Einloggen",
    logout: "Ausloggen",
    username: "Benutzername",
    password: "Passwort",
    inProgress: "Am Bearbeiten",
    type: "Type",
    processed: "ausgeführt",
    waiting: "warten",
    customersCustomer: "Kunde",
    bookingDeleted: "Die Buchung vesrchwindet nach dem Löschen",
    goodsDescription: "Warenbeschreibung",
    packagesDescription: "Emballagebeschreibung",
    deadline: "Frist",
    editBooking: "Buchung bearbeiten",
    editLocalBooking: "Buchung Nahverkehr bearbeiten",
    addNewAddress: "Neue Adresse hinzufügen",
    addAddress: "Adresse hinzufügen",
    addNew: " Neu hinzufügen",
    add: "Hinzufügen",
    name: "Name",
    address: "Adresse",
    postalCode: "Postleitzahl",
    city: "Ort",
    country: "Land",
    deliveryTime: "Anlieferzeit",
    deliveryDate: "Anliefertag",
    settings: "Einstellungen",
    defaultCustomerAddress: "Standard Kundenadresse",
    defaultUnit: "Standard Fracht",
    defaultPackages: "Standard Emballage",
    principalCode: "Postleitzahl",
    userId: "Benutzername",
    indtrans: "Nahverkehr",
    availible: "zugänglich",
    unavailible: "nicht zugänglich",
    addressNumber: "Adresse Nummer",
    latestDeliveryDate: "letzter Anliefertag",
    addressLoading: "Die Adresse wird bald angezeigt",
    receiverRef: "Empfänger Referenz",
    errorDatePast: "Datum darf nicht vor dem heutigen Datum liegen",
    errorObjectValuesRequired: "Dieses Feld darf nicht leer sein",
    export: "Exportieren",
    exportExcel: "Excel exportieren",
    settingsSaved: "Einstellungen speichern",
    minimizeMenu: "Menü minimieren",

    saveGoodsDescription: "Warenbeschreibung speichern",
    savePackagesDescription: "Emballagebeschreibung speichern",
    saveDescription: "Bemerkungen speichern",

    changeOrder: "Klicken und ziehen Sie, um die Zeilenreihenfolge zu ändern.",

    wrongCredentials: "Ihr Benutzername oder Passwort ist falsch.",

    unrevertableAction:
      "Bist du dir sicher? Diese Aktion kann nicht rückgängig gemacht werden.",
    restoreSettings: "Meine Einstellungen auf Standard zurücksetzen.",

    showOnlyMyBookings: "Nur meine Buchungen anzeigen",

    //packages
    CC: "CC",
    DS12CC: "DS ½-CC",
    DS12Hylde: "DS ½-Brett",
    EUPL: "Eupl",
    EuroCC: "EU-CC",
    EuroHylde: "EU-Brett",
    "FORL&dot;": "Verlängerung (Verl.)",
    HalfCC: "½-CC",
    HalfHylde: "½-Brett",
    HalfPLL: "½-Palette",
    HalfRFIDCC: "½-CC mit Schloss",
    HYLDER: "Bretter",
    Oneway: "Einweg",
    QuarterPLL: "¼ Palette",
    STPL: "STPL (Grosspalette)",
    "M2 CC": "M2 CC (klein)",
    "RFID CC": "CC (rot)",
    "T5 RFID CC": "RFID-CC (grün)",

    //help
    helpHeader: "Wie können wir Ihnen helfen?",
    helpSubheader: "Wählen Sie eine Kategorie um Hilfe zu finden.",
    myCompany: " Mein Unternehmen",
    myCompany1:
      "Unter der Rubrik ” Mein Unternehmen”, welche Sie in der rechten Ecke finden, können Sie Ihre Unternehmensdaten überprüfen; hier finden Sie auch Ihre Benutzer-ID.",
    myCompany2:
      "Gleichzeitig können Sie ihre Standard- Kundenadressen, Fracht- und Emballageeinheiten anlegen.",
    myCompany3:
      "Das bedeutet, dass die hier ausgewählten Standardeinstellungen automatisch angegeben werden, wenn Sie eine neue Buchung erstellen. ",
    adjustColumns: "Spalten hinzufügen",
    adjustColumns1:
      "Unter allen Menüpunkten haben sie die Möglichkeit Spalten hinzuzufügen oder zu entfernen, indem Sie oben rechts auf „Spalte hinzufügen“ klicken. ",
    adjustColumns2:
      "Indem Sie auf das Menü klicken, haben Sie die Möglichkeit auszuwählen, welche Spalten Sie sehen möchten. Auf diese Weise können Sie die Seite nach ihren Bedürfnissen gestalten. Sie müssen nur,  die für Sie relevanten Spalten ankreuzen.  ",
    bookingsFeatures: "Buchungsübersicht",
    bookingsFeatures1:
      "<b>Eksporter</b> ermöglicht es Ihnen, ihre Buchungen in einer Excel-Datei auf dem Computer herunterzuladen. Sie können dies sowohl für laufende als auch für abgeschlossene Buchungen anwenden. Möchten Sie die Buchungen, die heruntergeladen werden sollen, spezifizieren, können diese durch ein Zeitintervall gefiltert werden.",
    bookingsFeatures2:
      "<b>Download som PDF</b> können Sie eine bestimmte Buchung als PDF-Datei herunterladen. Sie können diese Funktion sowohl für laufende als auch für abgeschlossene Buchungen anwenden. Drücken Sie hierzu unter dem Menü Aktionen, wie auf dem Bild abgebildet.",
    bookingsFeatures3:
      "Unter dem Menü abgeschlossene Buchungen können sie auf <b>Vælg ordre fra nedenstående liste</b> klicken, wodurch Sie eine bereits erstellte Buchung als Vorlage für eine neue Buchung verwenden können. Auf diese Weise können Sie eine alte Buchung wiederverwenden, sodass nicht alle Informationen erneut eingeben werden müssen.  ",
    bookingsFeatures4:
      "Indem Sie auf die Taste drücken, können Sie aus der Buchungsübersicht eine Bestellung auswählen, die Sie dann zu „Buchung erstellen“ weiterleitet, wo alle Informationen der vorherigen Buchung eingetragen wurden.",
    createBookingFeatures: "Buchung erstellen",
    createBookingFeatures1:
      "<b>Brug ordre som skabelon</b> bietet Ihnen die Möglichkeit eine bereits erstellte Buchung als Vorlage für eine neue Bestellung zu verwenden. Auf diese Weise können Sie eine alte Buchung wiederverwenden, so dass Sie nicht alle Informationen erneut angeben müssen.",
    createBookingFeatures2:
      "<b>Tilføj til Hurtig Formular</b> bietet Ihnen die Möglichkeit, Ihre Buchung zu einem bereits erstellten Schnellformular hinzuzufügen. So können Sie Ihre häufigsten Buchungen schnell wiederholen.",
    createBookingFeatures3:
      "<b>Tilføj ny</b> bietet Ihnen die Möglichkeit neue Absender, Empfänger, Auf- und Abladestellen zu erstellen. Wenn Sie neue Kunden bekommen oder die Adresse ändern, können diese einfach und schnell angelegt werden. Sollten Sie hingegen einen Kunden oder eine Adresse löschen wollen, bitte wir Sie,  Alex Andersen zu kontaktieren und wir erledigen dies für Sie.",
    quickFormFeatures: "Schnellformular",
    quickFormFeatures1:
      "<b>Opret ny</b> wird zur Erstellung eines neuen Schnellformulars benutzt. Geben Sie dem Formular einen Namen und drücken Sie danach auf Speichern. Wenn sie die Buchung rückgängig machen wollen, dann drücken Sie auf die Escape-Taste.",
    quickFormFeatures2:
      "<b>Rediger</b> bietet Ihnen die Möglichkeit den Namen eines bereits erstellten Schnellformulars zu ändern. Hier können sie auch ein Formular löschen, wenn Sie dies nicht mehr benötigen.",
    guidesForWebooking: "Anleitungen für Webbooking",

    buttonsView: "Schaltflächenansicht",
    tableView: "Tabellenansicht",
    onBalance: "im Gleichgewicht",
    printed: "gedruckt",
    waitingPrinting: "warten auf Drucken",
    confirm: "Bestätigen",
    order: "Bestellen",
    labels: "Etiketten bestellen",
    showLabelsMenu: "Aktiver etikettbestillingsfunktionen.",
    extentions: "Erweiterungen",

    ready: "Die Buchung ist fertig",
    notReady: "Die Buchung ist nicht fertig",
  },
  sv: {
    flagUrl: seFlag,
    languageName: "Svensk",
    actions: "Handling",
    webbooking: "Webbokning",
    bookings: "Översikt",
    createBooking: "Skapa bokning",
    quickForm: "Snabb formulär",
    createLocalBooking: "Skapa inhämtning",
    quickFormLocal: "Snabb formulär inhämtning",
    help: "Hjälp",
    myCompany: "Mitt företag",
    bookingId: "Bokning ID",
    customer: "Avsändare",
    delivery: "Leveransadress",
    receiver: "Mottagare",
    pickup: "Upphämtningsplats",
    customerNb: "Användar idé",
    itemId: "Referens",
    sender: "Avsändare",
    receiver: "Mottagare",
    pickupDate: "Upphämtningsdag",
    pickupTime: "Upphämtningstid",
    departureDate: "Avgångsdatum",
    tourTime: "Turtid",
    description: "Övrigt",
    palletType: "Förpacknings typ",
    quantity: "Kvantitet",
    note: "Notera",
    status: "Status",
    showHide: "Lägg till kolumn",
    Processed: "Behandlad",
    delivered: "Färdigt",
    packages: "Förpackning",
    pallet: "Förpacknings typ",
    goods: "Förpacknings typer",
    save: "Spara",
    errorRequired: "Detta fält är obligatoriskt",
    errorPickupLaterDelivery:
      "Leveransdatum kan inte infalla tidigare än avhämtningsdatum.",
    goodIsEmpty: "Du kan inte boka med tomma Förpacknings typer",
    bookingCreated: "Bokning skapades",
    bookingsImported: "Bokning importerades",
    time: "Tid",
    useTemplate: "Använd bokning som mall",
    chooseTemplate: "Välj beställning från listan nedan",
    importExcel: "Ladda från Excel",
    addToQuickForm: "Lägg till för snabb formulär",
    addBooking: "Lägg till bokning",
    addLine: "Lägg till linje",
    lineCreated: "Linje skapades",
    createNew: "Skapa ny",
    edit: "Redigera",
    discard: "Ta bort ändring",
    quickFormUpdated: "Snabbformuläret uppdateras",
    errorUpdatingQuickForm: "Fel under  uppdatering av snabbformuläret",
    enterQuickFormName: "Skriv namn",
    quickFormCreated: "Snabbformulär skapades",
    error: "Ett fel uppstod… Försök senare",
    updatedAt: "Uppdaterad kl.",
    createdAt: "Skapad kl.",
    createOrder: "Skapa bokning",
    orderCreated: "Bokning skapad",
    isLocal: "Inhämtning",
    downloadPdf: "Download som PDF",
    useAsTemplate: "Använd som skabelon",
    copyForSender: "Kopia til avsändare",
    from: "Från",
    until: "Till",
    to: "Till",
    newQuickForm: "Snabbformulär",
    newQuickFormLocal: "Snabb inhämtnings  formulär",
    noRecords: "Inga uppgifter att visa",
    delete: "Ta bort",
    deleteQuickForm: "Ta bort snabbformulär",
    edit: "Redigera",
    deleteText: "Är du säker på att du vill ta bort den här raden",
    cancel: "Ångra",
    search: "Sök",
    menu: "Menu",
    latestDeliveryTime: "Senaste leveranstid",
    quickFormDeleted: "Snabbformulär är borttaget",
    oftest: "Oftast",
    errorNullEmpty: "Detta fält kan inte vara tomt eller 0",
    cantCreateOrder: "Du kan inte skapa order med tomma fält",
    validationFailed: "Validering misslyckas",
    login: "Logga på",
    logout: "Logga ut",
    username: "Användarnamn",
    password: "Lösenord",
    inProgress: "Pågående",
    type: "Typ",
    processed: "Behandlat",
    waiting: "Väntar",
    customersCustomer: "Kund",
    bookingDeleted: "Bokningen försvinner när raderingen är klar",
    goodsDescription: "Varubeskrivning",
    packagesDescription: "Förpackningsbeskrivning",
    deadline: "Deadline",
    editBooking: "Redigera bokning",
    editLocalBooking: "Redigera inhämtning",
    addNewAddress: "Lägg till ny adress",
    addAddress: "Lägg till adress",
    addNew: "Lägg till ny",
    add: "Lägg till",
    name: "Namn",
    address: "Adress",
    postalCode: "Post nr.",
    city: "Stad",
    country: "Land",
    deliveryTime: "Leveranstid",
    deliveryDate: "Leveransdag",
    settings: "Inställningar",
    defaultCustomerAddress: "Standard kund address",
    defaultUnit: "Standard frakt",
    defaultPackages: "Standard förpackning",
    principalCode: "Huvudkod",
    userId: "Användaridé",
    indtrans: "Inhämtning",
    availible: "Tillgänglig",
    unavailible: "Inte tillgänglig",
    addressNumber: "Adress nr.",
    latestDeliveryDate: "Senaste leverans dag",
    addressLoading: "Snart kan ni se adressen",
    receiverRef: "Mottagar referans",
    errorDatePast: "Datum kan inte vara tidigare än dagens datum",
    errorObjectValuesRequired: "Detta fält får inte vara tomt",
    export: "Exportör",
    exportExcel: "	Exportör Excel",
    settingsSaved: "Inställningar sparade",
    minimizeMenu: "	Minimera menu",
    saveGoodsDescription: "Spara varubeskrivning",
    savePackagesDescription: "Spara förpackningsbeskrivelse",
    saveDescription: "Spara ben. beställning",

    changeOrder: "Klicka och dra för att ändra linjeordning.",

    wrongCredentials: "Ditt användarnamn eller lösenord är felaktigt.",

    unrevertableAction: "Är du säker? Den här åtgärden kan inte återställas.",
    restoreSettings:
      "Återställ mina inställningar till standardinställningarna.",

    showOnlyMyBookings: "Visa endast mina bokningar",

    //packages
    CC: "CC (Svart lås)",
    DS12CC: "Ikke relevant for SE",
    DS12Hylde: "Ikke relevant for SE",
    EUPL: "Ikke relevant for SE",
    EuroCC: "Ikke relevant for SE",
    EuroHylde: "Ikke relevant for SE",
    "FORL&dot;": "Förlängare",
    HalfCC: "	Ikke relevant for SE",
    HalfHylde: "Ikke relevant for SE",
    HalfPLL: "	Ikke relevant for SE",
    HalfRFIDCC: "	Ikke relevant for SE",
    HYLDER: "Hyllor",
    Oneway: "Oneway Pall",
    QuarterPLL: "Ikke relevant for SE",
    STPL: "STPL",
    "M2 CC": "Ikke relevant for SE",
    "RFID CC": "CC (Röd lås)",
    "T5 RFID CC": "Rfdi CC (Grön lås)",

    //help				Hjälp
    helpHeader: "Hur kan vi hjälpa dig",
    helpSubheader: "Välj en kategori för att få hjälp",
    myCompany: "Mitt företag",
    myCompany1:
      "Under ” Mitt företag” som finns i högra hörnet, kan man kontrollera sina företags uppgifter, samt även sitt användar idé",
    myCompany2:
      "Du kan även lägga in din standard adress, standard frakt  och standar förpackning.",
    myCompany3:
      "Detta betyder att de standar inställningar du väljer här, vill stå automatiskt när du lägger in en ny bokning",
    adjustColumns: "	Lägg till kolumner",
    adjustColumns1:
      "Under alla menyalternativ kan man lägga till samt ta bort kolumner genom att trycka på ”lägg till kolumner” som ses uppe i högra hörnet",
    adjustColumns2:
      "När man väljer denna meny, har man själv möjlighet för att välja vilka kolumner man vill se. För att tillpassa den visning du vill ha, skal du enbart göra en markering i de kolumner som du vill se.",
    bookingsFeatures: "Boking översikt",
    bookingsFeatures1:
      "Ger dig möjligheten för att ladda ner dian bokningar på din egen computer i Excel fil. Detta kan du göra på existerande samt avslutad bokninga. Du kan specifikt ta ut vilken bokning du vill hämta i Excel genom att filtrera på tids intervall.",
    bookingsFeatures2:
      "Ger dig möjlighet för att hämta specifik bokning som PDF -fil. Detta kan du göra på existerande samt avslutad bokning. Knappen finns under handlingar och ser ut som på bilden.",
    bookingsFeatures3:
      "Under avslutade bokningar kan man trycka på <b> Välj bokning från nedanstående lista </b>, den ger dig möjlighet för att använda en bokning som du redan lagt in som en mall till en ny bokning. Så du kan använda en gamla bokning, vilket gör att du slipper för att trycka in informationerna igen.",
    bookingsFeatures4:
      "Vid att trycka på knappen kan du välja en bokning från bokningsöversikten. Den bokning du väljer sänder dig vidare till att skapa en ny bokning, där alla informationer från den föregående bokningen finns.",
    createBookingFeatures: "Skapa bokning",
    createBookingFeatures1:
      "    Använd bokning som mall </b> ger dig möjlighet för att använda möjlighet för att använda en bokning som du redan lagt in som en mall till en ny bokning. Så kan du använda en gamla bokning, vilket gör att du slipper för att trycka in informationerna igen.",
    createBookingFeatures2:
      "<b> Lägg till Snabb formuläret </b> ger dig möjligheten för att lägga till din bokning till ett snabb formulär som redan är upprättat. Därmed kan du snabbt upprepa dina vanligaste bokningar.",
    createBookingFeatures3:
      "<b>Lägg till ny</b> Ger dig möjligheten för att lägga upp en ny avsändare, mottagare, hämtnings ställe eller lossnings ställe. Så om du får en ny kund eller om adressen ändras, så kan du lätt och snabbt rätta och skriva in dem själv. Men om du däremot vill ta bort en adress eller en kund, så skal du kontakta Alex Andersen, så gör vi det för dig.",
    quickFormFeatures: "Snabb formulär",
    quickFormFeatures1:
      "<b>Lägg till ny</b> Används till att skapa en ny snabb formulär. Bara ge den ett namn och tryck spara. Om du ångrar dig, så tryck ångra.",
    quickFormFeatures2:
      "<b>Redigera</b> Ger dig möjligheten för att ändra namnet på ett existerande snabb formulär. Du kan även ta bort ett formulär om du inte skal använda det mera",
    guidesForWebooking: "Guider för Webbooking",

    buttonsView: "Knappvy",
    tableView: "Tabellvy",
    onBalance: "i balans",
    printed: "printed",
    waitingPrinting: "väntar på utskrift",
    confirm: "Bekräfta",
    order: "Order",
    labels: "Beställ etiketter",
    showLabelsMenu: "Aktivera funktionen för beställning av etiketter.",
    extentions: "Förlängningar",
    ready: "Bokningen är klar",
    notReady: "Bokningen är inte klar",
  },
  nl: {
    actions: "Acties",
    flagUrl: nlFlag,
    languageName: "Dutch",
    webbooking: "Webboeking",
    bookings: "Boekingsoverzicht",
    createBooking: "Boeking maken",
    quickForm: "Snel formulier",
    createLocalBooking: "Lokale boeking maken",
    quickFormLocal: "Snelle lokale boeking",
    help: "Helpen ?",
    myCompany: "Mijn bedrijf",
    bookingId: "Boekings-ID",
    customer: "Klant",
    delivery: "Levering",
    receiver: "Ontvanger",
    pickup: "Raap op",
    customerNb: "Klant nb",
    itemId: "Factuur",
    sender: "Afzender",
    receiver: "Ontvanger",
    pickupDate: "Ophaaldatum",
    pickupTime: "Ophaal tijd",
    departureDate: "Vertrekdatum",
    tourTime: "Tourtijd",
    description: "Beschrijving",
    palletType: "Eenheid",
    quantity: "Hoeveelheid",
    note: "Opmerking",
    status: "Toestand",
    showHide: "Kolommen aanpassen",
    Processed: "Verwerkt",
    delivered: "Geleverd",
    packages: "Pakketjes",
    pallet: "Eenheid",
    goods: "Eenheden",
    save: "Opslaan",
    goodIsEmpty: "U kunt geen boeking maken met lege goederen.",
    errorRequired: "Dit veld is verplicht.",
    errorPickupLaterDelivery:
      "De bezorgdatum kan niet vroeger zijn dan de ophaaldatum.",
    bookingCreated: "Boeking is succesvol opgeslagen.",
    bookingsImported: "Boekingen was succesvol geïmporteerd.",
    time: "Tijd",
    importExcel: "Importeren vanuit Excel",
    addToQuickForm: "Toevoegen aan snelformulier",
    addBooking: "Boeking toevoegen",
    addLine: "Regel toevoegen",
    lineCreated: "Lijn is succesvol aangemaakt",
    createNew: "Maak nieuw",
    edit: "Bewerking",
    discard: "Veranderingen weggooien",
    quickFormUpdated: "Snel formulier is succesvol bijgewerkt.",
    errorUpdatingQuickForm: "Fout tijdens het bijwerken van snel formulier.",
    enterQuickFormName: "Alsjeblieft voer naam (in of hier)",
    quickFormCreated: "Snel formulier is aangemaakt.",
    error: "Er is een fout opgetreden, probeer het later nog eens.",
    updatedAt: "Bijgewerkt",
    createdAt: "Gemaakt bij",
    createOrder: "Maak bestelling",
    orderCreated: "Bestelling gemaakt",
    isLocal: "Lokale boeking",
    downloadPdf: "Downloaden als PDF",
    useTemplate: "Kies sjabloon",
    chooseTemplate: "Kies de boeking die je voor de sjabloon wilt gebruiken",
    copyForSender: "Kies de boeking die je voor de sjabloon wilt gebruiken ",
    from: "Van",
    until: "Totdat",
    to: "Naar", //like "from - to"  //  Van - Naar
    newQuickForm: "Snelle formulier",
    newQuickFormLocal: "Lokaal snelformulier",
    noRecords: "Geen records om weer te geven",
    delete: "Verwijder",
    edit: "Bewerken",
    deleteText: "Weet je zeker dat je deze rij wilt verwijderen?",
    cancel: "Annuleren",
    search: "Zoekopdracht",
    menu: "Menu",
    latestDeliveryTime: "Laatste tijd",
    deleteQuickForm: "Snel formulier verwijderen",
    quickFormDeleted: "Snelformulier is succesvol verwijderd",
    oftest: "Meest voorkomend",
    errorNullEmpty: "Dit veld kan niet leeg zijn",
    cantCreateOrder: "Je kunt geen bestelling maken met lege velden",
    validationFailed: "Validatie mislukt",
    username: "Gebruikersnaam",
    password: "Wachtwoord",
    login: "Log in",
    logout: "Uitloggen",
    inProgress: "Bezig",
    type: "Type",
    processed: "Verwerkt",
    waiting: "Aan het wachten",
    customersCustomer: "Clïent",
    bookingDeleted:
      "Verwijderen is aangevraagd, boeking zal verdwijnen zodra het verwijderen is verwerkt",
    goodsDescription: "Goederen omschrijving",
    packagesDescription: "Pakketen beschrijving",
    deadline: "Deadline",
    editBooking: "Boeking bewerken",
    editLocalBooking: "Lokale boeking bewerken",
    addNewAddress: "Nieuw adres toevoegen",
    addAddress: "Voeg adres toe",
    addNew: "Nieuwe toevoegen ",
    add: "Toevoegen",
    name: "Naam",
    address: "Adres",
    postalCode: "Postcode",
    city: "Stad",
    country: "Land",
    deliveryTime: "Bezorgtijd",
    deliveryDate: "Bezorgdatum",
    settings: "Instellingen",
    defaultCustomerAddress: "Standaard klantadres",
    defaultUnit: "Standaard goed",
    defaultPackages: "Standaardpakketten",
    principalCode: "Hoofdcode",
    userId: "Gebruikersnaam",
    indtrans: "Indtrans",
    availible: "Beschikbaar",
    unavailible: "Niet beschikbaar",
    addressNumber: "Adres nummer",
    latestDeliveryDate: "Laatste leverdatum",
    addressLoading: "Adres verschijnt binnenkort...",
    receiverRef: "Ontvanger referentie ",
    errorDatePast: "Datum kan niet in het verleden zijn",
    errorObjectValuesRequired: "Dit veld mag geen lege waarden bevatten",
    export: "Exporteren",
    exportExcel: "Excel exporteren",
    settingsSaved: "Instellingen opgeslagen",
    minimizeMenu: "Menu minimaliseren",
    saveGoodsDescription: "Goederenbeschrijving opslaan",
    savePackagesDescription: "Beschrijving van pakketten opslaan",
    saveDescription: "Beschrijving opslaan",

    changeOrder: "Klik en sleep om de lijnvolgorde te wijzigen.",

    wrongCredentials: "Uw gebruikersnaam of wachtwoord is onjuist.",

    unrevertableAction:
      "Weet je het zeker? Deze actie kan niet worden teruggedraaid. ",
    restoreSettings: "Herstel mijn standaardinstellingen.",

    showOnlyMyBookings: "Toon alleen mijn boekingen",

    //packages
    CC: "Cont.",
    DS12CC: "DS ½Cont.",
    DS12Hylde: "DS ½Plank",
    EUPL: "Euro palle",
    EuroCC: "EUR Cont.",
    EuroHylde: "Euro Plank",
    "FORL&dot;": "Extensies",
    HalfCC: "½ Cont.",
    HalfHylde: "½Plank",
    HalfPLL: "½ palle",
    HalfRFIDCC: "½ Cont. med lås",
    HYLDER: "Planken",
    Oneway: "Een manier",
    QuarterPLL: "1/4 Palle",
    STPL: "STPL",
    "T5 RFID CC": "Rfid CC (Groen)",
    KASSE: "Doos",
    "RFID CC": "CC (Rood)",

    //help
    helpHeader: "Hoe kunnen we u helpen?",
    helpSubheader: "Kies een categorie om hulp te krijgen.",
    myCompany: "Mijn Bedrijf",
    myCompany1:
      'Rechterbovenhoek kunt u uw bedrijfsinformatie vinden onder "mijn bedrijf" inclusief gebruikers-ID.',
    myCompany2:
      "U kunt tegelijkertijd uw standaard klantradres, standaard verzendeenheden en standaardverpakking instellen",

    myCompany3:
      "Dit betekent dat de standaardinstellingen die u hier selecteert automatisch worden ingesteld wanneer u een nieuwe boeking wilt maken.",
    adjustColumns: "Kolommen toevoegen",
    adjustColumns1:
      'Onder alle menu-items heb je de mogelijkeheid om kolommen toe te voegen en te verwijderen door te klikken op " kolom toevoegen ", die zich rechtsboven op de pagina bevindt.',
    adjustColumns2:
      "Door op dit menu te klikken, heeft u de mogelijkheid om te kiezen welke kolommen u wilt zien. Hiermee kunt u de weergave precies aanpassen aan wat u nodig heeft. Het enige wat je hoeft te doen is de kolommen aan te vinken die voor jou relevant zijn",
    bookingsFeatures: "Boekingsoverzicht",
    bookingsFeatures1:
      "<b> Export </b> Stelt uw download boekingen in een Excel-bestand naar uw computer. Dit kunt u doen voor zowel lopende boekingen als afgeronde boekingen. U kunt aangeven welke bestellingen u in Excel-formaat wilt downloaden door op tijdsintervallen te filteren ",
    bookingsFeatures2:
      "Stelt download boeking als PDF-bestand. Dit kunt u doen voor zowel lopende boekingen als afgeronde boekingen. De knop bevindt zich onder acties en ziet eruit zoals op de afbeelding",
    bookingsFeatures3:
      "Onder voltooide boekingen kunt u op <b> Bestelling selecteren uit onderstaande lijst </b> te drukken, waardoor u de mogelijkheid heft om een reeds gemaakte boeking als sjabloon voor een nieuwe boeking te gebruiken. Hierdoor kun je een oude boeking hergebruiken zoadt je niet alle gegevens opnieuw hoeft in te vullen.",
    bookingsFeatures4:
      "Door op de knop te drukken kunt u uit het boekingsoverzicht een bestelling selecteren, die u vervolgens doorstuurt naar boeking aanmaken, waar alle informatie van de vorige boeking is gevuld.",
    createBookingFeatures: "Boeking maken",
    createBookingFeatures1:
      "Gebruik bestelling als sjabloon <b> stelt u in staat om een reeds gemaakte boeking te gebruiken als sjabloon voor een nieuwe boeking. Hierdoor kun je een oude boeking hergebruiken zodat je niet alle gegevens opnieuw hoeft in te vullen.",
    createBookingFeatures2:
      "</b> Toevoegen aan snelformulier <b> stelt u in staat om uw boeking toe te voegen aan een reeds gemaakt snelformulier. Hierdoor kun je snel je meest voorkomende boekingen herhalen.",
    createBookingFeatures3:
      "<b>Nieuwe toevoegen <b> Geeft u de mogelijkheid om nieuwe afzenders, ontvangers, ophaallocaties en afleverlocaties aan te maken. Dus als u een nieuwe klant krijgt of het adres wijzigt, kunt u deze gemakkelijk en snel aanmaken. Als u daarentegen een adres of klant wilt verwijderen, moet u contact opnemen met Alex Andersen en wij zullen het voor u doen.",
    quickFormFeatures: "Snel formulier",
    quickFormFeatures1:
      "<b>Maak Nieuw<b>, wordt gebruikt om een nieuw snel formulier te maken. Geef het gewoon een naam en druk op opslaan. Als u het opnieuw overweegt, drukt u op ongedaan maken",
    quickFormFeatures2:
      "<b>Bewerken <b> kunt u een reeds gemaakt snelformulier hernoemen. U kunt het formulier hier ook verwijderen als u het niet langer nodig heeft.",
    guidesForWebooking: "Gidsen voor Webboekingen",
    buttonsView: "Knoppenweergave",
    tableView: "Tabelweergave",
    onBalance: "per saldo",
    printed: "gedrukt",
    waitingPrinting: "wacht op printen",
    confirm: "Bevestigen",
    order: "Bestellen",
    labels: "Besteletiketten",
    showLabelsMenu: "Schakel de functie voor het bestellen van labels in.",
    extentions: "Uitbreidingen",
    ready: "Boeking is klaar",
    notReady: "Boeking is niet klaar",
  },
};
