import React from "react";
import { TextField, Box, Button, Typography } from "@material-ui/core";
import { useLang } from "context/lang";
import { useStore } from "context/store";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Lookup,
  ICustomersCustomer,
  BookingFieldId,
} from "navision-proxy-api/@types";

interface IProps {
  handleSubmit: (form: Form) => void;
  handleClose: () => void;
  name: string;
  showNumber: boolean;
}

export type Form = {
  [key: string]: {
    value: string;
    hidden?: boolean;
    lookup?: Lookup;
    code?: string; // ex. country code
  };
};

export default ({
  handleSubmit,
  handleClose,
  name,
  showNumber = false,
}: IProps) => {
  const {
    bookingFormData: { countries },
  } = useStore();

  const initForm: Form = {
    receiverRef: { value: "", hidden: !showNumber },
    name: { value: "" },
    address: { value: "" },
    postalCode: { value: "" },
    country: { value: "", lookup: countries, code: "" },
    city: { value: "" },
  };

  const formReducer = (
    form: Form,
    { key, value, code }: { key: keyof Form; value: string; code?: string }
  ) => ({
    ...form,
    [key]: { ...form[key], value, code },
  });

  const [form, dispatch] = React.useReducer(formReducer, initForm);

  const { t } = useLang();

  const submit = () => {
    handleSubmit(form);
    handleClose();
  };

  return (
    <Box display="flex" width="300px" flexWrap="wrap" p={2}>
      <Typography variant="h6">{`${t("addNew")} ${t(name)}`}</Typography>
      {Object.entries(form)
        .filter(([_, { hidden }]) => !hidden)
        .map(([key, { value, lookup }]) =>
          lookup ? (
            <Box width="100%" m={1} p={2} clone>
              <Autocomplete
                options={lookup}
                value={{ key, value }}
                onChange={(_, v) => {
                  if (v && v.key && v.value) {
                    dispatch({ key: "country", value: v.value, code: v.key });
                  }
                }}
                getOptionLabel={(o) => o.value || ""}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    inputProps={{
                      autocomplete: "new-password",
                      form: {
                        autocomplete: "off",
                      },
                      ...params.inputProps,
                    }}
                    fullWidth
                    label={t(key)}
                  />
                )}
              />
            </Box>
          ) : (
            <Box width="100%" m={1} p={2}>
              <TextField
                variant="outlined"
                fullWidth
                label={t(key)}
                onChange={(e) => dispatch({ key, value: e.target.value })}
                value={value}
              />
            </Box>
          )
        )}
      <Box display="flex" justifyContent="space-between" width="100%">
        <Button color="primary" onClick={submit}>
          {t("add")}
        </Button>
        <Button color="secondary" onClick={handleClose}>
          {t("cancel")}
        </Button>
      </Box>
    </Box>
  );
};
