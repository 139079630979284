import React from "react";
import {
  Box,
  Icon,
  Tooltip,
  IconButton,
  Paper,
  Typography,
  Checkbox,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MaterialTable, {
  MTableBodyRow,
  Action,
  Icons,
  MTableHeader,
} from "material-table";

import { useHistory, RouteChildrenProps, matchPath } from "react-router-dom";
import _ from "lodash";

import {
  IBooking,
  BookingStatus,
  BookingFieldId,
} from "navision-proxy-api/@types";
import { BookingsRequestQuery } from "navision-proxy-api/@types/api";

import { useLang } from "context/lang";
import { useStore } from "context/store";
import { useFieldsSettings } from "context/fieldsSettings";
import { useBookingsMTableColumns } from "hooks/mTableColumns";
import { useBookings } from "hooks/bookings";
import { useBookingActions } from "hooks/bookings/bookingActions";
import { useTheme } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import exportExcel from "utils/exportExcel";
import VirtualizedTableBody from "components/BookingMaterialTable/VirtualizedTableBody";
import BookingsToolbar from "components/BookingMaterialTable/BookingsToolbar";
import NavPdfDownloader from "components/PdfGenerator/NavPdfDownloader";
import { Loader } from "components/Loader";

import { BookingMovementStatus } from "types";

import { FieldsMenu, IFieldsMenuOption } from "components/FieldsMenu";
import { DateRangeFilter } from "components/BookingMaterialTable/DateRangeFilter";
import AlertDialog from "components/AlertDialog";
import routes from "config/routes";
import { COLUMN_FREEZE_INDEX } from "config";
import {
  canConfirmBooking,
  canUnconfirmBooking,
  canDeleteBooking,
  canDownloadPdf,
} from "config/bookingPolicies";

export default withWidth()(
  ({
    width,
    routerProps,
  }: {
    width: "xs" | "sm" | "md" | "lg" | "xl";
    routerProps: RouteChildrenProps<{}, any>;
  }) => {
    /** We keep component persistent in the body,
     * though if there is not Booking Overview or Booking Details view
     * we will return empty component
     * If there is than component will be reloaded */
    const currentPathname = routerProps.location.pathname;
    if (
      !(
        matchPath(currentPathname, { path: routes.bookings.path }) ||
        matchPath(currentPathname, { path: routes.bookingDetails.path })
      )
    ) {
      return <></>;
    }

    const { t, lang } = useLang();
    const history = useHistory();
    const theme = useTheme();

    const { bookings, loading, query, loadBookings, reloadBookings } =
      useBookings({
        refreshBookings: true,
      });

    const [selectedBookings, setSelectedBookings] = React.useState<IBooking[]>(
      []
    );

    const {
      createBookingsFromFile,
      deleteBooking,
      updateBookingMovementStatus,
      loading: bookingAcionsLoading,
    } = useBookingActions();
    const {
      hiddenFields,
      toogleHiddenField,
      setHiddenPackages,
      handleSaveColumnsWidth,
      changeColumnOrderOffset,
    } = useFieldsSettings();
    const { bookingFormData, loadTemplate, loading: storeLoading } = useStore();
    const { columns } = useBookingsMTableColumns({
      alwaysUnhiddenColumns: ["bookingId"],
      ignoredColumns: [
        "deadline",
        "customersCustomer",
        "packages",
        "ediReference",
        "movementStatus",
        "trackingNumber",
      ],
      notEditableColumns: [
        "bookingId",
        "pickupDate",
        "pickupTime",
        "latestDeliveryTime",
        "tourTime",
        "receiver",
        "customerNb",
        "customersCustomer",
        "customer",
        "pickup",
        "delivery",
        "isLocal",
        "itemId",
      ],
      sortableColumns: [
        "pickupDate",
        "customer",
        "receiver",
        "pickup",
        "delivery",
        "isLocal",
      ],
      columnFreezeIndex:
        width == "md" || width == "lg" || width == "xl"
          ? COLUMN_FREEZE_INDEX
          : -1,
      verticalPackagesView: false,
      customColumns: [
        {
          field: "actions",
          title: t("actions"),
          resizable: false,
          width: query?.filters?.status == "deliveryCompleted" ? 180 : 210,
          sorting: false,
          cellStyle: { padding: 0 },
          render: (booking: IBooking) => {
            return (
              <Box display="flex">
                {canDeleteBooking(booking) && (
                  <Tooltip title={t("delete")}>
                    <AlertDialog handleSubmit={() => deleteBooking(booking)}>
                      <IconButton>
                        <Icon>delete</Icon>
                      </IconButton>
                    </AlertDialog>
                  </Tooltip>
                )}

                {!(
                  new Date(booking.deadline) < new Date() ||
                  booking.status == "deliveryCompleted"
                ) && (
                  <Tooltip title={t("edit")}>
                    <IconButton
                      onClick={() => {
                        loadTemplate(booking);
                        if (booking.isLocal) {
                          history.push("editLocalBooking");
                        } else {
                          history.push("editBooking");
                        }
                      }}
                    >
                      <Icon>create</Icon>
                    </IconButton>
                  </Tooltip>
                )}
                {canDownloadPdf(booking) && (
                  <Tooltip title={t("downloadCMR")}>
                    <IconButton>
                      <NavPdfDownloader
                        key={parseInt(String(booking._id), 16)}
                        bookingId={booking.bookingId}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {canConfirmBooking(booking) && (
                  <Tooltip title={t("ready")}>
                    <IconButton
                      onClick={async () => {
                        await updateBookingMovementStatus(
                          booking.bookingId,
                          BookingMovementStatus.confirmed
                        );
                        reloadBookings();
                      }}
                    >
                      <Icon color="primary">redo</Icon>
                    </IconButton>
                  </Tooltip>
                )}
                {canUnconfirmBooking(booking) && (
                  <Tooltip title={t("notReady")}>
                    <IconButton
                      onClick={async () => {
                        await updateBookingMovementStatus(
                          booking.bookingId,
                          BookingMovementStatus.unconfirmed
                        );
                        reloadBookings();
                      }}
                    >
                      <Icon color="default">clear</Icon>
                    </IconButton>
                  </Tooltip>
                )}
                {/* {booking.type !== "Buffer" && (
                  <Tooltip title={t("viewDetails")}>
                    <IconButton
                      onClick={async () => {
                        history.push(`/bookings/${booking.trackingNumber}`);
                      }}
                    >
                      <Icon color="default">list_alt</Icon>
                    </IconButton>
                  </Tooltip>
                )} */}
              </Box>
            );
          },
        },
        {
          field: "_id", //dummy field
          width: 42,
          title: (
            <Checkbox
              checked={Boolean(
                selectedBookings.length > 0 &&
                  selectedBookings.length == bookings.items.length
              )}
              onChange={() =>
                setSelectedBookings((prev) =>
                  prev.length ? [] : bookings.items
                )
              }
            />
          ),
          hidden: false,
          resizable: false,
          cellStyle: { padding: 0 },
          headerStyle: { padding: 0 },
          sorting: false,
          render: (booking: IBooking) => {
            const checked = Boolean(
              selectedBookings.find(
                ({ bookingId }) => booking.bookingId == bookingId
              )
            );
            return (
              <Checkbox
                checked={checked}
                onChange={() => {
                  if (checked) {
                    setSelectedBookings((prev) =>
                      prev.filter(
                        ({ bookingId }) => bookingId != booking.bookingId
                      )
                    );
                  } else {
                    setSelectedBookings((prev) => [...prev, booking]);
                  }
                }}
              />
            );
          },
        },
      ],
      selectedBookings,
    });

    const { templateModeProps } = useStore();

    const initQuery = (): BookingsRequestQuery => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const past = new Date(Date.now() - 12096e5);

      //if booking table rendered in template mode we want to show delivered bookings
      const templateModePropsFilters = templateModeProps
        ? {
            status: "deliveryCompleted",
            pickupDate: {
              ge: past.toISOString(),
              le: yesterday.toISOString(),
            },
            isLocal: templateModeProps.isLocal,
          }
        : {};

      return {
        filters: {
          //...query.filters,
          status: "waiting",
          // pickupDate: undefined,
          // quickForm: undefined,
          // isLocal: undefined,
          ...templateModePropsFilters,
        },
      } as BookingsRequestQuery;
    };

    /** Effect from loading up bookings */
    React.useEffect(() => {
      if (bookingFormData.fields.length) {
        //wait for form data to load up
        loadBookings(initQuery());
      }

      return () => {
        //clearStore();
        //shouldn't clear store here because when swithcing to create booking and back bookings are not loads up again
      };
    }, [lang, bookingFormData.fields]);

    React.useEffect(() => {
      //hide packages which are not used
      const notUsedPackages = _.difference(
        bookingFormData.packageNames,
        bookings && bookings.usedPackages ? bookings.usedPackages : []
      );

      setHiddenPackages(
        notUsedPackages.map<`packages.${string}`>((p) => `packages.${p}`)
      );
    }, [bookings]);

    const handleChangeTab = (
      event: React.ChangeEvent<{}>,
      value: BookingStatus
    ) => {
      if (value === "deliveryCompleted") {
        //const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const past = new Date(Date.now() - 12096e5); //two weeks ago
        const future = new Date(Date.now() + 12096e5 / 2); //week in front

        loadBookings((prev) => ({
          ...prev,
          filters: {
            ...prev.filters,
            status: value,
            pickupDate: { ge: past.toISOString(), le: future.toISOString() },
            isLocal: undefined,
          },
        }));
      } else {
        //in progress
        loadBookings(initQuery());
      }
    };

    const handleCreateBookingsFromFile = (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (e?.target?.files?.[0]) {
        createBookingsFromFile(e.target.files[0]);
      }
    };

    const handleRowClick = templateModeProps
      ? (e?: any, rowData?: IBooking) => {
          if (rowData) {
            loadTemplate({
              ..._.pick(rowData, [
                //TODO: move this to settings as fields which can be copied
                "customerNb",
                "customer",
                "receiver",
                "pickup",
                "delivery",
                "customersCustomer",
                "isLocal",
                "goods",
                "packages",
              ]),
            } as IBooking);

            if (rowData.isLocal) {
              history.push("createLocalBooking");
            } else {
              history.push("createBooking");
            }
          }
        }
      : undefined;

    const tableRef = React.useRef<any>();

    const handleChangeColumnsOrder = (oldIndex: number, newIndex: number) => {
      let offset = 0;
      if (newIndex >= oldIndex) {
        offset = newIndex - oldIndex - 1;
      } else {
        offset = newIndex - oldIndex;
      }
      changeColumnOrderOffset(
        columns[oldIndex].field as BookingFieldId,
        offset
      );
    };

    const handleCheckDraggability = (oldIndex: number, newIndex: number) => {
      if (
        (oldIndex <= COLUMN_FREEZE_INDEX + 1 &&
          newIndex > COLUMN_FREEZE_INDEX) ||
        (newIndex <= COLUMN_FREEZE_INDEX && oldIndex > COLUMN_FREEZE_INDEX + 4)
      ) {
        return false;
      } else {
        return true;
      }
    };

    //we load up component always but return something only when the route is matching
    //this is how we persist the state for not refreshing component when go back from detailed view
    return routerProps?.match === null ? (
      <></>
    ) : (
      <Loader
        color="primary"
        size={50}
        margin={10}
        loading={Boolean(storeLoading["load"])}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Tabs
            value={query?.filters?.status || "waiting"}
            onChange={handleChangeTab}
            indicatorColor="primary"
          >
            <Tab
              className="MuiButton-sizeSmall MuiButton-containedSizeSmall"
              value={"waiting"}
              label={t("inProgress")}
            />
            <Tab
              className="MuiButton-sizeSmall MuiButton-containedSizeSmall"
              value={"deliveryCompleted"}
              label={t("delivered")}
            />
          </Tabs>
          <FieldsMenu
            options={columns
              //.filter(({ title }) => ) //filter dummy columns

              .map<IFieldsMenuOption>((column) => ({
                title: String(column.title),
                id: column.field as string,
                hidden: !Boolean(
                  column.title && typeof column.title == "string"
                ),
                disabled: column.alwaysUnhidden,
                value:
                  !hiddenFields.includes(
                    (column.field || " ") as BookingFieldId
                  ) ||
                  column.alwaysUnhidden ||
                  false,
              }))}
            onChoose={(option) =>
              toogleHiddenField(option.id as BookingFieldId)
            }
            draggableProps={{
              onChangeOrder: handleChangeColumnsOrder,
              checkDraggability: handleCheckDraggability,
            }}
            size="small"
          />
        </Box>
        <Box position="relative">
          <Box
            onMouseUp={() => {
              //handling columns resize
              handleSaveColumnsWidth(tableRef.current?.state?.columns);
            }}
            boxShadow={5}
          >
            <MaterialTable<IBooking>
              data={bookings ? bookings.items : []}
              columns={columns}
              isLoading={
                loading["load"] ||
                bookingAcionsLoading["movementStatus"] ||
                bookingAcionsLoading["delete"]
              }
              title={
                <BookingsToolbar
                  query={query}
                  handleCreateBookingsFromFile={handleCreateBookingsFromFile}
                  selectedBookings={selectedBookings}
                  deleteBooking={deleteBooking}
                  updateBookingMovementStatus={updateBookingMovementStatus}
                  refreshBookings={reloadBookings}
                >
                  {selectedBookings.length == 0 ? (
                    <DateRangeFilter
                      field="pickupDate"
                      query={query}
                      loadBookings={loadBookings}
                    />
                  ) : (
                    <></>
                  )}
                </BookingsToolbar>
              }
              tableRef={tableRef}
              onRowClick={handleRowClick}
              style={{
                //...props.style,
                boxShadow: " 0 0 black",
                borderCollapse: "inherit",
              }}
              icons={
                {
                  Resize: (props: any) => (
                    <Icon
                      {...props}
                      style={{
                        ...props.style,
                        color: theme.palette.grey[400],
                        transform: "rotate(90deg)",
                        position: "absolute",
                        right: "5px",
                      }}
                    >
                      drag_handle
                    </Icon>
                  ),
                } as Icons
              }
              localization={{
                body: {
                  emptyDataSourceMessage: t("noRecords"),
                  deleteTooltip: t("delete"),
                  editTooltip: t("edit"),
                  editRow: {
                    deleteText: t("deleteText"),
                    cancelTooltip: t("cancel"),
                    saveTooltip: t("save"),
                  },
                },
                header: {
                  actions: t("actions"),
                },
                toolbar: {
                  searchTooltip: t("search"),
                  searchPlaceholder: t("search"),
                  exportTitle: t("export"),
                  exportAriaLabel: t("export"),
                  exportCSVName: t("exportExcel"),
                },
              }}
              // editable={props.editable}
              // actions={actions}
              components={{
                //...props.components,
                Container: (props) => {
                  const bookingsQuantity = bookings ? bookings.items.length : 0;

                  // const initBookingsUnitsMap: any = {};
                  // bookings?.usedGoods?.forEach((g: any) => {
                  //   initBookingsUnitsMap[g] = 0;
                  // });

                  // console.log(initBookingsUnitsMap);

                  const bookingsUnitsMap: any = {};

                  bookings?.items?.forEach((b) => {
                    b.goods?.forEach((g) => {
                      if (g)
                        bookingsUnitsMap[g[0]] =
                          (bookingsUnitsMap[g[0]] || 0) + (g[1] || 0);
                    });
                  });

                  return (
                    <>
                      <Paper {...props} />
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Box m={1}>
                          <Typography>
                            {bookingsQuantity} bookings{"  "}
                            {Object.entries(bookingsUnitsMap)
                              .map(([k, v]) => `, ${v} ${k}`)
                              .join(" ")}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  );
                },
                Header: (headerProps) => {
                  return (
                    <MTableHeader
                      {...headerProps}
                      // {...getDraggbleProps(headerProps, {
                      //   onEnter: (e: any) => {
                      //     console.log("highlight enter");
                      //   },
                      //   onLeave: (e: any) => {
                      //     console.log("highlight leave");
                      //   },
                      // })}
                    />
                  );
                },
                Body: (
                  bodyProps //infinite scroll body
                ) => (
                  <VirtualizedTableBody
                    {...bodyProps}
                    scrollWidth={undefined}
                    tableRef={tableRef}
                  />
                ),
                Row: templateModeProps
                  ? (props) => (
                      <MTableBodyRow
                        {...props}
                        scrollWidth={undefined}
                        data-test="bookings-table-body-row"
                        className="choose-template-row"
                      />
                    )
                  : (props) => (
                      <MTableBodyRow
                        {...props}
                        scrollWidth={undefined}
                        data-test="bookings-table-body-row"
                        className={`${
                          props.data.type === "Buffer"
                            ? "waiting-table-row"
                            : ""
                        } ${
                          props.data.movementStatus ===
                          BookingMovementStatus.confirmed
                            ? "confirmed-table-row"
                            : ""
                        }
                        `}
                      />
                    ),
              }}
              options={{
                toolbar: true,
                grouping: false,
                doubleHorizontalScroll: false,
                draggable: false,
                paging: false,
                minBodyHeight: "calc( 100vh - 230px)",
                maxBodyHeight: "calc( 100vh - 230px)",
                overflowY: "auto",
                tableLayout: "fixed",
                columnResizable: true,
                exportButton:
                  templateModeProps || selectedBookings.length
                    ? undefined
                    : { csv: true },
                exportCsv: (columns, data) =>
                  exportExcel(
                    [
                      ...columns,
                      // {
                      //   //write custom rener function to translate packages when exporting
                      //   field: "goodsDescription",
                      //   hidden: false,
                      //   render: ({ goodsDescription }: IBooking) => {
                      //     return goodsDescription?.join(", ");
                      //   },
                      // },
                    ],
                    data
                  ),
                actionsColumnIndex: 0,
                //tableLayout: "fixed",
                //selection: true,
                //showTextRowsSelected: false,
                toolbarButtonAlignment: "left",
              }}
              onSelectionChange={(bookings) => setSelectedBookings(bookings)}
            />
          </Box>
        </Box>
      </Loader>
    );
  }
);
