import React, { ReactElement } from "react";
import enLocale from "date-fns/locale/en-US";
import daLocale from "date-fns/locale/da";
import deLocale from "date-fns/locale/de";
import svLocale from "date-fns/locale/sv";
import nlLocale from "date-fns/locale/nl";

import moment from "moment";
import "moment/locale/da";
import "moment/locale/de";
import "moment/locale/se";
import "moment/locale/nl";
import { useApi } from "hooks/api";
import { Loader } from "components/Loader";

import enFlag from "../assets/img/gb_flag.svg";
import deFlag from "../assets/img/de_flag.svg";
import dkFlag from "../assets/img/dk_flag.svg";
import svFlag from "../assets/img/se_flag.svg";
import nlFlag from "../assets/img/nl_flag.svg";

export type LangCode = "en" | "da" | "de" | "sv" | "nl";

type Translation = {
  [langCode in LangCode]?: {
    flagUrl: string;
    languageName: string;
    [key: string]: string;
  };
};

interface ILangContext {
  t: (key: string) => string;
  getAvailableLangs: () => {
    code: LangCode;
    title?: string;
    flagUrl?: string;
  }[];
  lang: LangCode;
  /** To properly change user lang in the app use context/user.switchLang */
  _setLang: (lang: LangCode) => void;
  dateFnsLocale?: Object;
}

const LangContext = React.createContext<ILangContext>({} as ILangContext);

const dateFnsLocaleMap = {
  en: enLocale,
  da: daLocale,
  de: deLocale,
  sv: svLocale,
  nl: nlLocale,
};

function LangProvider({ ...props }: { children: ReactElement }): ReactElement {
  const { fetchTranslations } = useApi();

  const [loading, setLoading] = React.useState<boolean>(true);

  const [lang, setLanguage] = React.useState<LangCode>(
    (localStorage.getItem("userLanguage") as LangCode) || "en"
  );

  const [translation, setTranslation] = React.useState<Translation>({});

  React.useEffect(() => {
    const loadTranslations = async () => {
      setLoading(true);
      try {
        const translations = await fetchTranslations(lang);
        const convertedTranslations = translations.reduce(
          (acc, translation) => ({
            ...acc,
            [translation.languageCode]: {
              ...translation,
              ...translation.translations,
            },
          }),
          {}
        );
        setTranslation(convertedTranslations);
      } catch (err: any) {
        console.error("Error while loading translations");
      }
      setLoading(false);
    };
    loadTranslations();
  }, [lang]);

  moment.locale(lang);

  const _setLang = (lang: LangCode) => {
    setLanguage(lang);
    moment.locale(lang);
    localStorage.setItem("userLanguage", lang);
  };

  const unknownTranslations = React.useRef<Translation>({});

  const t = (key: string): string => {
    if (translation[lang] && translation[lang]?.[key]) {
      return translation[lang]?.[key] || key;
    } else {
      //save unknown translation
      if (!unknownTranslations.current[lang]) {
        unknownTranslations.current[lang] = {
          flagUrl: "",
          languageName: "",
        };
      }
      unknownTranslations.current[lang]![key] = "";
      //console.log(unknownTranslations.current)
      return key;
    }
  };

  // const getAvailableLangs = () =>
  //   (Object.keys(translation) as Array<LangCode>).map((langCode) => ({
  //     code: langCode,
  //     title: translation[langCode]?.languageName,
  //     flagUrl: translation[langCode]?.flagUrl,
  //   }));

  const getAvailableLangs = () => [
    { code: "en" as LangCode, title: "English", flagUrl: enFlag },
    { code: "da" as LangCode, title: "Dansk", flagUrl: dkFlag },
    { code: "de" as LangCode, title: "Deutsch", flagUrl: deFlag },
    { code: "sv" as LangCode, title: "Svensk", flagUrl: svFlag },
    { code: "nl" as LangCode, title: "Dutch", flagUrl: nlFlag },
  ];

  return (
    <Loader size={50} margin={50} loading={loading}>
      <LangContext.Provider
        value={{
          getAvailableLangs,
          lang,
          _setLang,
          t,
          dateFnsLocale: dateFnsLocaleMap[lang],
        }}
        {...props}
      />
    </Loader>
  );
}

const useLang = () => React.useContext(LangContext);

export { useLang, LangProvider };
